import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IRoleAssignment, IRoleAssignments } from '../../interfaces'

const initialRoleAssignmentsState: IRoleAssignments = {
  totalRecords: 0,
    data: [],
}

const mapToRoleAssignments = (rawRoleAssignments): Array<IRoleAssignment> => rawRoleAssignments?.map(({ id, roleId, userId, tenantId, status, serviceId, servicePrefix, roleName, ownerServiceId }) => ({ id, roleId, userId, tenantId, serviceId, status, servicePrefix, roleName, ownerServiceId }))

export const roleAssignmentsSlice = createSlice({
  name: 'roleAssignments',
  initialState : initialRoleAssignmentsState,
  reducers: {
    getRoleAssignmentsSuccess: (state, action: PayloadAction<IRoleAssignments>) => {
      state.totalRecords = action.payload?.totalRecords === -1 ? state?.totalRecords : action.payload?.totalRecords
      state.data = mapToRoleAssignments(action.payload.data)
    },
  },
})

export const { getRoleAssignmentsSuccess } = roleAssignmentsSlice.actions

export default roleAssignmentsSlice.reducer
