import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Helpers } from '../../utils/helpers';

@Component({
  selector: 'prism-confirmation-modal',
  template: `
    <ng-template #modal>
      <!--    Modal Header    -->
      <div class="modal-header">
        <button type="button" class="close pull-right" (click)="onCloseModal()">
          <i class="fa fa-times" tooltip="Close Modal"></i>
        </button>
      </div>

      <!--    Modal Body    -->
      <div class="modal-body py-0">
        <ng-content></ng-content>
      </div>

      <!--    Modal Footer    -->
      <div class="modal-footer">
        <ng-content select="[modalFooter]"></ng-content>
      </div>
      <div class="default-footer modal-footer">
        <button *ngIf="showConfirm"
                type="button"
                class="btn btn-success"
                (click)="onConfirm()">
          {{ confirmText }}
        </button>
        <button type="button"
                class="btn btn-outline-dark"
                (click)="onCloseModal()">
          {{ cancelText }}
        </button>
      </div>
    </ng-template>

  `,
  styles: [`
      .modal-footer {
          border-top: none;
      }

      .modal-header {
          border-bottom: none;
      }

      .modal-footer:empty {
          display: none;
      }

      .modal-footer:empty + .default-footer {
          display: flex;
      }

      .default-footer {
          display: none;
      }
  `],
})
export class ConfirmationModalComponent {

  /**
   * Show or hide confirmation button
   * @private
   */
  private _showConfirm = true;

  /**
   * Configuration for bootstrap modal
   */
  private _modalConfig = {};

  /**
   * Setter that parses modalConfig data to an object if it is a stringified data.
   * @param value
   */
  @Input()
  set modalConfig(value: {} | string) {
    this._modalConfig = Helpers.jsonParseString(value);
  }

  /**
   * @see _showConfirm
   */
  get showConfirm(): boolean {
    return this._showConfirm;
  }

  /**
   * Setter that parses the showConfirm value to boolean if it is a string.
   */
  @Input()
  set showConfirm(value: boolean | string) {
    this._showConfirm = Helpers.convertStringToBoolean(value);
  }

  /**
   * Confirmation button text
   */
  @Input() confirmText = 'Confirm';

  /**
   * Cancellation button text
   */
  @Input() cancelText = 'Cancel';

  /**
   * Title of the modal
   */
  @Input() modalTitle = '';

  /**
   * Event emitted when cancel or close icon is clicked
   */
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Event emitted when confirm button is clicked
   */
  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Template reference of modal content
   * @private
   */
  @ViewChild('modal') private modalContent: TemplateRef<ConfirmationModalComponent>;

  /**
   * Bootstrap modal reference
   */
  public modalRef: BsModalRef;

  constructor(private modalService: BsModalService) {
  }

  /**
   * Function that hides/closes modal
   */
  hideModal() {
    this.modalRef.hide();
  }

  /**
   * Function that opens modal
   */
  openModal() {
    this.modalRef = this.modalService.show(this.modalContent, this._modalConfig);
  }

  /**
   * Handler for confirm button
   */
  onConfirm() {
    this.confirm.emit();
  }

  /**
   * Handler for cancel button and close icon
   */
  onCloseModal() {
    this.hideModal();
    this.closeModal.emit();
  }
}
