import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Helpers } from '../../../utils/helpers';
import { FilterObject } from '../../../data/core/filters/filters';
import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'prism-filters-container',
  template: `
    <div class="row filters-container pt-3">
      <div class="col-md-9">
        <ng-content select="[search]"></ng-content>
        <div #filterListWrapper>
          <ng-content select="[filters-list]"></ng-content>
        </div>
      </div>

      <div class="col-md-3" *ngIf="filterListWrapper.children.length">
        <div class="pull-right">
          <button tooltip="Reset"
                  class="btn btn-primary reset-filters mr-2"
                  (click)="resetFilters.emit()">
            <i class="fa fa-eraser pointer refresh"></i>
          </button>

          <button class="btn btn-primary mr-2"
                  (click)="openSavedFiltersModal()">
            Saved Filters
          </button>

          <button class="btn btn-primary"
                  (click)="toggleAdvancedFilter()">
            <i class="fa fa-filter"></i>
            Filters
          </button>

        </div>
      </div>

      <prism-confirmation-modal #savedFiltersModal
                                [modalConfig]="{class: 'modal-dialog-centered'}"
                                [showConfirm]="false">
        <ng-content select="[saved-filters]"></ng-content>
      </prism-confirmation-modal>

      <div class="container-fluid" [collapse]="isCollapsed" >
      <hr>
        <div>
          <ng-content select="[filters]"></ng-content>
        </div>
        <div class="text-center pt-2">
          <button type="button"
                  class="btn btn-primary m-1"
                  (click)="onConfirm()">
                  Search
          </button>
          <button type="button"
                  class="btn btn-outline-dark m-1"
                  (click)="isCollapsed = true">
                  Close
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 filters-block">
        <ng-content select="[filters-list]"></ng-content>
      </div>
    </div>
  `,
  styles: [`
    .filters-container {
      border: solid 1px gray;
      border-radius: 4px;
      margin: 0.25rem;
      padding: 0.5rem 0;
      background-color: #f0f3f5;
    }
  `],
})
export class FiltersContainerComponent {

  /**
   *  If true, expands filter section to show advanced filter options
   */
  private _isCollapsed = true;

  /**
   * Reference to advanced filters modal
   * @private
   */
  @ViewChild('modal', { static: false })
  private advancedFilters: ConfirmationModalComponent;

  /**
   * Reference to saved filters modal
   * @private
   */
  @ViewChild('savedFiltersModal', { static: false })
  private savedFilters: ConfirmationModalComponent;

  /**
   * Emits on apply filter changes
   */
  @Output()
  public apply = new EventEmitter<FilterObject<null>>();

  /**
   * Triggers when the reset button is pressed.
   */
  @Output()
  public resetFilters = new EventEmitter();

  /**
   * @see _isCollapsed
   */
  public get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  /**
   * Setter that parses the value to boolean if it is a string.
   * @param value
   */
  @Input()
  public set isCollapsed(value: boolean | string) {
    this._isCollapsed = Helpers.convertStringToBoolean(value);
  }

  /**
   * Open and close filters slide down on filter button click
   */
  public toggleAdvancedFilter(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  /**
   * Open saved filters modal on Saved Filters button click
   */
  public openSavedFiltersModal(): void {
    this.savedFilters.openModal();
  }

  /**
   * Emit event on apply filter changes
   */
  public onConfirm(): void {
    this.apply.emit();
  }
}
