export const optionToday = 'today';
export const optionYesterday = 'yesterday';
export const optionWeek = 'past_7_days';
export const optionCustom = 'custom';
export const optionAll = 'all';

export const dateOptions = [
    optionAll,
    optionToday,
    optionYesterday,
    optionWeek,
    optionCustom,
];
