import { all, takeLatest } from "redux-saga/effects";
import { createGroupSaga } from "./groups/create-group-saga";
import { deleteGroupSaga } from "./groups/delete-group-saga";
import { getGroupsSaga } from "./groups/groups-list-saga";
import { updateGroupSaga } from "./groups/update-group-saga";
import { partialAuthSaga } from "./partial-auth-saga";
import { createRoleAssignmentSaga } from "./role-assignments/create-role-assignment-saga";
import { deleteRoleAssignmentSaga } from "./role-assignments/delete-role-assignment-saga";
import { getRoleAssignmentsSaga } from "./role-assignments/get-role-assignments-saga";
import { createRoleSaga } from "./roles/create-role-saga";
import { deleteRoleSaga } from "./roles/delete-role-saga";
import { getRolesSaga } from "./roles/get-roles-saga";
import { updateRoleSaga } from "./roles/update-role-saga";
import { getServicesSaga } from "./services/get-services-saga";


export default function* rootSaga() {
  yield all([
    takeLatest("CREATE_ROLE", createRoleSaga),
    takeLatest("GET_ROLES", getRolesSaga),
    takeLatest("GET_SERVICES", getServicesSaga),
    takeLatest("DELETE_ROLE", deleteRoleSaga),
    takeLatest("UPDATE_ROLE", updateRoleSaga),
    takeLatest("CREATE_ROLE_ASSIGNMENT", createRoleAssignmentSaga),
    takeLatest("GET_ROLE_ASSIGNMENTS", getRoleAssignmentsSaga),
    takeLatest("DELETE_ROLE_ASSIGNMENT", deleteRoleAssignmentSaga),
    takeLatest("GET_USER_AUTH", partialAuthSaga),
    takeLatest("GET_GROUPS", getGroupsSaga),
    takeLatest("CREATE_GROUP", createGroupSaga),
    takeLatest("UPDATE_GROUP", updateGroupSaga),
    takeLatest("DELETE_GROUP", deleteGroupSaga),
  ])
}
