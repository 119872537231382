import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrismOtherAppsComponent } from './prism-other-apps.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [PrismOtherAppsComponent],
  imports: [
    CommonModule,
    BsDropdownModule,
  ],
  exports: [PrismOtherAppsComponent],
})
export class PrismOtherAppsModule {
}
