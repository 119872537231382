import { from, Observable } from 'rxjs';
import { AuthorizationService } from '../authorization.service';
import { Utilities } from '@gvp-utilities/utils';
import { partialAuthorizeData } from '../schema/authorization.schema';

export const APP_PREFIX_KEY = 'prism-app-prefix';
export const LOCKED_VIEW_KEY = 'auth_locked_view';

export class AngularAuthorizationService extends AuthorizationService {

  /**
   * Is the key where to store the authz data in the session storage
   * @private
   */
  private static _session_key: string;

  /**
   * A flag that indicates that application guards the routes.
   * After authorization, we get the authz policies asynchronously and a view might
   * be locked before that. In this case we can do additional actions after we get resource
   *
   * @private
   */
  private static _guardingRoutes = false;

  /**
   * @see _guardingRoutes
   * @param value
   */
  set guardingRoutes(value: boolean) {
    AngularAuthorizationService._guardingRoutes = value;
  }

  /**
   * Check if application guards routes, and guard locked a view before this service got the permission.
   * If both conditions are true, redirect to that locked view and remove it from the session storage
   */
  public static validateGuardingRoutes() {
    if (AngularAuthorizationService._guardingRoutes && sessionStorage.getItem(LOCKED_VIEW_KEY)) {
      window.location.href = sessionStorage.getItem(LOCKED_VIEW_KEY);
      sessionStorage.removeItem(LOCKED_VIEW_KEY)
    }
  }

  constructor(protected appName: string) {
    super(appName);
    localStorage.setItem(APP_PREFIX_KEY, this.appName);
    AngularAuthorizationService._session_key = this.appName;
  }

  /**
   * Get authz permissions and store them to session storage
   * @param email
   * @param service_actions
   * @param service_resources
   */
  public partialAuthorize(email: string, service_actions: string, service_resources: string): Observable<any> {
    const sessionData: partialAuthorizeData = {};
    return from(super.partialAuthorize(email, service_actions, service_resources)
      .then(this.status)
      .then(this.json)
      .then(function (data) {
        sessionData.userPermissions = data;
        Utilities.saveSessionData(AngularAuthorizationService._session_key, sessionData);
        AngularAuthorizationService.validateGuardingRoutes();
        return data;
      }));
  }

  /**
   * Retrieve session data from session storage
   */
  public getSessionAuthorizationData(): any  {
    const result = Utilities.getSessionData(AngularAuthorizationService._session_key);
    return (result) ? result.userPermissions : null;
  }

}
