import { Chip, FormControl, InputLabel, Select } from '@material-ui/core'
import { configData } from 'apps/authz/src/config-data'
import React, { useCallback } from 'react'
import { useCommonStyles } from './common.style'

type TenantsSelectPropsType = {
  tenants: Array<string>,
  tenantsError?: string,
  onTenantsChange: any,
  hideTitle?: boolean,
  disabled?: boolean
}

export const TenantsSelect: React.FC<TenantsSelectPropsType> = ({tenants, onTenantsChange, tenantsError, hideTitle, disabled } : TenantsSelectPropsType) : JSX.Element => {
  const commonClasses = useCommonStyles();

  const sortedConfigs = configData.tenants?.sort();

  return (
    <FormControl fullWidth style={{ marginTop: 10 }}>
        {!hideTitle && <InputLabel htmlFor="roleAssignmentTenantId">Tenant *</InputLabel>}
        <Select
          value={tenants}
          onChange={onTenantsChange}
          fullWidth
          id="roleAssignmentTenantId"
          name="tenantIds"
          label="tenantIds"
          disabled={disabled}
          error={!!tenantsError}
          multiple
          renderValue={useCallback((selected) => (
            <div className={commonClasses.chips}>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={value} className={commonClasses.chip} />
              ))}
            </div>
          ), [])}
        >
          <option value="*" className={commonClasses.option}>
            * (All Tenants)
          </option>
          {sortedConfigs?.map((tenant) => (
            <option key={tenant} value={tenant} className={commonClasses.option}>
              {tenant}
            </option>
          ))}
        </Select>
      </FormControl>
  )
}
