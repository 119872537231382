import React from "react";

import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { PageTitle } from "./page-title";
import { makeStyles } from "@material-ui/core";

type Props = {
  title: string,
  subtitle?: string,
  createButtonParams?: {
    entityName: string,
    url: string,
    state?: any
  },
  deleteButtonParams?: {
    entityName: string,
    url: string
  }
}

const useEntityListTitleBarStyles = makeStyles(() => ({
  buttonStyle: {
    height: "2.8em",
    '&:hover': {
      color: '#fff'
    }
  }
}))

export const EntityListTitleBar = (props: Props) => {

  const classes = useEntityListTitleBarStyles();
  const { createButtonParams } = props,
    entityName = createButtonParams?.entityName

  const createButton = createButtonParams &&
    <Button variant="contained"
      color="primary"
      component={Link}
      to={{
        pathname: createButtonParams.url,
        state: createButtonParams.state
      }}
      className={classes.buttonStyle}
      data-testid={`create-${entityName.replace(/\s/, '-').toLowerCase()}-button`}
    >
      Create {entityName}
    </Button>

  return (
    <Grid direction="row" container justifyContent="space-between" data-testid="entity-list-title-bar">
      <PageTitle title={props.title} />
      {
        createButton
      }
    </Grid>
  )
}
