import { Directive, ElementRef, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import {
  AngularAuthorizationService,
  APP_PREFIX_KEY,
} from '@gvp-services/authorization/angular/angular-authorization.service';
import { PermissionHelpers } from '@gvp-services/permissions/PermissionHelpers';
import { AuthorizationSchema } from '@gvp-services/authorization/schema/authorization.schema';

@Directive({
  selector: '[prismPermissionCheck]',
})
/**
 * Prismatic permission check directive
 * Example usage:
 * <a *prismPermissionCheck="{tenantId: tenant, resource: 'series', action: 'delete'}" href="http://www.discovery.com">
 *     Permission - {{tenant}}|series|delete
 * </a>
 * <button *prismPermissionCheck="{tenantId: tenant, resource: 'series', action: 'delete'}">
 *     Permission - {{tenant}}|series|delete
 * </button>
 *
 * The `tenantId` field is not required
 */
export class PermissionCheckDirective {

  /**
   * Element list to apply disable styles and attributes to
   */
  public static CLICKABLE_ELEMENTS = ['a', 'i', 'div', 'span'];

  /**
   * Stores session user permissions gotten from authz
   * @private
   */
  private sessionUserPermissions: AuthorizationSchema = null;

  /**
   * List of the current user permissions
   * @private
   */
  private permissions: Array<string> = [];

  /**
   * List of the current user drn permissions
   * @private
   */
  private permissionDrns: Array<string> = [];

  /**
   * Application prefix used to verify user permission
   * @private
   */
  private readonly appPrefix: string;

  /**
   * Authorization service used to get session permission
   * @private
   */
  private _authorizationService: AngularAuthorizationService;

  /**
   * Prism Permission Check Directive
   * @param element
   * @param templateRef
   * @param viewContainer
   * @param renderer
   */
  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2,
  ) {

    this.appPrefix = localStorage.getItem(APP_PREFIX_KEY);

    this._authorizationService = new AngularAuthorizationService(this.appPrefix);
    this.loadCachedUserPermissions();
  }


  /**
   * Set directive input data: {tenantId?: xxx, resource: xxx, action: xxx}
   * @param data
   */
  @Input()
  set prismPermissionCheck(data: Record<string, string | string[] | object>) {
    this.permissions = [`${this.appPrefix}:${data.resource}:${data.action}`, 'gms:*:*'];
    this.permissionDrns = [`drn:${this.appPrefix}:*:*`, `drn:${this.appPrefix}:*:${data.resource}`];

    if (data.tenantId) {
      this.permissionDrns.push(`drn:${this.appPrefix}:${data.tenantId}:${data.resource}`)
    }

    this.loadCachedUserPermissions();
    this.updateElement();
  }

  /**
   * Render or disable element based on element permission requirements and user permissions
   */
  private updateElement(): void {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef);

    if (this.hasPermission()) {
      return;
    }

    const el = this.viewContainer.element.nativeElement.previousElementSibling;
    this.renderer.setAttribute(el, 'disabled', 'true');

    // disabling anchor, icon, and div tab keyboard event
    if (PermissionCheckDirective.CLICKABLE_ELEMENTS.includes(el.tagName.toLowerCase())) {
      this.renderer.setStyle(el, 'pointerEvents', 'none');
      this.renderer.setStyle(el, 'cursor', 'default');
      this.renderer.setAttribute(el, 'tabindex', '-1');
    }
  }

  /**
   * Check if user has the element required permission
   */
  private hasPermission(): boolean {
    return PermissionHelpers.hasPermission(this.sessionUserPermissions, this.permissions, this.permissionDrns);
  }

  /**
   * Load user permissions cached in session.
   * If no cached permissions found,
   * Call partial authorize again to reload the data from backend service
   */
  private loadCachedUserPermissions(): void {
    this.sessionUserPermissions = this._authorizationService.getSessionAuthorizationData();
  }
}
