import { Chip, ChipProps } from '@material-ui/core'
import React from 'react'
import { useCommonStyles } from './common.style'

interface StyledChipPropTypes extends ChipProps {
  strikeThrough?: boolean
}

export const StyledChip: React.FC<StyledChipPropTypes> = (props:StyledChipPropTypes) : JSX.Element => {

  const commonClasses = useCommonStyles();

  return <Chip
  className={props.strikeThrough && commonClasses?.strikeThroughChip}
  variant="outlined"
  color={props?.color}
  style={{ margin: "2px" }}
  label={props?.label}
  onClick={props?.onClick} />
}
