export enum RolesListFilters {
  Name = "Name",
  ServicePrefix = "Service Prefix"
}

export enum RolesListHeadings {
  Name = "Name",
  ServicePrefix = "Service Prefix",
  Description = "Description",
  RoleAssignmentsCount = "Role Assignments Count"
}
