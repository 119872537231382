export const GET_USER_AUTH = 'GET_USER_AUTH';


type GetUserAuthType = {
  type: string,
  payload: {
    userEmail: string,
    requestBody?: any
  }
}

export const getUserAuth: (userEmail: string, requestBody?: any) => GetUserAuthType = (userEmail: string, requestBody: any = null): GetUserAuthType => ({
  type: GET_USER_AUTH,
  payload: {
    userEmail,
    requestBody
  },
})

