import { HttpService } from '../http.service';
import { AuthorizationSchema } from './schema/authorization.schema';

export type AuthorizationCollection = {
  data: Array<AuthorizationSchema>
};

export class AuthorizationService extends HttpService {

  /**
   * User permission session key constant
   */
  protected static SESSION_USER_PERMISSIONS = 'userPermissions';

  /**
   * The API endpoint prefix
   * @protected
   */
  protected apiContext = '/authz/v1';

  /**
   * The API resource name.
   * @protected
   */
  protected resource = 'partialAuthorize';


  constructor(protected appName: string) {
    super();
  }

  public partialAuthorize(email: string, service_actions: string, service_resources: string): any {

    const request = {
      'userId': email,
      'subjects': [
        {
          'actions': [
            service_actions,
          ],
          'resources': [
            service_resources,
          ],
        },
      ],
    };
    return this.post('', request);
  }

}
