import {
  CircularProgress,
  TextField,
} from '@material-ui/core';
import { environment } from '../../../environments/environment';
import {
  IGroup,
  IRoleAssignmentErrors,
  IRoleAssignmentLocal,
} from '../../interfaces';
import { PredefinedDomainTextBox } from '../shared';
import { Autocomplete } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getGroupsAction } from '../../redux/actions';
import { TenantsSelect } from '../shared/tenants-select';
import { useAppDispatch } from '../../redux/store';

type CreateRoleAssignmentPropTypes = {
  setRoleAssignment: any;
  setRoleAssignmentErrors: any;
  roleAssignment: IRoleAssignmentLocal;
  roleAssignmentErrors: IRoleAssignmentErrors;
  areUniqueUserIds: (values: string) => boolean;
};

const filterGroupSelector = (user, groups) => ({
  totalRecords: groups?.totalRecords,
  data: user.can
    .create('roleAssignment')
    .flatMap((serviceId: string) =>
      groups.data.filter(
        (group: IGroup) => serviceId === '*' || group?.serviceId === serviceId
      )
    ),
});

const mapState = (state) => ({
  isLoading: state?.isLoading,
  groups: filterGroupSelector(state.userPermissions, state.groups),
});

export const CreateRoleAssignment = ({
  setRoleAssignment,
  roleAssignment,
  roleAssignmentErrors,
  areUniqueUserIds,
  setRoleAssignmentErrors,
}: CreateRoleAssignmentPropTypes): JSX.Element => {

  const { isLoading, groups } = useSelector(mapState);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [searchGroupText, setSearchGroupText] = useState('');

  const onRoleAssignmentChange = useCallback((event) => {
    if (event.target.name === 'tenantIds') {
      setRoleAssignment({
        ...roleAssignment,
        tenantIds:
          event.target.value.length === 0
            ? ['*']
            : event.target.value.filter((tenant) => tenant !== '*'),
      });
    } else {
      let value = event.target.value;
      if (event.target.name === 'userIds') {
        value = value.toLowerCase();
      }
      setRoleAssignment({
        ...roleAssignment,
        [event.target.name]: value,
      });
    }
  }, [])

  const uniqueEmailCheck = () => {
    if (roleAssignment?.userIds) {
      setRoleAssignmentErrors({
        ...roleAssignmentErrors,
        userIds: areUniqueUserIds(roleAssignment.userIds)
          ? ''
          : 'Please enter unique email IDs',
      });
    }
  };

  useEffect(() => {
    if (open) {
      dispatch(getGroupsAction(100, 1, true));
    }
  }, [open]);

  return (
    <form>
      <PredefinedDomainTextBox
        onChange={onRoleAssignmentChange}
        textValue={roleAssignment.userIds}
        domain={environment.production ? '@discovery.com' : '@qadci.com'}
        name="userIds"
        error={roleAssignmentErrors.userIds}
        onBlur={uniqueEmailCheck}
      />
      <Autocomplete
        multiple
        value={roleAssignment.groupNames}
        options={groups.data?.map((group) => group?.name)}
        onChange={(event, newValue) => {
          setRoleAssignment({
            ...roleAssignment,
            groupNames: newValue,
          });
        }}
        filterSelectedOptions
        loading={isLoading}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Enter group name..."
            margin="dense"
            value={searchGroupText}
            onChange={(event) => {
              setSearchGroupText(event.target.value);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <TenantsSelect
        tenants={roleAssignment?.tenantIds}
        onTenantsChange={onRoleAssignmentChange}
        tenantsError={roleAssignmentErrors?.tenantIds}/>
    </form>
  );
};
