import { CollectorOptions } from '@gvp-microapps/gvp-core-components/components/jira-issue-collector/collector-options.type'

/**
 * Jira issue collector configuration.
 * Custom Fields for discoveryinc:
 * 10008 - is the epic linc filed
 * 17460 - is the environment field. Values: 16239[Production], 16237[Staging], 16236[Test]
 */
export const COLLECTOR_OPTIONS: CollectorOptions = {
  scriptSrc: 'https://discoveryinc.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/' +
    'c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.' +
    'jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:' +
    'issuecollector.js?locale=en-US&collectorId=1d352b26',
  customFields: {
    10008: 'AUTHZ-388', // ongoing epic ticket -- to be created
  },
}
