import { call, put } from "redux-saga/effects";
import { getRoleAssignmentsSuccess, toggleLoading, toggleAlert, toggleAlertActionCreator, } from '../../slices'
import { getAsync } from "../commonAsync";
import { toKebabCase } from "../../../pages/shared";

export function* getRoleAssignmentsSaga(action: any) {
  try {
    const getRoleAssignmentsURL: string = yield `role-assignments?${action.payload?.withTotalRecords ? ("&withTotalRecords=" + action.payload?.withTotalRecords) : ""}${action.payload?.pageSize ? ("&limit=" + action.payload?.pageSize) : ""}${action.payload?.currentPage ? ("&page=" + action.payload?.currentPage) : ""}${(action.payload?.selectedSearchCriteria && action.payload?.searchText) ? ("&" + toKebabCase(action.payload?.selectedSearchCriteria) + "=" + action.payload?.searchText) : ""}`;

    yield put(toggleLoading(true))
    const response = yield call(getAsync, getRoleAssignmentsURL)
    if (yield response?.ok) {
      const result = yield response.json();
      yield put(getRoleAssignmentsSuccess(result))
    }
    else {
      const errorCode = yield response.status;
      const { Error } = yield response.json();
      const errorMessages = yield Error?.map(err => err?.message).join(',')
      yield put(toggleAlert(toggleAlertActionCreator('error', `${errorCode}: ${errorMessages}`)))
    }
    yield put(toggleLoading(false))
  }
  catch (error) {
    yield put(toggleAlert(toggleAlertActionCreator('error', error?.toString())))
  }
}
