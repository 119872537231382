import { Card, Chip, FormControl, FormHelperText, FormLabel } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { ServiceAddAction } from './service-add-action';
import { getError } from '../shared';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export const ServiceActions = (props) => {
  const classes = useStyles();

  const [error, setError] = useState(props.error);

  useEffect(() => {
    setError(props?.error)
  }, [props?.error])

  const handleDelete = (actionToDelete) => () => {
    const changedData = props?.service?.actions?.filter(action => action !== actionToDelete)
    props.onActionsChange(changedData)
  }

  const handleActionAdded = (newAction) => {
    props.onActionsChange([...props.service?.actions, newAction])
  }
  return (
    <Card hidden={!!getError('prefix', props?.service?.prefix, null, props?.service)}>
      <FormControl variant="outlined" fullWidth style={{ padding: 20 }} error={!!error}>
        <FormLabel component="legend">Actions *</FormLabel>
        <div >
          {
            props?.service?.actions?.map((action, idx) => (
              <Chip key={idx}
                onDelete={handleDelete(action)}
                label={action}
                variant="outlined"
                color="primary"
                size="medium"
                className={classes.chip}
              />
            ))
          }

          {!props?.service?.actions?.length && <Alert severity="info">pick options below to add a new action</Alert>}
        </div>

        <ServiceAddAction onActionAdded={handleActionAdded}
                          actions={props?.service?.actions}
                          prefix={props?.service?.prefix}
                          setError={setError}
        />
        {
          error && <FormHelperText>{error}</FormHelperText>
        }
      </FormControl>
    </Card>
  )
}
