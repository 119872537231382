import { Component, Input } from '@angular/core';
import { Helpers } from '../../utils/helpers';

@Component({
  selector: 'prism-loading-spinner',
  template: `
    <div [ngClass]="{ 'd-flex justify-content-center': block, 'd-inline-block': !block }">
      <div class="spinner-grow text-info" [ngClass]="{ 'spinner-grow-sm': small }" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>`,
})
export class LoadingSpinnerComponent {

  /**
   * If true, the small version of this spinner is displayed.
   */
  private _small = false;

  get small(): boolean {
    return this._small;
  }

  /**
   * Setter that converts input flag to boolean if it is a string.
   * @param small
   */
  @Input() set small(small: boolean) {
    this._small = Helpers.convertStringToBoolean(small);
  }

  /**
   * If true, the loading spinner will display as a block element.
   */
  private _block: boolean = true;

  get block(): boolean {
    return this._block;
  }

  /**
   * Setter that converts input flag to boolean if it is a string.
   * @param block
   */
  @Input() set block(block: boolean) {
    this._block = Helpers.convertStringToBoolean(block);
  }

}
