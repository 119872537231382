import React, { useState, useEffect } from 'react'
import { IconButton, Tooltip, CircularProgress, Grid } from '@material-ui/core'
import { IStatement } from '../../interfaces'
import { DialogBox } from '../shared'
import { ViewStatements } from './view-statements'
import ListAltIcon from '@material-ui/icons/ListAlt';
import { getAsync } from "../../redux/sagas/commonAsync";

type StatementsDialogPropTypes = {
  roleId?: string,
  statements?: Array<IStatement>
}

export const StatementsDialog: React.FC<StatementsDialogPropTypes> = ({ roleId, statements }: StatementsDialogPropTypes): JSX.Element => {
  const [openStatementsView, setOpenStatementsView] = useState<boolean>(false)
  const [statementsState, setStatementsState] = useState<Array<IStatement> | null | undefined>(statements)

  const onStatementsViewClose: () => void = (): void => setOpenStatementsView(false);
  const onStatementsViewClick: () => void = (): void => setOpenStatementsView(true)

  useEffect(() => {
    setStatementsState(statements)
  }, [statements])

  useEffect(() => {
    if (openStatementsView && roleId) {
      getAsync(`roles/${roleId}`)
        .then(result => result.json())
        .then(role => setStatementsState(role?.statements))
    }
  }, [openStatementsView])

  return (
    <>
      <Tooltip title="Statements"><IconButton data-testid="statements-dialog" onClick={onStatementsViewClick}><ListAltIcon /></IconButton></Tooltip>
      <DialogBox title="Statements" open={openStatementsView} handleClose={onStatementsViewClose}>

        {(roleId && !statementsState) ? <Grid container alignItems="center" justifyContent="center"><CircularProgress /></Grid> : <ViewStatements statements={statementsState} />}

      </DialogBox>
    </>
  )
}
