import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { makeStyles } from '@material-ui/core';

import '@gvp-microapps/web-components';
import Routes from './routes';
import TopNav from './top-nav'
import { Alert } from './pages/shared';
import { COLLECTOR_OPTIONS } from '../constants';
import { oktaAuth } from './okta-auth';

const useAuthzAppStyles = makeStyles(() => ({
  authzBody: {
    padding: '1rem',
    backgroundColor: '#fff',
  },
}));

const mapState = (state) => ({
  alertMessage: state?.alert?.message,
  alertType: state?.alert?.type
})

const App: React.FC = (): JSX.Element => {
  const history = useHistory();
  const restoreOriginalUri = useCallback(async (_oktaAuth: any, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  }, []);

  const { alertMessage, alertType } = useSelector(mapState)

  const classes = useAuthzAppStyles()

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <TopNav />
      <div className={classes?.authzBody}>
        <Routes />
      </div>
      <Alert message={alertMessage} severity={alertType} />
      <prism-jira-issue-collector configuration={JSON.stringify(COLLECTOR_OPTIONS)} />
    </Security>
  )
}

export default App;
