import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionsFilterComponent } from './options-filter.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [OptionsFilterComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    TooltipModule,
  ],
  exports: [OptionsFilterComponent],
})
export class OptionsFilterModule {
}
