import { NgModule } from '@angular/core';
import { SortableHeaderComponent } from './sortable-header.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [SortableHeaderComponent],
  exports: [SortableHeaderComponent],
})

export class SortableHeaderModule {
}
