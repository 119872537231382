import { Component, Input } from '@angular/core';
import { Helpers } from '../../utils/helpers';

export type ListableType = string | number

export interface InitList {
  el: ListableType;
  length: number;
}

@Component({
  selector: 'prism-expandable-list',
  template: `
    <div *ngIf="initList?.el; else emptyList" class="expandable-list-container">
      <span>{{ initList.el }}</span>
      <span *ngIf="initList.length > 0"
            class="badge badge-primary pointer ml-1"
            [popover]="list.join(', ')"
            triggers="click"
            [outsideClick]="true"
      >
        +{{ initList.length }} more
      </span>
    </div>
    <ng-template #emptyList>
      <span class="text-muted">{{ emptyLabel }}</span>
    </ng-template>
  `,
  styles: [`
    .expandable-list-container {
      white-space: nowrap;
    }

    .pointer {
      cursor: pointer;
    }
  `],
})
export class ExpandableListComponent {

  /**
   * A list of the element to display
   * @private
   */
  private _list: Array<ListableType>

  /**
   * An init object to display on initial load. The first element of the array and length of the more elements
   */
  public initList: InitList;

  /**
   * A label to display if array is empty or undefined
   */
  @Input()
  public emptyLabel: string = '';

  /**
   * @see _list
   * @param value
   */
  @Input()
  set list(value: Array<ListableType> | string) {
    this._list = Helpers.jsonParseString(value);
    this.initiateList();
  }

  /**
   * @see _list
   */
  get list(): Array<ListableType> {
    return this._list;
  }

  /**
   * Inits the initList.
   * @private
   */
  private initiateList(): void {
    if (!this.list || this.list.length === 0) {
      this.initList = { el: '', length: 0 };
    }

    this.list.sort((a, b) => a.toString().length - b.toString().length)

    this.initList = {
      el: this.list[0],
      length: this.list.length - 1,
    }
  }
}
