import { Component, Input } from '@angular/core';
import { Helpers } from '../../utils/helpers';

export type BadgePillValue = string | { value: string, theme?: string }

@Component({
  selector: 'prism-badge-pill-list',
  template: `<span class="badge-pill-list" *ngIf="!noDataFound">
                <h5>
                  <prism-badge-pill
                    *ngFor="let value of values"
                    [value]="getValue(value)"
                    [theme]="theme">
                  </prism-badge-pill>
                </h5>
              </span>
  <span class="font-italic text-secondary" *ngIf="noDataFound">No Data Found</span>`,
  styles: ['.badge-pill-list { line-height: 32px;}'],
})
export class BadgePillListComponent {

  /**
   * The values to be displayed inside of badge pills.
   *
   * @type Array<string> | Array<{value: string, theme?: string}>
   *   Can be either an array of strings, or an array of encapsulated objects containing a value and the theme classname.
   */
  private _values: Array<BadgePillValue>;

  /**
   * @see _values
   */
  get values(): Array<BadgePillValue> {
    return this._values;
  }

  /**
   * Setter that parses input values data to array if it is a stringified data.
   * @param values
   */
  @Input()
  set values(values: Array<BadgePillValue>) {
    this._values = Helpers.jsonParseString(values);
  }

  @Input()
  public theme: string | string[] | Set<string> | { [klass: string]: unknown; } = 'badge-primary';

  /**
   * Return a string value gotten from the _values array single element
   * @param singleValue
   */
  public getValue(singleValue: BadgePillValue): string {
    if (typeof singleValue === 'string') {
      return singleValue;
    }
    return singleValue.value || '';
  }

  /**
   * Determines if no data was found.
   */
  get noDataFound(): boolean {
    return !this.values || this.values.length === 0;
  }
}
