import React from "react";
import { useParams } from "react-router-dom";

import { ServiceForm } from "./service-form";
import { EntityListTitleBar } from "../shared";

export const ServiceDetails = () => {
  // @ts-ignore
  let { id } = useParams();
  return (
    <>
      <EntityListTitleBar title={"Service Details"} subtitle={'#' + id} />
      <ServiceForm id={id} />
    </>
  )
}
