import { Component, Input } from '@angular/core';

@Component({
  selector: 'prism-resource-not-found',
  template: `
    <div class="jumbotron text-center text-muted bg-transparent">
      <h1 class="display-1">
        <i class="fa fa-exclamation"></i>
      </h1>

      <h1 class="display-4">{{ heading }}</h1>
      <p class="lead">
        <ng-content></ng-content>
      </p>

      <a [href]="backHref" class="btn btn-primary" *ngIf="backHref !== null">{{ backText }}</a>
    </div>
  `,
})

export class ResourceNotFoundNoticeComponent {

  /**
   * The heading of the notice to display.
   */
  @Input() heading: string = 'Resource Not Found';

  /**
   * The URL to be used on the 'Back' button. If `null`, the button will not be displayed.
   */
  @Input() backHref: string | null = null;

  /**
   * The text to be shown in the 'Back' button.
   */
  @Input() backText: string = 'Go Back';
}
