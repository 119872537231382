import { CircularProgress, Grid, List, ListItem, ListItemText } from '@material-ui/core'
import React from 'react'

type DenseListType = {
  listData: Array<string>,
  loading?: boolean
}

export const DenseList: React.FC<DenseListType> = ({listData, loading}: DenseListType) : JSX.Element => {
  if(loading){
    return <Grid container alignItems="center" justifyContent="center"><CircularProgress /></Grid>
  }
  if(!listData?.length){
    return <Grid container alignItems="center" justifyContent="center">No data found</Grid>
  }
  return <List dense disablePadding>
  {(listData)?.map((value) => {
    return (
      <ListItem key={value} dense>
        <ListItemText primary={value} />
      </ListItem>
    )
  })}
</List>
}
