import { makeStyles } from "@material-ui/core";

export const useCreateRoleStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
        '& > *': {
            marginBottom: theme.spacing(2)
        }
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    option: {
        paddingLeft: 16,
        paddingTop: 8,
        paddingBottom: 8,
        '&:hover': {
            backgroundColor: '#00000022',
            cursor: 'pointer'
        },
        '&[aria-selected=true]': {
            backgroundColor: '#00000044',
            cursor: 'pointer'
        }
    },
    statementsAddButton: {
        padding: 0
    },
    statementsContainer: {
        padding: 20
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    accordion: {
        paddingBottom: 0
    }
}));
