import { Component, Input } from '@angular/core';

@Component({
  selector: ' prism-badge-pill',
  template: '<span class="badge mr-1 badge-pill" [ngClass]="theme">{{ value }}</span>',
})
/**
 * The Badge Pill component is a reusable editable component
 * that displays string data such as a listable app name of
 * a series or a content package name of a series.
 */
export class BadgePillComponent {
  /**
   * The text value of the badge pill.
   */
  @Input()
  public value: string;

  /**
   * The appended theme classname of the badge pill.
   *
   * This uses the NgClass input format: {@link https://angular.io/api/common/NgClass}
   *
   * Recommended Bootstrap values:
   * `badge-primary` | `badge-secondary`
   * `badge-success` | `badge-danger`
   * `badge-info` | `badge-warning`
   * `badge-light` | `badge-dark`
   */
  @Input()
  public theme: string | string[] | Set<string> | { [klass: string]: unknown; } = 'badge-primary';
}
