export interface CollectorOptions {

  /**
   * Url for the jira collector script
   */
  scriptSrc: string;

  /**
   * Pre-populate values in the form, the key references a field in Jira.
   */
  customFields?: { [key: string]: string };
}
