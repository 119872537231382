import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationModalComponent } from './confirmation-modal.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [ConfirmationModalComponent],
  imports: [
    CommonModule,
    TooltipModule,
    ModalModule.forRoot(),
  ],
  exports: [ConfirmationModalComponent],
})
export class ConfirmationModalModule {
}
