export const DELETE_ROLE_ASSIGNMENT = 'DELETE_ROLE_ASSIGNMENT';

type DeleteRoleAssignmentActionType = {
    type: string
    payload: {
        assignmentId: string
    }
}

export const deleteRoleAssignmentAction: (assignmentId: string) => DeleteRoleAssignmentActionType = (assignmentId): DeleteRoleAssignmentActionType => ({
    type: DELETE_ROLE_ASSIGNMENT,
    payload: {
        assignmentId
    }
})
