import React, { useState, useCallback, useEffect, useRef, FC } from 'react';
import { makeStyles } from "@material-ui/core";
import { Table as MuiTable, TableHead, TableBody, TableRow, TableCell, TablePagination } from "@material-ui/core";

const useStyles = makeStyles((_) => ({
  tableWrapper: {
    border: "1px solid #ddd",
    borderRadius: "4px",
    overflow: "auto",
    maxHeight: "100%",
    position: "relative",
    '& table': {
      width: "100%",
      display: "grid",
      "& th": {
        position: "relative",
      },
      "& th span, td span": {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        display: "block"
      },
      "& thead, & tbody, & tr": {
        display: "contents"
      },
      "& thead th:not(:last-child) div": {
        display: "block",
        position: "absolute",
        cursor: "col-resize",
        width: "0.5rem",
        right: "0",
        top: "0",
        zIndex: 1,
        borderRight: "2px solid #eee",
        "&:hover": { borderColor: "#ccc" },
        "&.active": { borderColor: "#ddd" }
      },
      "& thead tr th:last-child div": {
        display: "none"
      },
    }
  },
  tableCell: {
    display: "flex",
    alignItems: "center",
    padding: "0.25rem 1rem",
    minHeight: "3rem"
  },
  hideExtraText: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}))

const createHeaders = (headers) => {
  return headers.map(({ name, property, width, valueGetter, renderCell }) => ({
    name,
    property,
    width,
    valueGetter,
    renderCell,
    ref: useRef(),
  }));
}

type HeadingType = {
  name: string;
  property: string;
  width?: string,
  valueGetter?: (row: any) => string | number,
  renderCell?: any
}

type TableProps = {
  headings: Array<HeadingType>,
  minCellWidth: number,
  rowsPerPage?: number,
  tableContent: any,
  style?: any,
  className?: any
}

export const Table: FC<TableProps> = ({
  headings,
  minCellWidth,
  tableContent,
  rowsPerPage,
  style,
  className
}): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<number>(null);
  const tableElement = useRef(null);
  const columns = createHeaders(headings);

  const classes = useStyles();

  const mouseMove = useCallback((event) => {
    const gridColumns = columns.map((column, index) => {
      if (index === activeIndex) {
        const width = event.clientX - column.ref.current.offsetLeft;
        if (width >= minCellWidth) {
          return `${width}px`;
        }
      }
      return `${column?.ref?.current?.offsetWidth}px`;
    });

    tableElement.current.style.gridTemplateColumns =
      `${gridColumns.join(' ')}`;

  }, [activeIndex, columns, minCellWidth]);


  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    return () => {
      removeListeners();
    }
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  const mouseDown = (index) => {
    setActiveIndex(index);
  }

  const getTableColumnTemplates = () => {
    return columns.map(column => `minmax(150px, ${column?.width || "1fr"})`).join(" ")
  }

  //PAGINATION LOGIC
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPageLocal, setRowsPerPageLocal] = useState(rowsPerPage || 5)
  const lastIndex = (currentPage + 1) * rowsPerPageLocal;
  const startIndex = currentPage * rowsPerPageLocal;
  const currentTableContent = tableContent?.slice(startIndex, lastIndex);
  return (
    <>
      <div className={`${classes.tableWrapper} ${className}`} style={style}>
        <MuiTable ref={tableElement} style={{ gridTemplateColumns: getTableColumnTemplates() }}>
          <TableHead>
            <TableRow>
              {columns.map(({ ref, name }, index: number): JSX.Element => (
                <TableCell ref={ref} key={name} className={classes.hideExtraText}>
                  <span>{name}</span>
                  <div
                    data-testid="table-separator"
                    style={{ height: "24px", marginTop: "16px" }}
                    onMouseDown={() => mouseDown(index)}
                    className={`${activeIndex === index ? 'active' : 'idle'}`} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              currentTableContent?.map((row, rowIndex: number): JSX.Element => (
                <TableRow key={rowIndex}>
                  {
                    columns.map((column: HeadingType, cellIndex: number): JSX.Element => (
                      <TableCell key={cellIndex} className={`${classes.tableCell} ${classes.hideExtraText}`}>
                        {
                          (column.renderCell && column.renderCell(currentTableContent[rowIndex]))
                          ||
                          (column.valueGetter && column.valueGetter(currentTableContent[rowIndex]))
                          ||
                          row[column.property]
                        }
                      </TableCell>
                    ))
                  }
                </TableRow>
              ))

              ||

              <p style={{ padding: "1rem" }}>No Rows</p>
            }
          </TableBody>
        </MuiTable>
      </div>
      <TablePagination
        component="div"
        count={tableContent?.length || 0}
        page={currentPage}
        onRowsPerPageChange={(event) => setRowsPerPageLocal(Number(event.target.value))}
        onPageChange={(event, newPage: number) => setCurrentPage(newPage)}
        rowsPerPage={rowsPerPageLocal}
        rowsPerPageOptions={[5, 10]}
      />
    </>
  );
}
