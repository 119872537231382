import { call, put } from 'redux-saga/effects';
import { postAsync } from './commonAsync';
import { IUserPermissions } from '../../interfaces';
import {
  toggleAlert,
  toggleAlertActionCreator,
  setUserPermissions,
} from '../slices';

type Permissions = {
  actions: {
    [key: string]: Map<string, { [serviceIds: string]: Array<string> }>;
  };
};
const permissions: Permissions = {
  actions: {},
};

const baseActions: Array<string> = ['read', 'create', 'update', 'delete', '*'];
export const userActions: IUserPermissions = {
  can: {},
};

export const createUserPermissions = () => {
  baseActions?.forEach(
    (baseAction: string) =>
    (userActions.can[baseAction] = (
      baseResource: string,
      serviceId: string,
      resourceId: string,
      sendRawData: boolean
    ) => {
      const checkResourceAuth =
        permissions?.actions['*']?.get('*') ||
        permissions?.actions[baseAction]?.get(baseResource);
      if (serviceId) {
        if (resourceId) {
          return (
            checkResourceAuth &&
            ((
              checkResourceAuth['*'] || checkResourceAuth[serviceId]
            )?.includes('*') ||
              (
                checkResourceAuth['*'] || checkResourceAuth[serviceId]
              )?.includes(resourceId))
          );
        } else {
          return (
            checkResourceAuth &&
            (checkResourceAuth['*'] || checkResourceAuth[serviceId])
          );
        }
      } else if (checkResourceAuth) {
        return sendRawData
          ? checkResourceAuth
          : Object.keys(checkResourceAuth);
      } else return null;
    })
  );
  //to check if current user is service owner/admin of a serviceId,
  //roleName should be checked if it includes "owner"/"admin" in it.
  //example: roleName.includes("owner") && user.isServiceOwner(serviceId)
  userActions.isServiceOwner = (serviceId) => userActions.can.update('service', null, null, true)?.[serviceId]?.includes('*')
  userActions.isServiceAdmin = (serviceId) => userActions.can.update('service', null, null, true)?.[serviceId]?.includes('statements')
  userActions.isSuperAdmin = () => userActions.can['*']('*', null, null, true)?.['*']?.includes('*')
};

export const parseIds = (value, result) => {
  value?.forEach((resource) => {
    const baseResourse = resource?.split(':')[2];
    const serviceId = resource?.split(':')[3];
    const resourceId = resource?.split(':')[4];
    // super admin condition
    if (baseResourse === '*' && serviceId === '*') {
      result = {
        '*': ['*'],
      };
      return result;
    }
    result = {
      ...result,
      [serviceId]: [...new Set([...(result[serviceId] || []), resourceId])],
    };
  });
  return result;
};

export const createPermissions = (response) => {
  Object.entries(response['allowedResources'])?.forEach(
    ([key, value]: [string, Array<string>]): void => {
      const baseResource: string = key?.split(':')[1],
        baseAction: string = key?.split(':')[2];
      let result = {};
      const parsedValue = parseIds(value, result);

      if (!permissions?.actions[baseAction])
        permissions.actions[baseAction] = new Map();
      permissions?.actions[baseAction]?.set(baseResource, parsedValue);
    }
  );
};

export function* partialAuthSaga(action) {
  try {
    const response = yield call(postAsync, 'partialAuthorize', {
      userId: action.payload.userEmail,
      subjects: [
        {
          actions: ['authz:*:*'],
          resources: ['drn:authz:*:*'],
        },
      ],
    });
    if (yield response.ok) {
      const result = yield response.json();
      yield createPermissions(result);
      yield createUserPermissions();
      yield put(setUserPermissions(userActions));
    } else {
      const errorCode = yield response.status;
      const { Error } = yield response.json();
      const errorMessages = yield Error?.map((error) => error?.message).join(
        ','
      );
      yield put(
        toggleAlert(
          toggleAlertActionCreator('error', `${errorCode}: ${errorMessages}`)
        )
      );
    }
  } catch (error) {
    yield put(
      toggleAlert(toggleAlertActionCreator('error', error?.toString()))
    );
  }
}
