import { IRole } from "../../../interfaces";

export const UPDATE_ROLE = 'UPDATE_ROLE';

type UpdateRoleActionType = {
    type: string
    payload: {
        role: IRole,
        push: any,
        id: string
    }
}

export const updateRoleAction: (role: IRole, push: any, id: string) => UpdateRoleActionType = (role, push, id): UpdateRoleActionType => ({
    type: UPDATE_ROLE,
    payload: {
        role,
        push,
        id
    }
})
