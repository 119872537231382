import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableCell,
  makeStyles,
  Table,
  TableBody,
  DialogProps,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

type DialogBoxProps = {
  open: boolean,
  handleClose?: () => void,
  title?: string,
  description?: string,
  handleConfirm?: () => void;
  children?: JSX.Element;
  showAsConfirmDialog?: boolean;
  critical?: boolean;
  className?: any;
  showAsLoader?: boolean;
  confirmButtonLabel?: string;
  isConfirmButtonDisabled?: boolean;
  size?: DialogProps['maxWidth'],
}

const useStyles = makeStyles((theme) => ({
  removeDivider: {
    borderBottom: 'none',
  },
  descriptionCell: {
    padding: 0,
  },
  title: {
    '& > h2': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '1rem',
      fontWeight: 400,
    },
  },
}))

export const DialogBox: React.FC<DialogBoxProps> = ({ open, handleClose, handleConfirm, title, description, children, showAsConfirmDialog, critical, className, showAsLoader, confirmButtonLabel, isConfirmButtonDisabled, size }: DialogBoxProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={className}
        fullWidth
        maxWidth={size}
      >
        {title && <DialogTitle className={classes.title}>{title}</DialogTitle>}
        <DialogContent dividers>
          <Table>
            <TableBody>
              <TableRow>
                {showAsLoader && <TableCell data-testid="dialog-box-showAsLoader" className={classes.removeDivider}>
                  <CircularProgress />
                </TableCell>}
                {description && (
                  <TableCell data-testid="dialog-box-description"
                             className={`${classes.removeDivider} ${classes.descriptionCell}`}
                  >
                    <DialogContentText id="alert-dialog-description">
                      {critical ? <WarningIcon color="secondary" />: ''}
                      {description}
                    </DialogContentText>
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>

          {children}
        </DialogContent>
        {
          !showAsLoader &&
          <DialogActions>
            <Button onClick={handleClose} color="primary">Cancel</Button>
            {showAsConfirmDialog && (
              <Button data-testid="dialog-box-showAsConfirmDialog"
                      disabled={isConfirmButtonDisabled} onClick={handleConfirm} color="primary"
              >{confirmButtonLabel || 'Yes'}
              </Button>
            )}
          </DialogActions>
        }
      </Dialog>
    </div>
  );
}
