import { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Snackbar,
  TextField,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import MuiAlert from '@material-ui/lab/Alert';
import { ServiceActions } from './service-actions';
import { ServiceToken } from './service-token';
import { useHistory } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import { GetItem } from '../shared/request/get-item';
import { RequestHeaders } from '../shared/request/request-headers';
import { allFieldsValid, DialogBox, getError } from '../shared';
import { useSelector } from 'react-redux';
import { getServicesAction } from '../../redux/actions';
import { useAppDispatch } from '../../redux/store';
import { toggleAlert, toggleAlertActionCreator } from '../../redux/slices';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const mapState = (state) => ({
  user: state?.userPermissions,
  services: state?.services,
})

export const ServiceForm = ({ id }) => {

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { user, services } = useSelector(mapState);

  type ErrorsType = {
    name: string;
    owner: string;
    prefix: string;
    actions: string;
    token: string;
  };

  type DialogBoxProps = {
    open: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
    description: string;
  };

  const initialErrors: ErrorsType = {
    name: '',
    owner: '',
    prefix: '',
    actions: '',
    token: '',
  };

  const defaultToast = { open: false, severity: 'success', text: 'Saved' };
  const [item, setItem] = useState({
    id: '',
    name: '',
    owner: '',
    prefix: '',
    actions: [],
    tokenValidation: false,
    token: {
      tokenClaims: {
        cid: '',
        aud: '',
        iss: '',
      },
      tokenType: 'jwt',
      tokenProvider: 'okta',
    },
  });

  const [errors, setErrors] = useState(initialErrors)
  const [toast, setToast] = useState(defaultToast);
  const [newPrefix, setNewPrefix] = useState('');
  const [dialogBoxProps, setDialogBoxProps] = useState<DialogBoxProps>({
    open: false,
    handleClose: () => null,
    handleConfirm: () => null,
    description: '',
  });
  const serviceNames = services?.data?.map(service => service.name.toLowerCase());

  const oPrefixChangeConfirm = () => {
    setItem({
      ...item,
      prefix: newPrefix,
      actions: item.actions.map(action => {
        const actionParts = action.split(':')
        return `${newPrefix}:${actionParts[1]}:${actionParts[2]}`
      }),
    })
    setDialogBoxProps({
      ...prefixDialogBoxProps,
      open: false,
    })
  }

  const onDialogCancel = () => {
    setDialogBoxProps({
      ...dialogBoxProps,
      open: false,
    })
  }

  const prefixDialogBoxProps: DialogBoxProps = {
    open: false,
    handleClose: onDialogCancel,
    handleConfirm: () => oPrefixChangeConfirm(),
    description: 'Changing prefix requires a change to all actions (as displayed). Would you like to continue?',
  }

  // SHOW WARNING IF PREFIX IS CHANGED
  useEffect(() => {
    if (item.actions.length) {
      setDialogBoxProps({
        ...prefixDialogBoxProps,
        open: true,
      })
    }
  }, [newPrefix]);

  // GET ALL SERVICES IF SERVICES REDUX STATE IS EMPTY
  useEffect(() => {
    if (services?.length === 0) {
      dispatch(getServicesAction())
    }
    return () => {
      abortController.abort();
    }
  }, []);

  const valueChanged = (event) => {
    const propName = event.target.name;
    const propValue = event.target.value;
    const newItem = {
      ...item,
      [propName]: propValue,
    };

    switch (propName) {
      case 'prefix':
        item.actions.length ? setNewPrefix(propValue) : setItem(newItem);
        checkForErrors(propName, propValue, null, item);
        if (propValue && item.actions.length <= 0) {
          setErrors({
            ...errors,
            actions: 'At least one resource action pair is required.',
          });
        }
        break;
      case 'name':
        checkForErrors(propName, propValue, serviceNames, null);
        setItem(newItem);
        break;
      case 'owner':
        setItem({
          ...item,
          [propName]: propValue.toLowerCase(),
        });
        checkForErrors(propName, propValue, null, item);
        break;
      case 'tokenValidation':
        setItem({
          ...item,
          [event.target.name]: event.target.checked,
        });
        break;
      default:
        setItem(newItem);
    }
  };

  const submitConfirm = async () => {
    setDialogBoxProps({
      ...dialogBoxProps,
      open: false,
    });
    const url = `${environment.apiBaseUrl}services${id ? '/' + id : ''}`;

    delete item.id;
    // temporary
    // item.name = item.prefix

    fetch(url, {
      method: id ? 'PUT' : 'POST',
      body: JSON.stringify(item),
      headers: await RequestHeaders.get(),
    })
      .then(res => {
        if (!res.ok) {
          if (res.status === 403) {
            setToast({ open: true, severity: 'error', text: 'Unauthorized' });
          } else {
            res.json().then(data => {
              setToast({ open: true, severity: 'error', text: typeof data === 'string' ? data : JSON.stringify(data) });
            })
          }
        } else {
          if (res.status >= 200 && res.status < 300) {
            if (!id) {
              // if a new service is created successfully, create default roles for it.
              history.push('/roles')
            } else {
              // if an existing service is updated successfully, simply go back to list page.
              history.push('/services')
            }
          } else {
            const err = 'Error code: ' + res.status + (res.statusText ? '. Error message: ' + res.statusText : '')
            setToast({ open: true, severity: 'error', text: err });
          }
        }
      })
      .catch(err => {
        setToast({ open: true, severity: 'error', text: typeof err === 'string' ? err : 'Error occurred. Can not save.' });
      });
  }

  const submit = (e) => {
    e.preventDefault();
    if (allServiceFieldsAreValid()) {
      if (id) {
        setDialogBoxProps({
          handleConfirm: submitConfirm,
          handleClose: onDialogCancel,
          open: true,
          description: `Do you want to confirm the changes with ${item.name} service?`,
        })
      } else {
        submitConfirm();
      }
    }
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast({ open: false, severity: 'success', text: 'saved' });
  };

  const handleActionsChange = (actions) => {
    if (actions.length > 0) {
      setErrors({
        ...errors,
        actions: '',
      })
    }
    setItem({
      ...item,
      actions: actions,
    })
  }

  const handleTokenChange = (token) => {
    setItem({
      ...item,
      token: token,
    });
  };

  const handleTokenError = (tokenError) => {
    setErrors({
      ...errors,
      token: tokenError,
    });
  }
  const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

  const abortController = new AbortController();

  useEffect(() => {
    if (id) {
      if (user) {
        if (user?.can?.update('service')) {
          GetItem('services', id, setItem)
        } else {
          dispatch(toggleAlert(toggleAlertActionCreator('error', 'Not authorized to update this service.')))
          history.push('/services')
        }
      }
    } else if (!user?.can?.create('service')) {
      dispatch(toggleAlert(toggleAlertActionCreator('error', 'Not authorized to create a service')))
      history.push('/services')
    }

    return () => {
      abortController.abort();
    };
  }, [user]);

  const checkForErrors = (name, value, item, objectToVerify) => {
    setErrors({
      ...errors,
      [name]: getError(name, value, item, objectToVerify),
    })
  }

  const onBlur = (event) => {
    checkForErrors(event.target.name, event.target.value, null, item);
  }

  const allServiceFieldsAreValid = () => {
    const serviceToVerify = {
      name: item.name,
      owner: item.owner,
      prefix: item.prefix,
      actions: item.actions,
    }
    const errorsObject = allFieldsValid(serviceToVerify) as ErrorsType;
    setErrors(errorsObject)
    return !Object.values(errorsObject).reduce((a, b) => a + b)
  }

  const canSubmit = () => Object.values(errors).join('').length <= 0;

  return (
    !user
      ?
      <LinearProgress />
      :
      <Container component="main" maxWidth="sm" data-testid="create-service">
        <CssBaseline />
        <div className={classes.paper}>
          <form className={classes.form} noValidate onSubmit={submit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={item.name}
                  onChange={valueChanged}
                  id="name"
                  label="Name"
                  name="name"
                  type="text"
                  onBlur={(event) => checkForErrors(event.target.name, event.target.value, serviceNames, null)}
                  error={!!errors.name}
                  helperText={errors.name}
                  disabled={id}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={item.owner}
                  onChange={valueChanged}
                  id="owner"
                  label="Owner"
                  name="owner"
                  type="email"
                  onBlur={onBlur}
                  error={!!errors.owner}
                  helperText={errors.owner}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={item.prefix}
                  onChange={valueChanged}
                  id="prefix"
                  label="Prefix"
                  name="prefix"
                  type="text"
                  onBlur={onBlur}
                  error={!!errors.prefix}
                  helperText={errors.prefix}
                  disabled={id}
                />
              </Grid>

              <Grid item xs={12}>
                <ServiceActions service={item} onActionsChange={handleActionsChange} error={errors.actions} />
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.tokenValidation}
                        onChange={valueChanged}
                        id="tokenValidation"
                        name="tokenValidation"
                        color="primary"
                      />
                    }
                    label="Token validation required"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} style={{marginBottom: '1rem'}} hidden={! item.tokenValidation}>
                 <ServiceToken service={item} onTokenError={handleTokenError} onTokenChange={handleTokenChange} />
              </Grid>
            </Grid>

            <Grid container direction="row" spacing={2}>
              <Grid item sm={6}>
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push('/services')}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item sm={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!canSubmit()}
                >
                  {id ? 'Save Service' : 'Create Service'}
                </Button>
              </Grid>
            </Grid>

            <Snackbar open={toast.open} autoHideDuration={6000} onClose={handleToastClose}>
              <Alert onClose={handleToastClose} severity={toast.severity}>
                {toast.text}
              </Alert>
            </Snackbar>
          </form>
        </div>
        <DialogBox
          {...dialogBoxProps}
          showAsConfirmDialog
        />
      </Container>
  );
}
