import { Grid } from '@material-ui/core'
import { environment } from 'apps/authz/src/environments/environment';
import React, { useCallback } from 'react'
import { PredefinedDomainTextBox, toCamelCase } from '../shared';
import { StyledChip } from '../shared/styled-chip';

type AddMembersAdminsPropTypes = {
  setNewGroup: any;
  newGroup: any;
  newGroupErrors: any;
  areValidateEmails: () => boolean;
}

export const AddMembersAdmins: React.FC<AddMembersAdminsPropTypes> = ({ setNewGroup,
  newGroup,
  newGroupErrors,
  areValidateEmails }: AddMembersAdminsPropTypes): JSX.Element => {

  const onAddMembersAdminsChange = (event) => {
    setNewGroup({
      ...newGroup,
      [event.target.name]: event.target.value.toLowerCase()
    })
  }

  const textFields = ["Members", "Admins"]

  const onChipClick = (textField, entity) => {
    if (newGroup[`${toCamelCase(textField)}ToBeDeleted`]?.includes(entity)) {
      setNewGroup({
        ...newGroup,
        [`${toCamelCase(textField)}ToBeDeleted`]: (newGroup[`${toCamelCase(textField)}ToBeDeleted`] || [])?.filter(value => value !== entity)
      })
    }
    else
      setNewGroup({
        ...newGroup,
        [`${toCamelCase(textField)}ToBeDeleted`]: [...(newGroup[`${toCamelCase(textField)}ToBeDeleted`] || []), entity]
      })
  }

  return (
    <Grid container direction="column" spacing={2}>
      {
        textFields?.map(textField => {
          const name = `${toCamelCase(textField)}ToBeAdded`
          const domain = environment.production ? "@discovery.com" : "@qadci.com"
          return <Grid item key={textField}>
            <PredefinedDomainTextBox
              onChange={onAddMembersAdminsChange}
              textValue={newGroup[name]}
              domain={domain}
              name={name}
              label={`Enter ${textField} Email IDs`}
              error={newGroupErrors[name]}
              onBlur={areValidateEmails}
              entity={textField}
              startAdornment={
                <div>
                  {newGroup[toCamelCase(textField)]?.map(entity => <StyledChip key={entity} strikeThrough={newGroup[`${toCamelCase(textField)}ToBeDeleted`]?.includes(entity)} color={newGroup[`${toCamelCase(textField)}ToBeDeleted`]?.includes(entity) ? "secondary" : "primary"} label={entity}
                  onClick={(_event) => onChipClick(textField, entity)} />)}
                </div>
              }
            />
          </Grid>
        })
      }
    </Grid>
  )
}
