export class PrismUserMenu extends HTMLElement {
  private readonly shadow: ShadowRoot;

  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: 'open' });
    this.shadow.innerHTML = `
<style>
  #nav-profile-settings {
    display: flex;
    justify-content: flex-end;
  }
  
  #app-profile-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #app-profile-menu {
    display: none;
  }
  #app-profile-menu button{
    background-color: transparent;
    border: 0;
    display: block;
    margin: 0 5px 0 5px;
  }
  #app-profile-menu-items {
    background-color: white;
    border: 1px solid gray;
    border-radius: .25rem;
    position: absolute;
    right: 1px;
    top: 55px;
    width: auto;
  }
  ::slotted(ul){
    list-style-type: none !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding: 15px;
    text-align: left;
    width: max-content;
  }
  slot[name=app-menu-items]::slotted(ul) {
    display: flex;
    height: 25px;
    justify-content: space-between;
    padding: 0 0 0 30px;
  }
  #avatar {
    margin-top: -30px;
    text-transform: uppercase;
  }
  #menu-icon {
    height: 40px;
    width: 40px;
    margin: auto;
    cursor: pointer;
    vertical-align: middle;
  }
  .hidden {
    display: none;
  }
  .display {
    display: block;
  }
  .app-profile-menu-items a {
    text-decoration: none;
  }
  .app-profile-menu-items a:hover {
    text-decoration: underline;
  }
  .app-profile-menu-items ul {
    list-style-type: none !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding: 10px;
    text-align: left;
  }
  .app-profile-menu-items li {
    padding: 10px;
  }
</style>
  <div id="nav-profile-settings" class="nav-profile-settings" title="User Account">
    <div id="app-profile-menu">
      <button>
        <div id="menu-icon">
          <svg viewBox="0 0 140 140">
            <g>
              <circle r="50%" cx="50%" cy="50%" fill="white" />
              <div id="avatar">
                <slot name="avatar" class="text-uppercase"></slot>
              </div> 
            </g>
          </svg>
        </div>
      </button>
      <slot id="app-profile-menu-items" name="app-profile-menu-items" class="app-profile-menu-items hidden" ></slot>
    </div>
  </div>
  `;
    const show = (menuItems: Element) => {
      if (!menuItems.classList.contains('hidden')) {
        menuItems.classList.replace('display', 'hidden')
      } else {
        menuItems.classList.replace('hidden', 'display')
      }
    }

    // click events
    this.shadow.querySelector('#app-profile-menu').addEventListener('click', () => {
      show(this.shadow.querySelector('#app-profile-menu-items'));
    })

    // User avatar - hidden if user is not signed in
    // If avatar is not provided, display the first letter of user name
    const avatar = this.shadow.querySelector('slot[name="avatar"]');
    avatar.addEventListener('slotchange', () => {
      this.shadow.querySelector<HTMLElement>('#app-profile-menu').style.display = 'flex'
    });
  }
}

window.customElements.define('prism-user-menu', PrismUserMenu);

