import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FiltersContainerComponent } from './filters-container.component';
import { ConfirmationModalModule } from '../../confirmation-modal/confirmation-modal.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@NgModule({
  declarations: [FiltersContainerComponent],
  imports: [
    ConfirmationModalModule,
    CommonModule,
    TooltipModule,
    CollapseModule,
  ],
  exports: [FiltersContainerComponent],
})
export class FiltersContainerModule {
}
