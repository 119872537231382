import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterObject, FilterOption, OptionsFilterType } from '../../../data/core/filters/filters';
import { Helpers } from '../../../utils/helpers';

@Component({
  selector: 'prism-options-filter',
  template: `
    <div class="options-filter" *ngIf="options">
      <div>
        <label class="m-1" *ngIf="title">{{ title }}</label>
        <ng-select
          [placeholder]="placeholder"
          (change)="updateFilter($event)"
          (clear)="clear.emit()"
          [clearable]="clearable"
          [multiple]="multiple"
          [ngModel]="filter.value">
          <ng-option *ngFor="let option of options; trackBy:identify"
                     [tooltip]="option.name"
                     [value]="option.value ?? option.code">
            <div title="{{option.name}}">{{option.name}}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
  `,
})
export class OptionsFilterComponent {
  public filter: FilterObject<OptionsFilterType> = {
    filter: '',
    value: null,
  };

  /**
   * The options that will be listed for the filter
   */
  private _options: Array<FilterOption>;

  /**
   * Defines multiply or single option select
   */
  private _multiple = false;

  /**
   * Event emitter for when a filter is updated.
   */
  @Output()
  public update = new EventEmitter<FilterObject<OptionsFilterType>>();

  /**
   * Event emitter for when the input is cleared.
   */
  @Output()
  public clear = new EventEmitter();

  /**
   * Determines if option is clearable
   */
  @Input()
  public clearable = true;

  /**
   * The title in display for this filter
   */
  @Input()
  public title: string;

  /**
   * Placeholder for select.
   */
  @Input()
  public placeholder: string = 'Select an option';

  /**
   * @see _options
   */
  public get options(): Array<FilterOption> {
    return this._options;
  }

  /**
   * Setter that parses options data to array if it is a stringified data.
   * @param value
   */
  @Input()
  public set options(value: Array<FilterOption> | string) {
    this._options = Helpers.jsonParseString(value);
  }

  /**
   * @see _multiple
   */
  public get multiple(): boolean {
    return this._multiple;
  }

  /**
   * Setter that parses the multiple value to boolean if it is a string.
   * @param value
   */
  @Input()
  public set multiple(value: boolean | string) {
    this._multiple = Helpers.convertStringToBoolean(value);
  }

  /**
   * The selected values in the filter. Validates if already defined filter matches
   * the multiply settings
   */
  @Input()
  public set selected(selected: OptionsFilterType) {
    if (!Array.isArray(selected) && this._multiple && selected) {
      selected = [selected];
    }

    this.filter.value = selected;
  };

  /**
   * Preserves the value of the <ng-select> element when nested <ong-option> elements are
   * dynamically populated using NgForOf and the bound iterable is updated
   * @param _index
   * @param item
   */
  public identify = (_index: number, item: FilterOption) => item.name;

  /**
   * The key that will be sent to the API via params
   * @param type
   */
  @Input()
  public set type(type: string) {
    this.filter.filter = type;
  }

  /**
   * Update selected filter and emit changes
   * @param value
   */
  public updateFilter(value: OptionsFilterType) {
    this.update.emit({ ...this.filter, value: value });
  }
}
