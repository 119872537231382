import React, { useCallback } from 'react'
import { makeStyles, IconButton, Chip } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear';
import { IStatement } from '../../interfaces';
import { useCommonStyles } from '../shared/common.style';

export const useViewStatementsStyles = makeStyles((_) => ({
  container: {
    width: "100%"
  },
  deleteCell: {
    width: 'auto',
    position: "absolute",
    top: 0,
    right: 0,
    padding: 4,
    borderRadius: 6,
    backgroundColor: "#eee",
  },
  chip: {
    margin: 2
  },
  card: {
    marginBottom: 16,
    border: '1px solid #eee',
    backgroundColor: "#eeeeee66",
    borderRadius: 8,
    width: "fit-content",
    minWidth: "100%",
    position: "relative",
    padding: "1rem",
    paddingTop: "1.2rem"
  }
}));

type ViewStatementsProps = {
  statements: Array<IStatement>,
  onDeleteStatement?: (index: number) => void,
  shouldShowDeleteButton?: boolean,
  className?: any
}

export const ViewStatements: React.FC<ViewStatementsProps> = (props: ViewStatementsProps) => {

  const viewStatementsClasses = useViewStatementsStyles();
  const commonClasses = useCommonStyles();

  return (
    <div data-testid="view-statements" className={viewStatementsClasses.container}>
      {
        props.statements?.map((statement, index) => (
          <div className={viewStatementsClasses.card} key={statement?.resources?.reduce((a,b)=>a+b)}>
            <fieldset className={commonClasses.fieldset} style={{ marginBottom: 8 }}>
              <legend className={commonClasses.legend}>Resource Types</legend>
              {
                statement.resources.map(resource => (
                  <Chip key={resource} label={resource} className={viewStatementsClasses.chip} />
                ))
              }
            </fieldset>
            <fieldset className={commonClasses.fieldset}>
              <legend className={commonClasses.legend}>Actions</legend>
              {
                statement.actions.map(action => (
                  <Chip key={action} label={action} className={viewStatementsClasses.chip} />
                ))
              }
            </fieldset>
            {props.shouldShowDeleteButton
              && <IconButton onClick={() => props.onDeleteStatement(index)} className={viewStatementsClasses.deleteCell}><ClearIcon style={{ height: "16px" }} /></IconButton>}
          </div>
        ))
      }
    </div>
  )
}
