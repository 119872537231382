import { Utilities } from '@gvp-utilities/utils'

export enum ElementType {
  BREADCRUMB = 'breadcrumb',
  BUTTON = 'button',
  CHECKBOX = 'checkbox',
  MENU = 'menu',
  MENUITEM = 'menuitem',
  // More to be added
}

/**
 *  Centralize naming of element identifiers.
 *  The initial motivation is to add IDs as locators for automated testing.
 */
export class ElementIdentifier {
  /**
   * Use
   * @param elementName
   * @param elementType
   */
  static getElementId(elementName: string, elementType: ElementType): string {
    return elementType + '-' + Utilities.slugify(elementName)
  }
}
