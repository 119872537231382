import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorizontalMenuComponent } from './horizontal-menu.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [HorizontalMenuComponent],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    RouterModule.forRoot([]),
  ],
  exports: [HorizontalMenuComponent],
})
export class HorizontalMenuModule {
}
