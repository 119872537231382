export enum GroupsListFilters {
  Name = "Name",
  ServicePrefix = "Service Prefix",
  UserId = "User Id"
}

export enum GroupsListHeadings {
  Name = "Name",
  ServicePrefix = "Service Prefix",
  Admins = "Group Admins",
  Members = "Group Members",
}
