import { makeStyles } from "@material-ui/core";

export const usePredefinedDomainTextBoxStyles = makeStyles((theme) => ({
  textField: {
    margin: "0"
  },
  domainDiv: {
    width: "auto",
    padding: "0 1rem",
    backgroundColor: "#ddd",
    borderRadius: "4px",
    height: "2.5rem"
  }
}));
