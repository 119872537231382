import { useState } from 'react'
import { GridColDef } from '@material-ui/data-grid'
import { useSelector } from 'react-redux'
import { getGroupsAction, deleteGroupAction } from '../../redux/actions'
import { AdvancedList, DenseList, DialogBox, EntityListTitleBar, toTitleCase } from '../shared'
import { GroupsListFilters, GroupsListHeadings } from '../../enums'
import { Chip, Grid, IconButton, Tooltip, Button, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useAppDispatch } from '../../redux/store'


export const useStyles = makeStyles((_) => ({
  chip: {
    fontSize: "0.7rem",
  },
  btn: {
    backgroundColor: "#eee",
    borderRadius: "16px",
    color: "#3f51b5",
    fontSize: "0.5rem",
  }
}));

const getGroupsDataFromState = (state) => ({
  groups: state?.groups,
  user: state?.userPermissions,
  isLoading: state?.isLoading,
})

export const GroupsList: React.FC = (): JSX.Element => {
  const classes = useStyles();

  const dispatch = useAppDispatch()
  const { groups, user, isLoading } = useSelector(getGroupsDataFromState)
  const history = useHistory();

  const [showSeeAllDialog, setShowSeeAllDialog] = useState<any>();
  const [groupToBeDeleted, setGroupToBeDeleted] = useState(null)

  const onDeleteGroupCancel = () => setGroupToBeDeleted(null);

  const onDeleteGroupConfirm = () => {
    dispatch(deleteGroupAction(groupToBeDeleted.id))
    setGroupToBeDeleted(null)
  }

  const columns : GridColDef[] = [

    {
      field: 'name',
      headerName: GroupsListHeadings.Name,
      disableColumnMenu: true,
      flex: 0.8
    },
    {
      field: 'servicePrefix',
      headerName: GroupsListHeadings.ServicePrefix,
      disableColumnMenu: true,
      flex: 0.6
    },
    {
      field: 'admins',
      headerName: GroupsListHeadings.Admins,
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: ({ row }) => {
        return <Grid
          container
          wrap='nowrap'
          spacing={1}
        >
          <Grid item>
            {
              row?.admins?.length
                ?
                <Chip className={classes.chip} label={row?.admins?.[0]} />
                :
                <Chip className={classes.chip} label="No Admins" />
            }
          </Grid>
          {
            row?.admins?.length > 1 &&
            <Grid container item justifyContent='space-between'>
              <Grid><Chip label={`+${row?.admins?.length - 1}`} /></Grid>
              <Grid><Button className={classes.btn} onClick={(_event) => {
                //The object key is to determine whether the data is for "admins" or "members"
                setShowSeeAllDialog({
                  "admins": row?.admins
                })
              }}>See All</Button></Grid>
            </Grid>
          }
        </Grid>
      }
    },
    {
      field: 'members',
      headerName: GroupsListHeadings.Members,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return <Grid
          container
          wrap='nowrap'
          spacing={1}
        >
          <Grid item>
            {
              row?.members?.length
                ?
                <Chip className={classes.chip} label={row?.members?.[0]} />
                :
                <Chip className={classes.chip} label="No Members" />
            }
          </Grid>
          {
            row?.members?.length > 1 &&
            <Grid container item justifyContent='space-between'>
              <Grid><Chip className={classes.chip} label={`+${row?.members?.length - 1}`} /></Grid>
              <Grid><Button className={classes.btn} onClick={(_event) => {
                setShowSeeAllDialog({
                  "members": row?.members
                })
              }}>See All</Button></Grid>
            </Grid>
          }
        </Grid>
      }
    },
    {
      field: "id",
      headerName: " ",
      renderCell: ({ row }) => {

        const onDeleteClick = () => {
          setGroupToBeDeleted(row)
        };

        const onEditClick = () => {
          history.push(`/groups/${row?.id}`, {
            group: row
          })
        };

        return <Grid container direction="row" wrap="nowrap" justifyContent='flex-start'>
          {user?.can?.update('group', row?.serviceId, row?.id) && <Tooltip title="Edit Group"><IconButton onClick={() => onEditClick()}><EditIcon /></IconButton></Tooltip>}
          {user?.can?.delete('group', row?.serviceId, row?.id) && <Tooltip title="Delete Group"><IconButton onClick={() => onDeleteClick()}><DeleteIcon /></IconButton></Tooltip>}
        </Grid>
      },
      disableColumnMenu: true, width: 110, align: "right"
    }
  ]

  //SEE ALL DIALOG CLOSE
  const onSeeAllDialogClose: () => void = (): void => {
    setShowSeeAllDialog(null)
  }

  //GETTING ADMINS/MEMBERS LIST FROM OBJECT KEYS
  const getMembersAdminsData : () => Array<string> = () : Array<string> => showSeeAllDialog && (Object.values(showSeeAllDialog)[0] as Array<string>)
  return (
    <>
      <EntityListTitleBar
        title={"Groups"}
        createButtonParams={user?.can?.create('group') && { url: "/groups/create", entityName: "Group" }}
      />
      <AdvancedList
        columns={columns}
        getEntityAction={getGroupsAction}
        isLoading={isLoading}
        entity={groups}
        searchFilterOptions={Object.values(GroupsListFilters)}
      />
      {/* SEE ALL DIALOG */}
      <DialogBox open={!!showSeeAllDialog}
        handleClose={onSeeAllDialogClose}
        title={showSeeAllDialog && toTitleCase(Object.keys(showSeeAllDialog)[0])}
      >
        <DenseList listData={getMembersAdminsData()}/>
      </DialogBox>
      {/* GROUP DELETE DIALOG */}
      <DialogBox
        open={!!groupToBeDeleted}
        handleClose={onDeleteGroupCancel}
        handleConfirm={onDeleteGroupConfirm}
        title="Delete Group"
        description={`Are you sure to delete ${groupToBeDeleted?.name} group?`}
        showAsConfirmDialog
      />
    </>
  )
}
