import { Component, Input } from '@angular/core';
import { MenuItem } from './menu.interface';
import { Helpers } from '../../utils/helpers';
import { Router } from '@angular/router';
import { ElementIdentifier, ElementType } from '../../utils';

@Component({
  selector: 'prism-horizontal-menu',
  template: `
    <div class="prism-menu">
      <ul *ngIf="menuItems" class="menu-container nav navbar-nav top-nav d-md-down-none">
        <li *ngFor="let menuItem of menuItems"
            class="nav-item menu-item px-2"
            dropdown
            routerLinkActive="active">
          <a class="nav-name menu-name text-decoration-none"
             id="{{ menuIdentifier(menuItem.name) }}"
             routerLink="{{ menuItem.url }}"
             [queryParamsHandling]="menuItem.url === currentRoute ? 'preserve' : null">
           <span>
                {{ menuItem.name }}
            </span>
          </a>
          <button
            *ngIf="menuItem.children && menuItem.children.length > 0"
            class="btn text-white dropdown-toggle-split toggle-button pt-2"
            (click)="$event.stopPropagation()"
            dropdownToggle>
            <i class="fa fa-chevron-down toggle-icon"></i>
          </button>
          <div *ngIf="menuItem.children && menuItem.children.length > 0">
            <ul *dropdownMenu class="dropdown-menu">
              <li *ngFor="let menuChild of menuItem.children">
                <a class="dropdown-item" routerLink="/{{ menuChild.url }}">
                  {{ menuChild.name }}
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  `,
  styles: [`
      .menu-name {
          margin: 0;
          line-height: 52px;
          color: white;
      }

      .menu-container {
          flex-grow: 1;
          display: flex;
          min-height: 46px;
          align-self: flex-end;
          overflow: visible;
          background-color: transparent;
          position: relative;
          flex-direction: inherit;
      }

      .menu-item {
          position: relative;
      }

      .menu-item:hover {
          background-color: #3E72D6;
      }

      .menu-item.active {
          color: white;
          border-bottom: 2px solid #fff;
      }

      .toggle-button {
          padding: 2px;
          box-shadow: none;
      }

      .toggle-icon {
          font-size: 12px;
          margin-bottom: 12px;
      }

      .dropdown-menu {
          position: absolute;
          color: #333333;
      }
  `],
})
export class HorizontalMenuComponent {

  /**
   * A collection of the menu items to display
   * @private
   */
  private _menuItems: MenuItem[];

  /**
   * The getter for menu items
   * @see {@link _menuItems}
   */
  get menuItems(): MenuItem[] {
    return this._menuItems;
  }

  /**
   * The getter for menu items
   * @param value
   */
  @Input()
  set menuItems(value: MenuItem[] | string) {
    this._menuItems = Helpers.jsonParseString(value);
  }

  constructor(private router: Router) {
  }

  /**
   * The current route as a string without leading slashes or query params.
   */
  get currentRoute(): string {
    const routeParts = this.router.url.split('?');
    return routeParts[0].replace(/^\/+/, '');
  }

  /**
   * Generates an id for a menu element
   * @param name - a menu item name
   */
  public menuIdentifier(name): string {
    return ElementIdentifier.getElementId(name, ElementType.MENU)
  }
}
