import React, { useEffect, useState } from 'react'
import {
    Grid, Select, IconButton, FormControl, InputLabel,
    Chip, Input, FormLabel, FormHelperText,
} from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import { useCreateStatementStyles } from "./create-statement.style";
import { IStatement } from '../../interfaces';
import { getError } from '../shared';
import { useCommonStyles } from '../shared/common.style';

type CreateStatementProps = {
    onCreateStatement: (selectedActions: Array<string>, selectedResources: Array<string>) => void,
    actionOptions: Array<string>,
    servicePrefix: string,
    statements: Array<IStatement>
}

export const CreateStatement: React.FC<CreateStatementProps> = (props: CreateStatementProps) => {

    const [selectedActions, setSelectedActions] = useState<string[]>([]);
    const [selectedResources, setSelectedResources] = useState<string[]>([]);
    const [error, setError] = useState<string>('');

    const resourceOptions = [...new Set(props.actionOptions?.map(action => action.split(":")[1]))].map(data => `drn:${props.servicePrefix}:` + '${tenantId}' + `:${data}`)

    const actionOptions = selectedResources.flatMap(selectedResource => props.actionOptions.filter(action => action.split(":")[1] === selectedResource.split(":")[3]))

    const onChangeAction = (event: React.ChangeEvent<{ value: unknown }>) => {
        setError("")
        setSelectedActions(event.target.value as string[]);
    };

    const onChangeResource = (event: React.ChangeEvent<{ value: unknown }>) => {
        setError("")
        setSelectedResources(event.target.value as string[]);
        setSelectedActions((event.target.value as string[])?.flatMap(resource => selectedActions.filter(action => resource.split(":")[3] === action.split(":")[1])))
    };

    const getStatementsError = () => {
        const newStatement = {
            actions: selectedActions,
            effect: "Allow",
            resources: selectedResources
        } as IStatement;

        return getError('statements', newStatement, props.statements, null)
    }

    const onCreateStatement = (event) => {
        event.preventDefault();
        const error = getStatementsError()

        if (error) {
            setError(error)
        }
        else {
            props.onCreateStatement(selectedActions, selectedResources)
            setSelectedActions([])
            setSelectedResources([])
        }
    }

    useEffect(() => {
        setSelectedActions([])
        setSelectedResources([])
    }, [props.servicePrefix])

    useEffect(() => {
        setError("")
    }, [props.statements?.length])

    const createStatementClasses = useCreateStatementStyles();
    const commonClasses = useCommonStyles();

    return (
        <FormControl variant="outlined" fullWidth className={createStatementClasses.statementsContainer} data-testid="create-statement" style={{ border: error && "1px solid red" }}>
            <Grid container justifyContent="space-between">
                <FormLabel component="label">Add Statement *</FormLabel>
                <IconButton
                    color="primary"
                    onClick={onCreateStatement}
                    disabled={!(selectedActions.length > 0 && selectedResources.length > 0)}
                    className={createStatementClasses.statementsAddButton}>
                    <AddIcon />
                </IconButton>
            </Grid>

            <Grid container spacing={2}>
                {/* RESOURCE TYPES */}
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="actions">Resource Types *</InputLabel>
                        <Select
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            name="resourceTypes"
                            error={!!error}
                            value={selectedResources}
                            onChange={onChangeResource}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                                <div className={commonClasses.chips}>
                                    {(selected as string[]).map((value) => (
                                        <Chip key={value} label={value} className={commonClasses.chip} />
                                    ))}
                                </div>
                            )}
                            data-testid="resource-types-select"
                        >
                            {resourceOptions.map((name) => (
                                <option data-testid='resource-types-options' key={name} value={name} className={commonClasses.option}>
                                    {name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/* ACTIONS */}
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="actions">Actions *</InputLabel>
                        <Select
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            name="actions"
                            error={!!error}
                            value={selectedActions}
                            onChange={onChangeAction}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                                <div className={commonClasses.chips}>
                                    {(selected as string[]).map((value) => (
                                        <Chip key={value} label={value} className={commonClasses.chip} />
                                    ))}
                                </div>
                            )}
                            data-testid="actions-select"
                        >
                            {(selectedResources.length > 0 ? actionOptions : props.actionOptions).map((name) => (
                                <option data-testid="actions-options" key={name} value={name} className={commonClasses.option}>
                                    {name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {
                error && <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>
            }
        </FormControl>
    )
}
