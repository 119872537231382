import { Grid, IconButton } from "@material-ui/core";
import React from "react";
import { configData } from "../../../config-data";
import { CheckCircleOutline } from "@material-ui/icons";
import { CreatableSelector, CreatableSelectorOptionType } from "../shared";

export const ServiceAddAction = (props) => {
  const initialAction = { baseResourceType: '', baseAction: '' };
  const [action, setAction] = React.useState(initialAction);
  const [isValid, setIsValid] = React.useState(false);

  const validate = (actionData) => {
    setIsValid(actionData.baseAction.length > 0 && actionData.baseResourceType.length > 0)
  }

  const handleChange = (field, newValue) => {
    const actionData = {
      ...action,
      [field]: newValue
    }
    setAction(actionData)
    validate(actionData)
  }

  const addAction = () => {
    const newAction = `${props.prefix}:${action.baseResourceType}:${action.baseAction}`
    if (props.actions.includes(newAction))
      props.setError("Duplicate Action.")
    else {
      props.setError("");
      setAction(initialAction);
      props.onActionAdded(newAction);
      validate(initialAction);
    }
  }

  const baseResourceOptions: CreatableSelectorOptionType[] = configData.baseResourceTypes.map(r => ({ value: r }))
  const baseActionOptions: CreatableSelectorOptionType[] = configData.baseActions.map(a => ({ value: a }))

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5}>
          <CreatableSelector
            name="baseResourceType"
            label="Base Resource *"
            selectorOptions={baseResourceOptions}
            valueChanged={handleChange}
            value={action.baseResourceType}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <CreatableSelector
            name="baseAction"
            label="Base Action *"
            selectorOptions={baseActionOptions}
            valueChanged={handleChange}
            value={action.baseAction}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Grid container justifyContent="flex-end">
            <IconButton
              color="primary"
              onClick={addAction}
              disabled={!isValid}
            >
              <CheckCircleOutline />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

