import { FormControl, FormHelperText, Grid, TextField } from '@material-ui/core'
import React from 'react'
import { useCommonStyles } from './common.style'
import { usePredefinedDomainTextBoxStyles } from './predefined-domain-textbox.style'

type PredefinedDomainTextBoxPropTypes = {
  onChange: (event) => void,
  textValue: string,
  domain: string,
  error?: string,
  onBlur?: () => void,
  placeholder?: string,
  name?: string,
  label?: string,
  onKeyDown?: (event) => void,
  startAdornment?: any,
  entity?: string
}

export const PredefinedDomainTextBox: React.FC<PredefinedDomainTextBoxPropTypes> = ({ onChange,
  textValue,
  domain,
  error,
  onBlur,
  placeholder,
  name,
  label,
  startAdornment,
  entity,
  onKeyDown }: PredefinedDomainTextBoxPropTypes): JSX.Element => {

  const classes = usePredefinedDomainTextBoxStyles()
  const commonClasses = useCommonStyles();

  const domainTextbox = <Grid container wrap="nowrap" direction="row" alignItems='center' style={{ marginTop: "0.5rem" }}>
    <TextField
      autoFocus
      margin="dense"
      id="userId"
      label={label || "Enter Email IDs"}
      type="email"
      name={name}
      variant="outlined"
      InputProps={startAdornment}
      error={!!error}
      onBlur={onBlur}
      fullWidth
      value={textValue}
      placeholder={placeholder || "Enter email IDs separated by commas"}
      onChange={onChange}
      className={classes.textField}
      onKeyDown={onKeyDown}
    />
    <Grid container alignItems='center' className={classes.domainDiv}>
      {domain}
    </Grid>
  </Grid>

  return (
    <FormControl fullWidth>
      {startAdornment
        ?
        <fieldset className={commonClasses.fieldset}>
          <legend className={commonClasses.legend}>{entity}</legend>
          {startAdornment}
          {domainTextbox}
        </fieldset>
        :
        <>
          {domainTextbox}
        </>

      }
      {error && <FormHelperText style={{ color: "#f00" }}>{error}</FormHelperText>}
      <FormHelperText>To add a custom domain, add '@' followed by the custom domain</FormHelperText>
    </FormControl>
  )
}
