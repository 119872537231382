import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DISPLAY_LIST_DATES, DISPLAY_LIST_IGNORE, Filter, FilterListObject } from '../../../data/core/filters/filters';
import { DateHelpers, Helpers } from '../../../utils/helpers';

type Theme = 'primary' | 'info' | 'success';

@Component({
  selector: 'prism-filters-list',
  template: `
    <div class="row">
      <div class="col-12">
        <div class="m-2" *ngIf="showNoFiltersMessage">
          No filters applied
        </div>
        <div class="badge badge-pill badge-{{theme}} filter-list-item mx-1 my-2" *ngFor="let filter of filterList">
          <span>{{ formatLabel(filter.key) }}: <strong>{{ filter.value }}</strong></span>
          <i class="fa fa-times-circle ml-1 filter-list-icon pointer"
             (click)="remove(filter)"></i>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .filter-list-item {
      font-size: 0.875rem;;
      font-weight: 400;
      display: inline-flex;
    }
  `],
})
export class FiltersListComponent {

  /**
   * The current filter payload.
   */
  private _filters: Filter;

  /**
   * Formatted filter list
   */
  public filterList: Array<FilterListObject>

  /**
   * Flag to show or hide `No filters applied` message when not filters provided
   * @private
   */
  private _showNoFiltersMessage = true;

  /**
   * Filter item color theme. Changes badge color
   */
  @Input()
  public theme: Theme = 'primary';

  /**
   * Format filters list.
   * Excludes pagination payload.
   * Formats dates filter option
   *
   * @see _filters
   * @see filterList
   * @param filters
   */
  @Input()
  set filters(filters: Filter | string) {
    this._filters = Helpers.jsonParseString(filters);

    if (!this._filters) {
      return;
    }

    const rawFilterList = Object.keys(this._filters)
      .filter(key => !DISPLAY_LIST_IGNORE.includes(key))
      .map((key) => {
        const value = this._filters[key];
        if (!this._filters[key]) {
          return {};
        }
        if (key === DISPLAY_LIST_DATES) {
          return {
            key: key,
            value: DateHelpers.formatDateFilterObject(value),
          }
        }
        if (Array.isArray(value)) {
          return value.map((singleValue) => ({
            key: key,
            value: singleValue,
          }))
        }
        return {
          key: key,
          value: value,
        }
      });

    this.filterList = [].concat(...rawFilterList).filter(item => !!item.value);
  }

  /**
   * @see _showNoFiltersMessage
   * @param value
   */
  @Input()
  public set showNoFiltersMessage(value: boolean) {
    this._showNoFiltersMessage = value;
  }

  /**
   * Flag to show or hide `No filters applied` message if filters list is empty
   */
  public get showNoFiltersMessage(): boolean {
    return this.filterList.length === 0 && this._showNoFiltersMessage;
  }

  /**
   * Event that is emitted when stored filters applied
   */
  @Output()
  public apply = new EventEmitter();

  /**
   * Remove filter item from the filters payload.
   *
   * @param filter
   */
  public remove(filter: FilterListObject) {
    if (Array.isArray(this._filters[filter.key]) && this._filters[filter.key].length > 1) {
      this._filters[filter.key] = this._filters[filter.key].filter(el => el !== filter.value)
    } else {
      this._filters[filter.key] = null;
    }

    this._filters.page = 1;
    this.apply.emit(this._filters);
  }

  /**
   * Format a camel-case filter option name to a lower-case string
   * @param label
   */
  public formatLabel(label: string) {
    return label.replace(/([A-Z])/g, ' $1').toLowerCase()
  }
}
