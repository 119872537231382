import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Link, Tooltip } from '@material-ui/core';
import { GridColDef } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  DialogBox,
  EntityListTitleBar,
  AdvancedList,
  areUniqueUserIds,
  DenseList,
  emailValidationRegex,
} from '../shared';
import { IRoleAssignmentErrors, IRoleAssignmentLocal } from '../../interfaces';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CreateRoleAssignment } from './create-role-assignment';
import { RolesListHeadings, RolesListFilters } from '../../enums';
import { StatementsDialog } from './statements-dialog';
import {
  getRolesAction,
  deleteRoleAction,
  createRoleAssignmentAction,
} from '../../redux/actions';
import { environment } from '../../../environments/environment';
import { useAppDispatch } from '../../redux/store';
import { getAsync } from '../../redux/sagas/commonAsync';

const getDataFromState = (state) => ({
  user: state.userPermissions,
  roles: state.roles,
  isLoading: state.isLoading,
});

export const RoleList: React.FC = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { user, roles, isLoading } = useSelector(getDataFromState);

  // USERS ASSIGNED TO A ROLE STATE
  const [showUsersDialog, setShowUsersDialog] = useState<string>(null);
  const [usersAssigned, setUsersAssigned] = useState<string[]>([]);
  const [assignedUserCount, setAssignedUserCount] = useState<number>(0);
  const [isUsersLoading, setIsUsersLoading] = useState<boolean>(false);
  const [selectedRoleName, setSelectedRoleName] = useState<string>('');

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: RolesListHeadings.Name,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'servicePrefix',
      headerName: RolesListHeadings.ServicePrefix,
      disableColumnMenu: true,
      flex: 1,
      valueGetter: (params) => params.row?.service?.prefix,
    },
    {
      field: 'description',
      headerName: RolesListHeadings.Description,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'roleAssignmentsCount',
      headerName: RolesListHeadings.RoleAssignmentsCount,
      disableColumnMenu: true,
      flex: 1,
      hide: true,
    },
    {
      field: 'id',
      headerName: ' ',
      renderCell: (params) => {
        const onDeleteClick = () => {
          setRoleToBeDeleted(params.row);
        };
        const onEditClick = () => {
          history.push(`/roles/${params?.row?.id}`, {
            id: user?.can?.update('role'),
            role: params?.row,
          });
        };
        const onCopyClick = () => {
          history.push('/roles/create', {
            role: params?.row,
          });
        };
        const onAssignmentClick = () => {
          setRoleAssignment({
            ...roleAssignment,
            roleId: params.row?.id,
            serviceId: params.row.service?.id,
            tenantIds: ['*'],
          });
          setShowAssignmentDialog(true);
          setSelectedRoleName(params.row?.name);
        };
        const onAssignedUsersClick = () => {
          setShowUsersDialog(params.row?.name);
          setSelectedRoleName(params.row?.name);
        }
        const canCreateRoleAssignment = (ownerServiceId) => {
          const partialAuthRawData = user?.can?.create(
            'roleAssignment',
            null,
            null,
            true,
          );
          let permission = false;
          if (user?.isSuperAdmin())
            return true;
          if (params.row.name?.includes("Owner"))
            return false;
          if (params.row.name?.includes("Admin")) {
            //if its an admin role but user is the service owner
            if (user?.isServiceOwner(params.row.ownerServiceId))
              return true;
            //if its an admin role but user is the service admin
            else if (user?.isServiceAdmin(params.row.ownerServiceId))
              return false;
          }
          partialAuthRawData &&
            Object.entries(partialAuthRawData).forEach(
              ([key, value]: [string, Array<string>]) => {
                permission =
                  permission ||
                  key === '*' ||
                  key === ownerServiceId ||
                  value?.includes(ownerServiceId);
              },
            );
          return permission;
        };
        return (
          <Grid container direction="row" wrap="nowrap" data-testid="role-list">
            <StatementsDialog statements={params.row.statements} />
            {
              <Tooltip title="Assigned users">
                <IconButton onClick={onAssignedUsersClick}>
                  <PeopleAltIcon />
                </IconButton>
              </Tooltip>
            }
            {user?.can?.create(
              'role',
              params?.row?.service?.id,
              params?.row?.id
            ) && (
              <Tooltip title="Copy Role">
                <IconButton onClick={onCopyClick}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            )}
            {canCreateRoleAssignment(params?.row?.ownerServiceId) && (
              <Tooltip title="Assignment">
                <IconButton onClick={onAssignmentClick}>
                  <AssignmentIndIcon />
                </IconButton>
              </Tooltip>
            )}
            {user?.can?.update(
              'role',
              params?.row?.service?.id,
              params?.row?.id
            ) && (
              <Tooltip title="Edit">
                <IconButton onClick={onEditClick}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {user?.can?.delete(
              'role',
              params?.row?.service?.id,
              params?.row?.id
            ) && (
              <Tooltip title="Delete">
                <IconButton onClick={onDeleteClick}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        );
      },
      disableColumnMenu: true,
      width: 300,
      align: 'right',
    },
  ];

  useEffect(() => {
    if (!!showUsersDialog) {
      setIsUsersLoading(true);
      getAsync(`role-assignments?&role-name=${selectedRoleName}&withTotalRecords=true`)
        .then((result) => result.json())
        .then((roleAssignments) => {
          setUsersAssigned(
            roleAssignments?.data?.map(
              (roleAssignment) => roleAssignment?.userId
            ) || []
          );
          setIsUsersLoading(false);
          setAssignedUserCount(roleAssignments?.totalRecords || 0);
        });
    }
  }, [showUsersDialog]);

  // DELETE ROLE
  const [roleToBeDeleted, setRoleToBeDeleted] = useState(null);
  const handleDeleteDialogClose = () => setRoleToBeDeleted(null);
  const handleDeleteConfirm = () => {
    setRoleToBeDeleted(null);
    dispatch(deleteRoleAction(roleToBeDeleted.id));
  };

  // ROLE ASSIGNMENT
  const initialRoleAssignment: IRoleAssignmentLocal = {
    roleId: '',
    status: 'active',
    tenantIds: ['*'],
    userIds: '',
    groupNames: [],
    serviceId: '',
  };

  const initialRoleAssignmentErrors: IRoleAssignmentErrors = {
    userIds: '',
    tenantIds: '',
  };
  const [showAssignmentDialog, setShowAssignmentDialog] =
    useState<boolean>(false);
  const [roleAssignmentErrors, setRoleAssignmentErrors] =
    useState<IRoleAssignmentErrors>(initialRoleAssignmentErrors);
  const [roleAssignment, setRoleAssignment] = useState<IRoleAssignmentLocal>(
    initialRoleAssignment
  );

  const handleAssignmentDialogClose = () => {
    setShowAssignmentDialog(false);
    setRoleAssignmentErrors(initialRoleAssignmentErrors);
    setRoleAssignment(initialRoleAssignment);
  };

  const handleAssignmentConfirm = () => {
    const splitUserIds =
      !!roleAssignment?.userIds &&
      roleAssignment?.userIds.split(',')?.map((id) => id.trim());
    const customDomainIds =
      !!splitUserIds && splitUserIds?.filter((id) => id.includes('@'));
    if (!roleAssignment.userIds && !roleAssignment.groupNames)
      setRoleAssignmentErrors({
        ...roleAssignmentErrors,
        userIds: 'Please enter at least an Email ID or group name',
      });
    else if (
      !!customDomainIds &&
      !!customDomainIds
        ?.map((id) => (emailValidationRegex.test(id as string) ? '' : 'Error'))
        .join('')
    ) {
      setRoleAssignmentErrors({
        ...roleAssignmentErrors,
        userIds: 'Please enter valid email IDs',
      });
    } else if (
      !!roleAssignment?.userIds &&
      !areUniqueUserIds(roleAssignment?.userIds)
    ) {
      setRoleAssignmentErrors({
        ...roleAssignmentErrors,
        userIds: 'Please enter unique email IDs',
      });
    } else {
      setShowAssignmentDialog(false);
      setRoleAssignment({
        ...roleAssignment,
        userIds: '',
        groupNames: [],
      });
      setRoleAssignmentErrors({
        ...roleAssignmentErrors,
        userIds: '',
      });
      const domain = environment.production ? '@discovery.com' : '@qadci.com';
      const formattedUserIds = !!splitUserIds
        ? splitUserIds?.map((id) => (id.includes('@') ? id : `${id}${domain}`))
        : [];
      const formattedGroupNames = roleAssignment?.groupNames || [];
      let updatedRoleAssignment = {
        ...roleAssignment,
        userIds: [...formattedUserIds, ...formattedGroupNames],
      };
      delete updatedRoleAssignment.groupNames;
      dispatch(createRoleAssignmentAction(updatedRoleAssignment));
    }
  };

  const resetUsersDialog: () => void = (): void => {
    setShowUsersDialog(null);
    setUsersAssigned([]);
  };

  return (
    <>
      <EntityListTitleBar
        title={'Roles'}
        createButtonParams={
          user?.can?.create('role') && {
            url: '/roles/create',
            entityName: 'Role',
          }
        }
      />
      <AdvancedList
        columns={columns}
        getEntityAction={getRolesAction}
        isLoading={isLoading}
        entity={roles}
        searchFilterOptions={Object.values(RolesListFilters)}
      />
      {/* SEE ALL USERS ASSIGNED TO A ROLE */}
      <DialogBox
        open={!!showUsersDialog}
        handleClose={resetUsersDialog}
        title={`Users assigned to role "${selectedRoleName}"`}
      >
        <div>
            {assignedUserCount > 25 ? (
              <div className="m-2">
                <div className="center">
                  <span className="bold">Most Recently Assigned </span>
                  (<Link href={`/users?roleName=${selectedRoleName}`} target="_blank" underline="always">
                    All
                  </Link>)
                </div>
              </div>
            ) : null}
          <DenseList listData={usersAssigned} loading={isUsersLoading} />
        </div>
      </DialogBox>
      {/* ROLE ASSIGNMENT DIALOG */}
      <DialogBox
        open={showAssignmentDialog}
        handleClose={handleAssignmentDialogClose}
        description={`Assign role "${selectedRoleName}" to users or groups`}
        handleConfirm={handleAssignmentConfirm}
        showAsConfirmDialog
      >
        <CreateRoleAssignment
          areUniqueUserIds={areUniqueUserIds}
          setRoleAssignment={setRoleAssignment}
          setRoleAssignmentErrors={setRoleAssignmentErrors}
          roleAssignment={roleAssignment}
          roleAssignmentErrors={roleAssignmentErrors}
        />
      </DialogBox>
      {/* ROLE DELETE DIALOG */}
      <DialogBox
        open={!!roleToBeDeleted}
        handleClose={handleDeleteDialogClose}
        handleConfirm={handleDeleteConfirm}
        title="Delete Role"
        description={
          roleToBeDeleted?.roleAssignmentsCount
            ? `This role contains ${roleToBeDeleted.roleAssignmentsCount} assignments. Are you sure to delete this role?`
            : 'Are you sure to delete this role?'
        }
        showAsConfirmDialog
        critical
      />
    </>
  );
};
