import { Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CollectorOptions } from './collector-options.type';
import { Helpers } from '../../utils/helpers';

declare const window: any;

@Component({
  selector: 'prism-jira-issue-collector',
  template: '',
})
export class JiraIssueCollectorComponent implements OnInit {

  /**
   * Jira issue collector configuration.
   */
  private _configuration: CollectorOptions;

  /**
   * Setter for collector configuration options
   * @see _configuration
   * @param value
   */
  @Input()
  public set configuration(value: CollectorOptions | string) {
    this._configuration = Helpers.jsonParseString(value);
  }

  constructor(private _renderer2: Renderer2,
              @Inject(DOCUMENT) private _document: Document,
  ) {
  }

  public ngOnInit(): void {
    if (!this._configuration?.scriptSrc) {
      console.log('Missing issue collector script url.');
      return;
    }
    this._addJiraIssueCollector()
    this._extendJiraIssueCollector()
  }

  /**
   * Adds a Jira issue collector widget to UI.
   * It is a temporary solution until a new sharable component will be added
   * @private
   */
  private _addJiraIssueCollector(): void {
    const script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src = this._configuration.scriptSrc;

    this._renderer2.appendChild(this._document.body, script);
  }

  /**
   * Add jira issue collector function and field values.
   */
  private _extendJiraIssueCollector(): void {

    if (!this._configuration.customFields) {
      return;
    }

    window.ATL_JQ_PAGE_PROPS = {
      'triggerFunction': showCollectorDialog => {
        document.getElementById('feedback-button')
          .addEventListener('click', e => {
            e.preventDefault();
            showCollectorDialog();
          });
      },
      fieldValues: Object.keys(this._configuration.customFields)
        .reduce((acc, key) => {
          acc[`customfield_${key}`] = this._configuration.customFields[key];
          return acc;
        }, {}),
    }
  }
}
