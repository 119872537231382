import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Breadcrumb } from '../types/breadcrumb';

export class BreadcrumbService {

  /**
   * The breadcrumb data that is updated upon refresh.
   * @private
   */
  private breadcrumbData: BehaviorSubject<Array<Breadcrumb>>;

  /**
   * The breadcrumb data stream.
   */
  public breadcrumbs: Observable<Array<Breadcrumb>>;

  constructor() {
    this.breadcrumbData = new BehaviorSubject<Array<Breadcrumb>>([]);
    this.breadcrumbs = this.breadcrumbData.asObservable();
  }

  /**
   * Builds the breadcrumb array from the given activated route data.
   * @param route
   * @param labels (optional) If set, replaces the labels of the last breadcrumb in the list.
   */
  refresh(route: ActivatedRoute, labels?: string | Array<string>): void {
    const newBreadcrumbs: Array<Breadcrumb> = this.buildBreadcrumb(route.root);

    if (labels) {
      newBreadcrumbs.pop();
      labels = Array.isArray(labels) ? labels : [labels];

      for (const label of labels) {
        newBreadcrumbs.push({
          label: label,
          url: null,
        });
      }
    }

    this.breadcrumbData.next(newBreadcrumbs);
  }

  /**
   * Builds the breadcrumb array from the given ActivatedRoute data.
   *
   * This method is called recursively to find all nested values from the current child.
   * @param route
   * @param url
   * @param breadcrumbs
   * @private
   */
  private buildBreadcrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<Breadcrumb> = []): Array<Breadcrumb> {
    const enabled = route.routeConfig?.data?.breadcrumb !== false;
    let label: string = route.routeConfig?.data?.title || '';
    let path = route.routeConfig?.path || '';

    // If the route is dynamic, set the label and path to use the ID
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if (enabled && isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = route.snapshot.params[paramName];
    }

    // Rebuild the complete path
    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: Breadcrumb = {
      label: label,
      url: nextUrl,
    };

    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];

    if (route.firstChild) {
      // If we are not on our current path yet, recursively call this function
      return this.buildBreadcrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }
}
