import { IRole } from "../../../interfaces";

export const CREATE_ROLE = 'CREATE_ROLE'

type CreateRoleActionType = {
    type: string,
    payload: {
        role: IRole,
        push: any
    }
}

export const createRoleAction: (role: IRole, push: any) => CreateRoleActionType = (role, push): CreateRoleActionType => ({
    type: CREATE_ROLE,
    payload: {
        role,
        push
    }
})
