import { AccessToken, TokenManager } from '@okta/okta-auth-js';
import { environment } from '../../../../environments/environment';
import { oktaAuth as authClient } from '../../../okta-auth';

export const RequestHeaders = {
  get: async () => {
    if (await authClient.isAuthenticated()) {
      const tokenManager: TokenManager = authClient.tokenManager;
      const accessToken: AccessToken = await tokenManager.get('accessToken') as AccessToken;
      return { Authorization: 'Bearer ' + accessToken?.accessToken }
    } else if (environment.env === 'local') {
      return { Authorization: 'Bearer ' + environment.token }
    }
    return {}
  },
};
