import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SavedFiltersComponent } from './saved-filters.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [SavedFiltersComponent],
  imports: [
    CommonModule,
    SweetAlert2Module.forRoot(),
    TooltipModule.forRoot(),
  ],
  exports: [SavedFiltersComponent],
})
export class SavedFiltersModule {
}
