import { PageTitle, toTitleCase } from '../shared';
import { environment } from '../../../environments/environment';
import { useEffect, useState } from 'react';
import { RequestHeaders } from '../shared/request/request-headers';
import { AuthzConfigurationsCard } from './authz-configuration-card';
import { DashboardContainer } from './dashboard-container';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((_) => ({
  resourcesContainer: {
    marginLeft: '1rem',
  },
}));

export const Dashboard = () => {
  const [apiCounts, setApiCounts] = useState({
    services: 0,
    roles: 0,
    roleAssignments: 0,
    groups: 0,
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const checkApis = async () => {
      setIsLoading(true);

      const headers = await RequestHeaders.get();
      const counts = {
        services: 0,
        roles: 0,
        roleAssignments: 0,
        groups: 0,
      }
      counts.services = await fetch('/authz/v1/services?withTotalRecords=true', { headers: headers })
        .then(res => res.json())
        .then(res => res.totalRecords)

      counts.roles = await fetch('/authz/v1/roles?withTotalRecords=true', { headers: headers })
        .then(res => res.json())
        .then(res => res.totalRecords);

      counts.roleAssignments = await fetch('/authz/v1/role-assignments?withTotalRecords=true', { headers: headers })
        .then(res => res.json())
        .then(res => res.totalRecords);

      counts.groups = await fetch('/authz/v1/groups?withTotalRecords=true', { headers: headers })
        .then(res => res.json())
        .then(res => res.totalRecords);

      setApiCounts(counts);
      setIsLoading(false);
    }

    checkApis();
  }, [])

  const classes = useStyles();

  return <>
    {
      environment.env !== 'prod' ? <div><i>(Dashboard is publicly accessible only in dev/stage environment)</i></div> : ''
    }

    <PageTitle title={'AuthZ Data Manager Dashboard'} />
    <DashboardContainer heading="Authz Configurations">
      {
        Object.entries(apiCounts).map(([key, value]) => (
          <AuthzConfigurationsCard key={key} isLoading={isLoading} count={value} label={toTitleCase(key)} />
        ))
      }
    </DashboardContainer>
    <DashboardContainer heading="Resources">
      <Grid container direction="column" className={classes.resourcesContainer}>
        <span>
          <a target="_blank" href="https://wbdstreaming.slack.com/archives/C04AY7P7AQM">
            #etnt-authz-integration Slack Channel
          </a> - Ask for help, make suggestions
        </span>
        <span>
          <a target="_blank" href="https://discoveryinc.atlassian.net/jira/software/c/projects/AUTHZ/boards/1950/roadmap">
            AuthZ JIRA Roadmap
          </a> - View upcoming features
        </span>
        <span>
          <a target="_blank" href="https://docs.google.com/document/d/1ZQNeNIuSne8e7XTNofiZPzli82uW2-nP6y-crY27jqk">
            AuthZ User Guide
          </a> - Google doc for implementation and how to use Authz
        </span>
      </Grid>
    </DashboardContainer>
  </>
}
