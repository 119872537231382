export const GET_ROLE_ASSIGNMENTS = 'GET_ROLE_ASSIGNMENTS';

type GetRoleAssignmentsActionType = {
  type: string,
  payload: {
    withTotalRecords: boolean, pageSize: number, currentPage: number, searchText: string, selectedSearchCriteria: string
  }
}

export const getRoleAssignmentsAction: (pageSize: number, currentPage: number, withTotalRecords: boolean, searchText?: string, selectedSearchCriteria?: string) => GetRoleAssignmentsActionType = (pageSize: number, currentPage: number, withTotalRecords: boolean, searchText: string = "", selectedSearchCriteria: string = ""): GetRoleAssignmentsActionType => ({
  type: GET_ROLE_ASSIGNMENTS,
  payload: {
    withTotalRecords, pageSize, currentPage, searchText, selectedSearchCriteria
  }
})
