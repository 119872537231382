export const DELETE_ROLE = 'DELETE_ROLE';

type DeleteRoleActionType = {
    type: string
    payload: {
        roleId: string
    }
}

export const deleteRoleAction: (roleId: string) => DeleteRoleActionType = (roleId): DeleteRoleActionType => ({
    type: DELETE_ROLE,
    payload: {
        roleId
    }
})
