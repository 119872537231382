import { call, put } from "redux-saga/effects";
import { getRolesAction } from "../../actions";
import { deleteAsync } from "../commonAsync";
import {toggleAlert, toggleAlertActionCreator, toggleLoading} from '../../slices'

export function* deleteRoleSaga(action) {
  try {
    yield put(toggleLoading(true))
    const response = yield call(deleteAsync, `roles/${action.payload.roleId}`)
    if (yield response.ok) {
      yield put(toggleAlert(toggleAlertActionCreator('success', 'Role deleted successfully')))
      yield put(getRolesAction(25, 1, true))
    }
    else {
      const errorCode = yield response.status;
      const { Error } = yield response.json();
      const errorMessages = yield Error?.map(error => error?.message).join(",")
      yield put(toggleAlert(toggleAlertActionCreator('error', `${errorCode}: ${errorMessages}`)))
    }
    yield put(toggleLoading(false))
  }
  catch (error) {
    yield put(toggleAlert(toggleAlertActionCreator('error', error?.toString())))
  }
}
