import { makeStyles } from "@material-ui/core";

export const useCommonStyles = makeStyles((_) => ({
  fieldset: {
    borderRadius: 4,
    border: '1px solid rgba(0, 0, 0, 0.2)'
  },
  legend: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.6)',
    width: 'auto',
    marginLeft: "0.5rem",
    padding: "0 0.5rem"
  },
  roundContainer: {
    border: "1px solid #00000033",
    borderRadius: "4px"
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
},
  chip: {
    margin: 2,
  },
  option: {
    paddingLeft: 16,
    paddingTop: 8,
    paddingBottom: 8,
    '&:hover': {
        backgroundColor: '#00000022',
        cursor: 'pointer'
    },
    '&[aria-selected=true]': {
        backgroundColor: '#00000044',
        cursor: 'pointer'
    }
  },
  strikeThroughChip: {
    '& > span': {
      textDecoration: 'line-through'
    }
  }
}))
