import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { bootstrapComponents, coreModules } from './component-list';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ...coreModules,
  ],
})

export class GvpCoreComponentsModule implements DoBootstrap {
  private config = { injector: this.injector };

  constructor(private injector: Injector) {
  }

  ngDoBootstrap(): void {
    bootstrapComponents.forEach(el => {
      if (!customElements.get(el.selector)) {
        customElements.define(el.selector, createCustomElement(el.component, this.config));
      }
    })
  }
}

platformBrowserDynamic()
.bootstrapModule(GvpCoreComponentsModule)
.catch(err => console.error(err));
