import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/root-saga";
import rolesReducer from "./slices/rolesSlice";
import servicesReducer from "./slices/servicesSlice";
import roleAssignmentsReducer from "./slices/roleAssignmentsSlice";
import groupsReducer from "./slices/groupsSlice";
import { useDispatch } from 'react-redux';
import alertReducer from './slices/root-slices/alertSlice';
import currentUserReducer from './slices/root-slices/currentUserSlice';
import userPermissionsReducer from './slices/root-slices/userPermissionsSlice';
import isLoadingReducer from './slices/root-slices/loaderSlice';

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch

let sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware]

export const store = configureStore({
  reducer: {
    roles: rolesReducer,
    services: servicesReducer,
    roleAssignments: roleAssignmentsReducer,
    groups: groupsReducer,
    alert: alertReducer,
    currentUser: currentUserReducer,
    userPermissions: userPermissionsReducer,
    isLoading: isLoadingReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middleware),
})

sagaMiddleware.run(rootSaga)
