import { Component, OnInit } from '@angular/core';

interface SubDomain {
  name: string,
  url: string,
}

const SUB_DOMAINS: Array<SubDomain> = [
  {
    name: 'Ad Policy UI',
    url: 'adpolicyui',
  },
  {
    name: 'AuthZ',
    url: 'authz',
  },
  {
    name: 'GVP Config',
    url: 'gvpconfig',
  },
  {
    name: 'GMS UI',
    url: 'gmsui',
  },
  {
    name: 'MSS',
    url: 'mss',
  },
]

@Component({
  selector: 'prism-other-apps',
  template: `
    <ul class="nav navbar-nav">
      <li class="nav-item dropdown" dropdown placement="bottom right">
        <span class="fa fa-th fa-2x text-white nav-link"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              dropdownToggle
              (click)="false">

        </span>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
          <div class="dropdown-header text-center">
            <strong>Apps</strong>
          </div>
          <div class="m-2 d-block">
            <div *ngFor="let domain of domains" class="p-2">
              <a class="app-link"
                 [href]="domain.url"
                 target="_blank">
                {{ domain.name }}
              </a>
            </div>
          </div>
        </div>
      </li>
    </ul>

  `,
  styles: [`
      .app-link:hover, .app-link:link, .app-link:visited {
          text-decoration: none;
      }
  `],
})
export class PrismOtherAppsComponent implements OnInit {

  /**
   * Collection of the domain names and urls.
   */
  public domains: Array<SubDomain> = [];

  ngOnInit(): void {
    const host = window.location.hostname
    const currentSubDomain = host.split('.')[0];
    const prismaticDomain = (host === 'localhost') ? 'dev-vod.h264.io' : host.split('.').slice(1).join('.');

    this.domains = SUB_DOMAINS.filter((subDomain) => subDomain.url !== currentSubDomain)
    .map((subDomain) => ({
      name: subDomain.name,
      url: `https://${subDomain.url}.${prismaticDomain}`,
    }));
  }
}
