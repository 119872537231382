import { Route, Switch } from 'react-router-dom';
import { LoginCallback, SecureRoute } from '@okta/okta-react';
import {
  Dashboard,
  CreateService, ServiceList, ServiceDetails,
  HowTo, SystemStatus, Config,
  PageNotFound,
  UserList,
  RoleList,
  CreateRole,
  GroupsList,
  CreateGroup,
} from './pages';
import { SignOut } from './sign-out';
import { environment } from '../environments/environment';

const callbackRoutePath = environment.env === 'local' ? '/prismatic-microapps/authz-ui/auth-callback' : '/authz-callback';

const Routes = () =>
    <Switch>
      <Route path="/auth-callback" component={LoginCallback} />
      <Route path={callbackRoutePath} component={LoginCallback} />
      <SecureRoute path="/" exact component={Dashboard} />

      <SecureRoute path="/sign-out" exact component={SignOut} />
      <SecureRoute path="/roles" exact component={RoleList} />
      <SecureRoute path="/roles/create" exact component={CreateRole} />
      <SecureRoute path="/roles/:id" exact component={CreateRole} />

      <SecureRoute path="/services" exact component={ServiceList} />
      <SecureRoute path="/services/create" exact component={CreateService} />
      <SecureRoute path="/services/:id" exact component={ServiceDetails} />

      <SecureRoute path="/users" exact component={UserList} />
      <SecureRoute path="/groups" exact component={GroupsList} />
      <SecureRoute path="/groups/create" exact component={CreateGroup} />
      <SecureRoute path="/groups/:id" exact component={CreateGroup} />

      <SecureRoute path="/config" exact component={Config} />

      <SecureRoute path="/how-to" exact component={HowTo} />
      <SecureRoute path="/system-status" exact component={SystemStatus} />

      <Route path="*" component={PageNotFound} />
    </Switch>

export default Routes;
