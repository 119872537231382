/* eslint-disable no-use-before-define */
import React, {useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions<CreatableSelectorOptionType>();

export const CreatableSelector = (props) => {
  const [value, setValue] = React.useState<CreatableSelectorOptionType | null>();
  const [selectorOptions, setSelectorOptions] = React.useState(props.selectorOptions);

  useEffect(() => {
    setValue({
      value: props.value,
      inputValue: props.value
    })
  }, [props.value])

  return (
    <Autocomplete
      value={value || { value: '' }}
      onChange={(event, newValue) => {
        let selectedValue
        if (typeof newValue === 'string') {
          setValue({
            value: newValue,
          });
          selectedValue = newValue;
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            value: newValue.inputValue,
          });

          selectorOptions.push({ value: newValue.inputValue })
          setSelectorOptions(selectorOptions)
          
          selectedValue = newValue.inputValue;
        } else {
          setValue(newValue);
          selectedValue = newValue.value;
        }
        props.valueChanged(props.name, selectedValue)
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            value: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      fullWidth
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={props.name}
      options={selectorOptions}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.value;
      }}
      renderOption={(option) => option.value}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label={props.label} />
      )}
    />
  );
}

export interface CreatableSelectorOptionType {
  inputValue?: string;
  value: string;
}
