import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JiraIssueCollectorComponent } from './jira-issue-collector.component';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';



@NgModule({
  declarations: [
    JiraIssueCollectorComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
  ],
  exports: [JiraIssueCollectorComponent],
})
export class JiraIssueCollectorModule { }
