import { call, put } from "redux-saga/effects";
import { IGroup } from "../../../interfaces";
import {toggleAlert, toggleAlertActionCreator, toggleLoading, getGroupsSuccess} from '../../slices'
import { getAsync } from "../commonAsync";
import { toKebabCase } from "../../../pages/shared";

const mapToGroups = (rawGroups): Array<IGroup> => rawGroups?.map(({ id,
  name,
  servicePrefix,
  serviceId,
  admins,
  members }) => ({
    id,
    name,
    servicePrefix,
    serviceId,
    admins,
    members
  }))

export function* getGroupsSaga(action) {
  try {
    yield put(toggleLoading(true))
    const response = yield call(getAsync, `groups?${action.payload?.withTotalRecords ? ("&withTotalRecords=" + action.payload?.withTotalRecords) : ""}${action.payload?.pageSize ? ("&limit=" + action.payload?.pageSize) : ""}${action.payload?.currentPage ? ("&page=" + action.payload?.currentPage) : ""}${(action.payload?.selectedSearchCriteria && action.payload?.searchText) ? ("&" + toKebabCase(action.payload?.selectedSearchCriteria) + "=" + action.payload?.searchText) : ""}`)
    if (yield response.ok) {
      const { totalRecords, data } = yield response.json();
      yield put(getGroupsSuccess({
        totalRecords,
        data: mapToGroups(data)
      }))
    }
    else {
      const errorCode = yield response.status;
      const { Error } = yield response.json();
      const errorMessages = yield Error?.map(error => error?.message).join(",")
      yield put(toggleAlert(toggleAlertActionCreator('error', `${errorCode}: ${errorMessages}`)))
    }
    yield put(toggleLoading(false))
  }
  catch (error) {
    yield put(toggleAlert(toggleAlertActionCreator('error', error?.toString())))
  }
}
