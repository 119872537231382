import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { CheckCircleOutline, ReportProblemOutlined } from "@material-ui/icons";
import { RequestHeaders } from "../shared/request/request-headers";
import { useOktaAuth } from "@okta/okta-react";

export function SystemStatusSection({ hideTitle }) {
  const { authState, oktaAuth } = useOktaAuth();

  const [apiStatuses, setApiStatuses] = useState({ serviceList: false, roleList: false });
  const abortController = new AbortController();

  useEffect(() => {
    const checkApis = async () => {
      const headers = await RequestHeaders.get();
      const res1 = await fetch(`/authz/v1/services`, { headers: headers });
      const res2 = await fetch(`/authz/v1/roles`, { headers: headers });

      setApiStatuses({
        ...apiStatuses,
        serviceList: res1.status === 200,
        roleList: res2.status === 200
      })
    }

    checkApis();

    return () => {
      abortController.abort();
    }
  }, [])

  return <div>
    <div>
      <div>
        <Typography variant="h5" component="h2">
          System Status
        </Typography>
      </div>

      <i>Last updated: 2021-09-07 11:00 EDT</i>

      <div data-testid="api-status">
        {
          authState?.isAuthenticated ?
            <div>
              <Typography variant="h6" component="h3">
                API Statuses
              </Typography>

              <ul>
                <li>
                  <div style={{ display: 'flex' }}>
                    <div>Service List </div>
                    <span>{
                      apiStatuses.serviceList ? <CheckCircleOutline fontSize="small" style={{ color: 'green' }} /> : <ReportProblemOutlined fontSize="small" color="secondary" />
                    }</span>
                  </div>
                </li>
                <li>
                  <div style={{ display: 'flex' }}>
                    <div>Role List </div>
                    <span>{
                      apiStatuses.roleList ? <CheckCircleOutline fontSize="small" style={{ color: 'green' }} /> : <ReportProblemOutlined fontSize="small" color="secondary" />
                    }</span>
                  </div>
                </li>
              </ul>
            </div> : ''
        }
      </div>

      <div data-testid="dev-progress">
        <div>
          <Typography variant="h6" component="h3">
            Tickets
          </Typography>

          <ul>
            <ul>
              <li><a href="https://discoveryinc.atlassian.net/browse/AUTHZ-249">AUTHZ-249 Service actions display optimization</a></li>
              <li><a href="https://discoveryinc.atlassian.net/browse/AUTHZ-212">AUTHZ-212 Allow multiple users (emails) to be assigned to a role at once</a></li>
              <li><a href="https://discoveryinc.atlassian.net/browse/AUTHZ-154">AUTHZ-172 Display edit button and delete button based on authorization</a></li>
              <li><a href="https://discoveryinc.atlassian.net/browse/AUTHZ-152">AUTHZ-152 Display create role button based on authorization</a></li>
              <li><a href="https://discoveryinc.atlassian.net/browse/AUTHZ-226">AUTHZ-226 user data transformation</a></li>
              <li><a href="https://discoveryinc.atlassian.net/browse/AUTHZ-177">AUTHZ-177 Display role assignment button based on authorization</a></li>
              <li><a href="https://discoveryinc.atlassian.net/browse/AUTHZ-181">AUTHZ-181 Display user (role assignment) button based on authorization</a></li>
              <li><a href="https://discoveryinc.atlassian.net/browse/AUTHZ-157">AUTHZ-157 Display error if service name is taken while typing on create service page</a></li>
              <li><a href="https://discoveryinc.atlassian.net/browse/AUTHZ-210">AUTHZ-210 Allow selecting multiple tenants while assigned a role to user(s)</a></li>
              <li><a href="https://discoveryinc.atlassian.net/browse/AUTHZ-171">AUTHZ-171 Display error if role name is taken while typing on create role page</a></li>
              <li><a href="https://discoveryinc.atlassian.net/browse/AUTHZ-163">AUTHZ-163 Error if NOT authorized upon loading edit service page</a></li>
              <li><a href="https://discoveryinc.atlassian.net/browse/AUTHZ-142">AUTHZ-142 Error if NOT authorized upon loading edit role page</a></li>

            </ul>
          </ul>
        </div>

        <div>
          <Typography variant="h6" component="h3">
            SSO Integration
          </Typography>

          <ul>
            <li>All but dashboard page are secured with Okta SSO</li>
            <li>This dev instance requires QA account to sign in (request for QA account <a href="https://discoveryinc.service-now.com/askdiscovery?id=sc_cat_item&sys_id=ec6865301bfbc010ca01fd15cc4bcbf6">here</a>.)</li>
            <li>Production instance will require your normal SSO account to sign in.</li>
          </ul>
        </div>

        <div>
          <Typography variant="h6" component="h3">
            Role Management
          </Typography>

          <ul>
            <li style={{ color: 'green', fontWeight: 'bold' }}>
              <Link to="/roles">Role List</Link>: deployed to stage.
            </li>

            <li style={{ color: 'green', fontWeight: 'bold' }}>
              <Link to="/services/create">Create Service</Link>: deployed to stage.
            </li>

            <li style={{ color: 'green', fontWeight: 'bold' }}>
              <Link to="/services">Update Role</Link>: deployed to stage.
            </li>

            <li style={{ color: 'green', fontWeight: 'bold' }}>
              Delete Role: deployed to stage.  Works on role list page.
            </li>

            <li style={{ color: 'green', fontWeight: 'bold' }}>
              Role Assignment: deployed to stage.  Role assignment works on role list page.  Role assignment deletion works on /users page.
            </li>
          </ul>
        </div>

        <div>
          <Typography variant="h6" component="h3">
            Service Management
          </Typography>

          <ul>
            <li style={{ color: 'green', fontWeight: 'bold' }}>
              <Link to="/services">Service List</Link>: deployed to stage.
            </li>
            <li style={{ color: 'green', fontWeight: 'bold' }}>
              <Link to="/services/create">Create Service</Link>: deployed to stage.
            </li>
            <li style={{ color: 'green', fontWeight: 'bold' }}>
              <Link to="/services">Update Service</Link>: deployed to stage.
            </li>
            <li style={{ color: 'green', fontWeight: 'bold' }}>
              Delete Service: deployed to stage (on service list page).
            </li>
          </ul>
        </div>

        <div>
          <Typography variant="h6" component="h3">
            Resource Type Management
          </Typography>

          <ul>
            <li style={{ color: 'green', fontWeight: 'bold' }}>
              <Link to="/resource-types">Resource List</Link>: deployed to stage (extracting from roles).
            </li>
          </ul>
        </div>

        <div>
          <Typography variant="h6" component="h3">
            Action Management
          </Typography>

          <ul>
            <li style={{ color: 'green', fontWeight: 'bold' }}>
              <Link to="/actions">Action List</Link>: deployed to stage (extracting from services).
            </li>
          </ul>
        </div>

        <div>
          <Typography variant="h6" component="h3">
            User Management
          </Typography>
          <ul>
            <li style={{ color: 'green', fontWeight: 'bold' }}>
              <Link to="/users">User List</Link>: deployed to stage (extracting from role assignments).
            </li>
          </ul>

        </div>

        <div>
          <Typography variant="h6" component="h3">
            User Guide (How to Use AuthZ Data Manager)
          </Typography>
          <ul>
            <li>
              <Link to="/how-to">How to use AuthZ Data Manager</Link> (placeholder page): Pending discussion.
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
}
