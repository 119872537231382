import { makeStyles } from "@material-ui/core";

export const useCreateStatementStyles = makeStyles((theme) => ({
    statementsAddButton: {
        padding: 0
    },
    statementsContainer: {
        padding: 20
    }
}));
