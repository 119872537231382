import React from 'react'
import { makeStyles, Grid, IconButton } from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    height: "48px",
    marginBottom: "1rem"
  },
  searchSubContainer: {
    flex: 1,
    marginRight: "0.5rem",
  },
  searchInput: {
    border: "1px solid #ddd",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    flex: 1,
    padding: "0 1rem",
    "&:focus": {
      outline: "none"
    }
  },
  searchSelect: {
    border: "1px solid #ddd",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    borderLeft: 0,
    padding: "0 0.5rem",
    outline: "none",
    "&:focus": {
      outline: "none"
    }
  },
  searchButton: {
    borderRadius: "4px",
    border: "1px solid #ddd",
  }
}))

type SearchBarPropTypes = {
  filterOptions?: Array<string>,
  searchText: string,
  onSearchTextChange: (event) => void
  selectedSearchCriteria?: string,
  onSelectedSearchCriteriaChange?: (event) => void,
  onSearchClick: (event) => void,
  onEnterKey: (event) => void,
}

export const SearchBar: React.FC<SearchBarPropTypes> = (props: SearchBarPropTypes): JSX.Element => {
  const classes = useStyles()
  const onKeyPress = (event) => {
    if (event.key.toLowerCase() === 'enter') {
      props.onEnterKey(event)
    }
  }
  return (
    <Grid wrap="nowrap" data-testid="search-bar" container className={classes.searchContainer}>
      <Grid wrap="nowrap" container className={classes.searchSubContainer}>
        <input value={props.searchText} onChange={props.onSearchTextChange} onKeyPress={onKeyPress} type="search" placeholder={'Search ' + (props.selectedSearchCriteria || '...')} className={classes.searchInput} />
        {
          props.filterOptions &&
          <select value={props?.selectedSearchCriteria} className={classes.searchSelect} onChange={props?.onSelectedSearchCriteriaChange}>
            <option value="" disabled>Filters</option>
            {
              props.filterOptions?.map(filter => <option key={filter} value={filter}>{filter}</option>)
            }
          </select>
        }
      </Grid>
      <IconButton data-testid="search-button" className={classes.searchButton} disabled={!(!!props.searchText)} onClick={props?.onSearchClick}>
        <SearchIcon />
      </IconButton>
    </Grid>
  )
}
