import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import {useState, useEffect} from 'react';

const CustomizedAlert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Alert = ({ severity, message }) => {

  const [open, setOpen] = useState(!!message)

  useEffect(() => {
    setOpen(!!message)
  }, [message])

  const onClose = () => setOpen(false)

  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={onClose}>
      <CustomizedAlert severity={severity} onClose={onClose}>
        {message}
      </CustomizedAlert>
    </Snackbar>
  );
}
