import { call, put } from "redux-saga/effects";
import { getServicesSuccess, toggleLoading, toggleAlert, toggleAlertActionCreator } from '../../slices'
import { getAsync } from "../commonAsync";

export function* getServicesSaga() {
  try {
    yield put(toggleLoading(true))
    const response = yield call(getAsync, `services?withTotalRecords=false&limit=100&page=1`)
    if (response?.ok) {
      const services = yield response.json();
      yield put(getServicesSuccess(services))
    } else {
      const errorCode = yield response.status;
      const { Error } = yield response.json();
      const errorMessages = yield Error?.map(error => error?.message).join(",")
      yield put(toggleAlert(toggleAlertActionCreator('error', `${errorCode}: ${errorMessages}`)))
    }
    yield put(toggleLoading(false))
  }
  catch (error) {
    yield put(toggleAlert(toggleAlertActionCreator('error', error?.toString())))
  }
}
