import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { GridPagination, SearchBar, useQuery } from '../shared';
import { useAdvancedListStyles } from './advanced-list.style';
import { useAppDispatch } from '../../redux/store';
import {useHistory} from "react-router-dom";

type AdvancedListPropsTypes = {
  columns: GridColDef[],
  getEntityAction: any,
  isLoading?: boolean,
  entity: any,
  searchFilterOptions: string[],
  filterOnLoadProps?: {
    searchText?: string,
    selectedSearchCriteria?: string
  }
}

export const AdvancedList: React.FC<AdvancedListPropsTypes> = (
  { columns, getEntityAction, entity, isLoading, searchFilterOptions }: AdvancedListPropsTypes
): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useAdvancedListStyles();

  // Pagination
  const [paginationState, setPaginationState] = useState({
    selectedPageSize: 25,
    currentPage: 1,
  })

  const onCurrentPageChange = (_event, value) => {
    setPaginationState({
      ...paginationState,
      currentPage: value,
    });
  };
  const onRowsPerPageChange = (event): void => {
    setPaginationState({
      ...paginationState,
      currentPage: 1,
      selectedPageSize: event.target.value,
    });
  }

  // Filters
  const [searchText, setSearchText] = useState<string>('');
  const [selectedSearchCriteria, setSelectedSearchCriteria] = useState<string>();

  const onSearchTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value)
  }, [])

  const onSelectedSearchCriteriaChange: (event: React.ChangeEvent<HTMLSelectElement>) => void = useCallback((event: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedSearchCriteria(event.target.value)
  }, [])

  const onSearchClick = useCallback((_event) => {
    dispatch(getEntityAction(paginationState.selectedPageSize, paginationState.currentPage, true, searchText, selectedSearchCriteria))
  }, [paginationState, searchText, selectedSearchCriteria])

  const query = useQuery();

  useEffect(() => {
    if (selectedSearchCriteria !== undefined) {
      history.push(window.location.pathname)
      dispatch(getEntityAction(
        paginationState.selectedPageSize,
        paginationState.currentPage,
        !!(!entity?.totalRecords),
        searchText,
        selectedSearchCriteria,
      ));
    }
  }, [paginationState.selectedPageSize, paginationState.currentPage, selectedSearchCriteria]);

  useEffect(() => {
    if (query.get('roleName')) {
      setSearchText(query.get('roleName'));
      setSelectedSearchCriteria('Role Name');
    } else {
      setSelectedSearchCriteria(searchFilterOptions?.[0] || 'Name')
    }
  }, [])

  const pagination = () => <GridPagination
  count={Math.ceil(entity?.totalRecords / paginationState.selectedPageSize)}
  selectedPageSize={paginationState.selectedPageSize}
  onRowsPerPageChange={onRowsPerPageChange}
  currentPage={paginationState.currentPage}
  onCurrentPageChange={onCurrentPageChange}
  />

  return (
    <>
      <SearchBar filterOptions={searchFilterOptions}
        searchText={searchText}
        onSearchTextChange={onSearchTextChange}
        selectedSearchCriteria={selectedSearchCriteria}
        onSelectedSearchCriteriaChange={onSelectedSearchCriteriaChange}
        onSearchClick={onSearchClick}
        onEnterKey={onSearchClick} />
      <DataGrid disableSelectionOnClick loading={isLoading} columns={columns} rows={isLoading ? [] : (entity?.data || [])} className={classes.advancedListTable} autoHeight components={{
        Pagination: pagination
      }} />
    </>
  )
}
