import { IconButton, Tooltip, Chip, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ListAltIcon from "@material-ui/icons/ListAlt";
import DeleteIcon from "@material-ui/icons/Delete";
import { DialogBox } from '../shared'
import { useState } from 'react';

const useStyles = makeStyles((_theme) => ({
  resizeIcon: {
    "& span": {
      height: "24px",
    },
    "& span svg": {
      marginTop: "-12px",
    },
  }
}))

const ServiceActions = ({ actions }) => {
  const [openStatementsView, setOpenStatementsView] = useState(false)

  const onStatementsViewClose = () => setOpenStatementsView(false);
  const onStatementsViewClick = () => setOpenStatementsView(true);
  const classes = useStyles();
  return (
    <>
      <Tooltip title="Service Actions"><IconButton className={classes.resizeIcon} onClick={() => onStatementsViewClick()}><ListAltIcon /></IconButton></Tooltip>
      <DialogBox open={openStatementsView} handleClose={onStatementsViewClose}>
        {
          actions?.map((action, idx) => (
            <Chip key={idx}
              label={action}
              variant="outlined"
              color="primary"
              size="medium"
              style={{ margin: "4px" }}
            />
          ))
        }
      </DialogBox>
    </>
  )
}

export const ServiceListColumns = ({ deleteHandler, editHandler, user }) => {

  const classes = useStyles();
  return [
    { property: 'name', name: 'Service Name' },
    { property: 'owner', name: 'Owner' },
    { property: 'prefix', name: 'Prefix' },
    {
      property: "id",
      name: " ",
      renderCell: (row) => {
        return (
          <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
            <ServiceActions actions={row?.actions} />
            <div data-testid="edit-button">
              {user?.can?.update('service', row?.id) && <IconButton className={classes.resizeIcon} onClick={() => editHandler(row)}><EditIcon /></IconButton>}
            </div>
            <div data-testid="delete-button">
              {user?.can?.delete('service', row?.id) && <IconButton className={classes.resizeIcon} onClick={() => deleteHandler(row)}><DeleteIcon /></IconButton>}
            </div>
          </div>
        )
      },
      width: "180px"
    }
  ]
}
