import React from 'react'
import { makeStyles, Select } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    '& > *': {
      marginRight: 16
    }
  },
  select: {
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#eee"
  },
  option: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    paddingBottom: 8,
    '&:hover': {
      backgroundColor: '#00000022',
      cursor: 'pointer'
    },
    '&[aria-selected=true]': {
      backgroundColor: '#00000044',
      cursor: 'pointer'
    }
  },
}));

const pageSizes: Array<number> = [25, 50, 100]

interface GridPaginationProps {
  count?: number;
  selectedPageSize?: number;
  onRowsPerPageChange?: (event) => void;
  currentPage?: number;
  onCurrentPageChange?: (event, value) => void;
}

export const GridPagination: React.FC<GridPaginationProps> = (props: GridPaginationProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <p>Rows Per Page: </p>
      <Select
        value={props?.selectedPageSize}
        onChange={props?.onRowsPerPageChange}
        data-testid="rowsPerPageChangeSelect"
        className={classes.select}
      >
        {
          pageSizes?.map((pageSize) =>
            <option className={classes?.option} key={pageSize} value={pageSize}>{pageSize}</option>
          )
        }
      </Select>
      <Pagination data-testid="pagination" count={props?.count} page={props?.currentPage} onChange={props?.onCurrentPageChange} />
    </div>
  )
}
