export const DELETE_GROUP = 'DELETE_GROUP';

type DeleteGroupActionType = {
  type: string
  payload: {
    groupId: string
  }
}

export const deleteGroupAction: (groupId: string) => DeleteGroupActionType = (groupId): DeleteGroupActionType => ({
  type: DELETE_GROUP,
  payload: {
    groupId
  }
})
