import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ICurrentUser } from '../../../interfaces'

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState : null,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<ICurrentUser>) => action.payload,
  },
})

export const { setCurrentUser } = currentUserSlice.actions

export default currentUserSlice.reducer
