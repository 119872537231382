import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IServices, IService } from '../../interfaces'

const initialServicesState: IServices = {
  totalRecords: 0,
    data: [],
}

const mapToServices = (rawServices): Array<IService> => rawServices?.map(({ id, owner, name, prefix, actions }) => ({
  id,
  owner,
  name,
  prefix,
  actions
}))

export const servicesSlice = createSlice({
  name: 'services',
  initialState : initialServicesState,
  reducers: {
    getServicesSuccess: (state, action: PayloadAction<IServices>) => {
      state.totalRecords = action.payload?.totalRecords === -1 ? state?.totalRecords : action.payload?.totalRecords
      state.data = mapToServices(action.payload.data)
    },
  },
})

export const { getServicesSuccess } = servicesSlice.actions

export default servicesSlice.reducer
