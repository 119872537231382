import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Helpers } from '../../utils/helpers';

export const SELECT_VALUES = [
  5,
  10,
  25,
  50,
  100,
]

@Component({
  selector: 'prism-table-limit-selector',
  template: `
    <div class="mt-n1">
      <label class="mb-3">
        <select class="form-control table-limit"
                [disabled]="disabled"
                [(ngModel)]="limit"
                (change)="changed($event)">
          <option *ngFor="let value of values" [value]="value">{{ value }}</option>
        </select>
      </label>
    </div>
  `,
})
export class TableLimitSelectorComponent {

  /**
   * The data-binding value of the limit.
   */
  @Input()
  public limit: number | string;

  /**
   * If true, the select element is disabled.
   */
  private _disabled = false;

  /**
   * @see _disabled
   * @param value
   */
  @Input()
  set disabled(value: boolean) {
    this._disabled = Helpers.convertStringToBoolean(value);
  }

  /**
   * @see _disabled
   */
  get disabled(): boolean {
    return this._disabled;
  }

  /**
   * Default set of limit values
   */
  private _values: Array<number> = SELECT_VALUES;

  /**
   * @see _values
   * @param values
   */
  @Input()
  set values(values: Array<number> | string) {
    this._values = Helpers.jsonParseString(values);
  }

  /**
   * @see _values
   */
  get values(): Array<number> {
    return this._values;
  }

  /**
   * Returns selected limit value
   */
  @Output()
  public update = new EventEmitter<string | number>();

  /**
   * By default, limit is the middle element of SELECT_VALUES constant
   */
  constructor() {
    this.limit = this.values[Math.floor(this.values.length / 2)];
  }

  /**
   * Emit selected value
   * @param event
   */
  public changed(event) {
    this.update.emit(event.target.value)
  }
}
