export const configData = {
  // verbs
  baseActions: [
    'create',
    'delete',
    'list',
    'update',
    'read',
    'view',

    'hide',
    'unhide',

    'approve',
    'revoke',

    'publish',
    'unpublish',
    'republish',

    'start',
    'stop'
  ],

  // name of entities or objects
  baseResourceTypes: [
    'channel',
    'stream',

    'series',
    'season',
    'video',
    'image',
  ],

  // pre-defined prefixes for actions
  actionPrefixes: [
    'authz',
    'glo',
    'gms'
  ],

  // composition pattern: service-namespace-prefix:object:action
  actions: [
    // should extract from services
    /*    'gms:video:list',
        'gms:video:create',
        'gms:video:view',
        'gms:video:update',
        'gms:video:delete',
        'gms:video:hide',
        'gms:video:unhide',
        'gms:video:approve',
        'gms:video:unapprove',

        'gms:series:list',
        'gms:series:create',
        'gms:series:view',
        'gms:series:update',
        'gms:series:delete',
        'gms:series:hide',
        'gms:series:unhide',

        'gms:jobs:list',
        'gms:jobs:view',
        'gms:jobs:republish',

        // AuthZ related
        'authz:*:*',

        'authz:ListRoles',
        'authz:CreateRole',
        'authz:ViewRole',
        'authz:UpdateRole',
        'authz:DeleteRole',

        'authz:ListServices',
        'authz:CreateService',
        'authz:ViewService',
        'authz:UpdateService',
        'authz:DeleteService',

        'authz:AssignUserRole',

        // AuthZ related
        'glo:*:*',

        'glo:channel:create',
        'glo:channel:list',
        'glo:channel:view',
        'glo:channel:update',
        'glo:channel:delete',
        'glo:channel:start',
        'glo:channel:stop',*/
  ],
  owners: [
    'global/authz',
    'global/glo',
    'global/gms'
  ],
  tenants: [
    'atve_us',
    'cooking_channel_us',
    'discovery_us',
    'dplus_br',
    'dplus_ca',
    'dplus_dk',
    'dplus_es',
    'dplus_fi',
    'dplus_ga',
    'dplus_ie',
    'dplus_it',
    'dplus_nl',
    'dplus_no',
    'dplus_ph',
    'dplus_se',
    'dplus_sport',
    'dplus_uk',
    'dplusindia',
    'fnk_us',
    'food_us',
    'food_network_us',
    'general',
    'gcn',
    'go',
    'hgtv_us',
    'magnolia_us',
    'motortrend',
    'questuk',
    'tlc_us',
    'travel_channel_us',
  ]
}
