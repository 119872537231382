import { Grid, CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { toCamelCase } from '../shared';

type AuthzConfigurationsCardProps = {
  count: number,
  label: string,
  isLoading: boolean
}

const useStyles = makeStyles((_) => ({
  container: {
    border: "1px solid #ccc",
    backgroundColor: "#f5f7fa",
    width: "12rem",
    padding: "1rem"
  },
  progress: {
    padding: "0.5rem"
  },
  heading: {
    fontWeight: "bold"
  }
}));


export const AuthzConfigurationsCard: React.FC<AuthzConfigurationsCardProps> = (props: AuthzConfigurationsCardProps): JSX.Element => {
  const classes = useStyles();

  return <Grid container className={classes.container} direction="column" alignItems='flex-end' justifyContent="center" item>
    <Grid item>
      {props.isLoading
        ?
        <CircularProgress className={classes.progress} />
        :
        <h1 className={classes.heading}>
          <NavLink to={`/${toCamelCase(props.label === "Role Assignments" ? "Users" : props.label)}`}>{props.count}</NavLink>
        </h1>
      }
    </Grid>
    <Grid item>
      <h5>
        {props.label}
      </h5>
    </Grid>
  </Grid>
}
