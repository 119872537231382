import { Button, Container, FormControl, FormHelperText, Grid, InputLabel, Select, TextField } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { areUniqueUserIds, EntityListTitleBar, toCamelCase, toTitleCase } from '../shared'
import { ICreateGroup, ICreateGroupLocal, IGroup } from '../../interfaces'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useCreateRoleStyles } from "../role/create-role.style";
import { createGroupAction, getServicesAction, updateGroupAction } from '../../redux/actions'
import { AddMembersAdmins } from './add-members-admins'
import { environment } from 'apps/authz/src/environments/environment'
import { AssignedRoles } from './assigned-roles'
import { useAppDispatch } from '../../redux/store'
import { toggleAlert, toggleAlertActionCreator } from '../../redux/slices'

const initialGroup: ICreateGroupLocal = {
  name: "",
  serviceId: "",
  membersToBeAdded: "",
  adminsToBeAdded: ""
}

type ICreateGroupErrors = {
  name: string,
  serviceId: string
  membersToBeAdded?: string,
  adminsToBeAdded?: string
}

const initialErrors: ICreateGroupErrors = {
  name: "",
  serviceId: "",
  membersToBeAdded: "",
  adminsToBeAdded: ""
}

const getDatafromState = (state) => ({
  services: state?.services,
  roles: state?.roles,
  user: state?.userPermissions,
})

type CreateGroupProps = {
  group?: IGroup,
  history: any,
}

export const CreateGroup: React.FC<CreateGroupProps> = (props: CreateGroupProps): JSX.Element => {

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { services, user, roles } = useSelector(getDatafromState)
  const classes = useCreateRoleStyles();

  const getInitialGroup = () => {
    return {
      ...initialGroup,
      ...(id && props.history.location?.state?.group),
      ...(id && {
        membersToBeDeleted: [],
        adminsToBeDeleted: []
      })
    }
  }

  const [newGroup, setNewGroup] = useState<ICreateGroupLocal>(getInitialGroup())
  const [errors, setErrors] = useState<ICreateGroupErrors>(initialErrors)

  const getGroupError = (name, value): string => {
    switch (name) {
      case 'name':
        return value ? "" : `${toTitleCase(name)} is required`
      case 'serviceId':
        return value ? "" : 'Service is required'
      default:
        return ''
    }
  }

  const checkForErrors = (name, value): void => {
    setErrors({
      ...errors,
      [name]: getGroupError(name, value)
    })
  }

  const onTextChange = (event): void => {
    checkForErrors(event.target.name, event.target.value)
    setNewGroup({
      ...newGroup,
      [event.target.name]: event.target.value
    })
  }

  const onBlur = (event): void => {
    checkForErrors(event.target.name, event.target.value)
  }

  const allFieldsValid = (objectToVerify): ICreateGroupErrors => {
    let errorsObject = initialErrors

    Object.keys(objectToVerify).forEach(key => errorsObject[key] = "")
    Object.entries(objectToVerify).forEach(([key, value]) => {
      errorsObject = {
        ...errorsObject,
        [key]: getGroupError(key, value)
      }
    })
    return errorsObject;
  }

  const allGroupFieldsAreValid = (): boolean => {
    const groupToVerify: ICreateGroup = {
      name: newGroup.name,
      serviceId: newGroup.serviceId
    }
    const errorsObject = allFieldsValid(groupToVerify);
    setErrors(errorsObject)
    return !Object.values(errorsObject).reduce((a, b) => a + b)
  }

  const onCreateGroup = () => {
    const domain = environment?.production ? "@discovery.com" : "@qadci.com"

    const groupToBeCreated: ICreateGroup = {
      name: newGroup?.name,
      serviceId: newGroup?.serviceId,
      ...(newGroup?.membersToBeAdded && { "membersToBeAdded": newGroup?.membersToBeAdded?.split(",")?.map(value => value?.trim())?.map(value => value?.includes("@") ? value : `${value}${domain}`) }),
      ...(newGroup?.adminsToBeAdded && { "adminsToBeAdded": newGroup?.adminsToBeAdded?.split(",")?.map(value => value?.trim())?.map(value => value?.includes("@") ? value : `${value}${domain}`) }),
      ...(newGroup?.membersToBeDeleted?.length && { "membersToBeDeleted": newGroup?.membersToBeDeleted }),
      ...(newGroup?.adminsToBeDeleted?.length && { "adminsToBeDeleted": newGroup?.adminsToBeDeleted }),
    }
    if (allGroupFieldsAreValid() && areValidateEmails()) {
      if (id) {
        dispatch(updateGroupAction(groupToBeCreated, newGroup?.id, history.push))
      }
      else {
        dispatch(createGroupAction(groupToBeCreated, history.push))
      }
    }
  }

  const textFields: Array<string> = ["Name"]

  useEffect(() => {
    const canCreateGroup = user?.can?.create('group')
    if (id) {
      if (!user?.can?.update('group')) {
        dispatch(toggleAlert(toggleAlertActionCreator('error', "Not authorized to update this group")))
        history.push('/groups')
      }
      else if (!props.history.location?.state?.group) {
        dispatch(toggleAlert(toggleAlertActionCreator('error', "Update is allowed only by navigating via 'Groups' page")))
        history.push('/groups')
      }
      else {
        services?.data?.length === 0 && dispatch(getServicesAction())
      }
    }
    else {
      if (!canCreateGroup) {
        dispatch(toggleAlert(toggleAlertActionCreator('error', "Not authorized to create a group")))
        history.push('/groups')
      }
      else {
        services?.data?.length === 0 && dispatch(getServicesAction())
      }
    }
  }, [])

  const areValidateEmails: () => boolean = (): boolean => {
    let customDomainMembersEmailError = "";
    let customDomainAdminsEmailError = "";
    let membersUniqueError = "";
    let adminsUniqueError = "";

    if (newGroup?.membersToBeAdded) {
      const customDomainMemberIds = newGroup?.membersToBeAdded?.split(",")?.filter(id => id.includes("@"))?.map(value => value?.trim());
      customDomainMembersEmailError = customDomainMemberIds?.length && (!!customDomainMemberIds.map(id => /^\w+([_.-]?\w+)*@[a-zA-Z_-]+?\.[a-zA-Z]{2,3}$/.test(id) ? "" : "Error").join("") ? "Please enter valid member email IDs" : "");
      const areMembersUnique = areUniqueUserIds(newGroup?.membersToBeAdded)
      membersUniqueError = areMembersUnique ? "" : "Please enter unique members";
    }
    if (newGroup?.adminsToBeAdded) {
      const customDomainAdminIds = newGroup?.adminsToBeAdded?.split(",")?.filter(id => id.includes("@"))?.map(value => value?.trim());
      customDomainAdminsEmailError = customDomainAdminIds?.length && (!!customDomainAdminIds.map(id => /^\w+([_.-]?\w+)*@[a-zA-Z_-]+?\.[a-zA-Z]{2,3}$/.test(id) ? "" : "Error").join("") ? "Please enter valid admin email IDs" : "");
      const areAdminsUnique = areUniqueUserIds(newGroup?.adminsToBeAdded)
      adminsUniqueError = areAdminsUnique ? "" : "Please enter unique admins";
    }
    if (customDomainMembersEmailError || customDomainAdminsEmailError || membersUniqueError || adminsUniqueError) {
      const membersError = customDomainMembersEmailError || membersUniqueError;
      const adminsError = customDomainAdminsEmailError || adminsUniqueError;
      setErrors({
        ...errors,
        membersToBeAdded: membersError,
        adminsToBeAdded: adminsError
      })
      return false;
    }
    else {
      setErrors({
        ...errors,
        membersToBeAdded: "",
        adminsToBeAdded: ""
      })
      return true;
    }
  }

  return (
    <>
      <EntityListTitleBar title={`${id ? "Update" : "Create"} Group`} />
      <Container component="main" maxWidth="sm" data-testid="create-group" className={classes.form}>
            {
              textFields.map((field, index) => {
                const camelCaseField = toCamelCase(field)
                return <TextField
                  key={index}
                  variant="outlined"
                  required
                  error={!!errors[camelCaseField]}
                  value={newGroup[camelCaseField]}
                  onChange={onTextChange}
                  onBlur={onBlur}
                  helperText={errors[camelCaseField]}
                  fullWidth
                  id={camelCaseField}
                  label={field}
                  name={camelCaseField}
                  type="text"
                />
              })
            }
            <FormControl variant="outlined" fullWidth error={!!errors.serviceId}>
              <InputLabel htmlFor="demo-simple-select-error-label">Service *</InputLabel>
              <Select
                labelId="demo-simple-select-error-label"
                id="demo-simple-select-error"
                value={newGroup.serviceId || ""}
                error={!!errors['serviceId']}
                onChange={onTextChange}
                fullWidth
                onBlur={onBlur}
                name="serviceId"
                label="Service"
                disabled={!!id}
                defaultValue={newGroup.serviceId || ""}
              >
                {
                  services?.data?.map((service, index) =>
                    <option className={classes.option} key={index} value={service?.id}>{service?.name}</option>
                  )
                }
              </Select>
              {
                errors.serviceId && <FormHelperText>{errors.serviceId}</FormHelperText>
              }
            </FormControl>
            <AddMembersAdmins
              areValidateEmails={areValidateEmails}
              setNewGroup={setNewGroup}
              newGroup={newGroup}
              newGroupErrors={errors} />
              {/* <AssignedRoles currentGroup={newGroup} roles={roles} /> */}
            <Grid container direction="row" spacing={2}>
              <Grid item sm={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push('/groups')}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item sm={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={onCreateGroup}
                >
                  {`${id ? "Update" : "Create"} Group`}
                </Button>
              </Grid>
            </Grid>
          </Container>
    </>
  )
}
