import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbListComponent } from './breadcrumb-list.component';
import { BreadcrumbService } from './services/breadcrumb.service';

@NgModule({
  providers: [BreadcrumbService],
  declarations: [BreadcrumbListComponent],
  imports: [
    CommonModule,
  ],
  exports: [BreadcrumbListComponent],
})
export class BreadcrumbListModule {
}
