import { call, put } from "redux-saga/effects";
import { getGroupsAction } from "../../actions";
import { putAsync } from "../commonAsync";
import {toggleAlert, toggleAlertActionCreator, toggleLoading} from '../../slices'

export function* updateGroupSaga(action) {
  try {
    yield put(toggleLoading(true))
    const response = yield call(putAsync, 'groups', action.payload.group, action.payload.id)
    if (yield response.ok) {
      yield put(toggleAlert(toggleAlertActionCreator('success', `Group updated successfully`)))
      if (yield action?.payload?.push) {
        yield action?.payload?.push('/groups')
      }
      else {
        yield put(getGroupsAction(25, 1, true))
      }
    }
    else {
      const errorCode = yield response.status;
      const { Error } = yield response.json();
      const errorMessages = yield Error?.map(error => error?.message).join(",")
      yield put(toggleAlert(toggleAlertActionCreator('error', `${errorCode}: ${errorMessages}`)))
    }
    yield put(toggleLoading(false))
  }
  catch (error) {
    yield put(toggleAlert(toggleAlertActionCreator('error', error?.toString())))
  }
}
