
export class Utilities {

  static DEFAULT_XOR_KEY = 2021;
  static DEFAULT_CACHE_TTL = 1440;

  /**
   * Encrypt and decript input value with a given cipher key
   * @param value
   * @param key
   */
  static xorCipher(value, key) {
    let encrypted = '';
    const str = value.toString();
    const numberKey = isNaN(parseInt(key, 10)) ? this.DEFAULT_XOR_KEY : parseInt(key, 10);
    for (let i = 0; i < str.length; i++) {
      const a = str.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      const b = a ^ numberKey;
      encrypted = encrypted + String.fromCharCode(b);
    }
    return encrypted;
  }

  /**
   * Store encrypted session data with a given TTL.
   * Default TTL = 1440 (24 hours)
   * @param key
   * @param data
   * @param minutes
   */
  static saveSessionData(key: string, data: object, minutes: number = this.DEFAULT_CACHE_TTL) {
    const expires = new Date();
    const expireMinutes = minutes;
    const sessionObject = {
      expiresAt: expires.setMinutes(expires.getMinutes() + expireMinutes),
      sessionData: data,
    }

    // Cache encrypted session data
    let sessionSaved = false;
    if (sessionStorage) {
      const encryptedSession = this.xorCipher(JSON.stringify(sessionObject), this.DEFAULT_XOR_KEY);
      sessionStorage.setItem(key, encryptedSession);
      sessionSaved = true;
    }
    return sessionSaved;
  }

  /**
   * Retrieve decrypted session data with a given session key
   * @param key
   */
  static getSessionData(key) {
    let sessionData = null;
    if (!sessionStorage || sessionStorage.getItem(key) === null) {
      console.log('session data cache not found');
    } else {
      try {
        const currentDate = new Date();
        const decryptedSession = this.xorCipher(sessionStorage.getItem(key), this.DEFAULT_XOR_KEY);
        const sessionObject = JSON.parse(decryptedSession);
        const expirationTimestamp = sessionObject.expiresAt;
        if (currentDate.getMilliseconds() < expirationTimestamp) {
          // session is not expired
          sessionData = sessionObject.sessionData;
        } else {
          // remove the sessionStorage because it will be set again by previous logic
          sessionStorage.removeItem(key);
        }
      } catch (e) {
        sessionStorage.clear();
      }
    }
    return sessionData;
  }

  /**
   * Checks if the value is null/empty. If it is null/empty,
   * returns 'No Data Found.'
   * @param value
   */
  static missingValueCheck<T>(value: T): T | string {
    const defaultValue = 'No Data Found';

    if (!value || !`${value}`.trim()) {
      return defaultValue;
    } else {
      return value;
    }
  }

  /**
   * Remove undefined or null fields from the object
   * @param inputObject
   */
  static removeEmptyFieldsInObj(inputObject: Object): Object {
    if (inputObject.constructor === Object) {
      Object.keys(inputObject).forEach(key => (inputObject[key] === undefined || inputObject[key] === null) && delete inputObject[key]);
    }
    return inputObject;
  }

  /**
   * Converts all object keys into lowercase
   * @param inputObject
   */
  static convertObjKeysToLowercase(inputObject: Object): Object {
    let lowercaseObj: Object;
    if (inputObject.constructor === Object) {
      lowercaseObj = Object.keys(inputObject)
                           .reduce((obj, key) => (obj[key.toLowerCase()] = inputObject[key], obj), {});
    } else {
      lowercaseObj = inputObject;
    }
    return lowercaseObj;
  }

  /**
   * Convert a string into a slug. More rules can be added.
   * @param str
   */
  static slugify(str: string): string {
    str = str || '';
    return str.replace(/^[^0-9a-z]+|[^0-9a-z]+$/gi, '')
      .replace(/[^0-9a-z]+/gi, '-')
      .toLocaleLowerCase();
  }

  /*
   * Returns true if input is a valid Date, else false.
   * @param value
   */
  static isDate(value: Date): boolean {
    return value && (value instanceof Date) && !isNaN(value.valueOf());
  }
}
