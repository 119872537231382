import { RequestHeaders } from "./request-headers";
import { environment } from "../../../../environments/environment";

export const DeleteItem = async (itemType, itemId, deletedItemSetter) => {
  const defaultErrorHandler = (error) => {
    // TODO
  };

  fetch(`${environment.apiBaseUrl}${itemType}/${itemId}`, {
      method: 'DELETE',
      headers: await RequestHeaders.get()
    })
    .then(res => {
      if (res.ok) {
        deletedItemSetter(itemId)
      }
    })
    .catch(defaultErrorHandler)
}
