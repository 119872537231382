import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Helpers } from '../../../utils/helpers';
import { Filter, FilterCache } from '../../../data/core/filters/filters';

export const NO_NAME_ERROR = 'Please enter a name for your filter';

@Component({
  selector: 'prism-saved-filters',
  template: `
    <div class="row saved-filters">
      <div class="col-12 d-flex">
        <h4 class="flex-grow-1">My Saved Filters</h4>
        <label class="float-right mb-0 mr-1">
          <button class="btn btn-secondary btn-sm" (click)="removeAllSavedFilters()" placement="bottom"
                  tooltip="Delete All Saved Filters">
            <i class="fa fa-trash fa-lg text-white"></i>
          </button>
        </label>
        <label class="float-right mb-0">
          <button class="btn btn-primary btn-sm medium-icons"
                  placement="bottom"
                  tooltip="Save Filter"
                  (click)="saveFilterSwal.fire()"
                  id="button-save-filters"
          >
            <i class="fa fa-plus-square-o fa-lg text-white"></i>
          </button>
        </label>
      </div>
      <div class="col-12 p-3">
        <div *ngIf="savedFilters?.length > 0">
          <div class="row px-2 d-flex" *ngFor="let filter of savedFilters">
            <a href="javascript:void(0)"
               (click)="removeFilter(filter)"
               placement="bottom"
               tooltip="Delete Saved Filter"
               id="button-delete-saved-filters"
            ><i
              class="fa fa-minus-square-o fa-lg text-danger"></i></a>
            <label class="form-check-label flex-grow-1 ml-1">
              <span placement="right" [tooltip]="toString(filter.filter)">{{ filter.name | slice:0:25 }}...</span>
            </label>
            <button class="btn btn-success btn-sm mb-1" (click)="applyFilter(filter)" title="Apply Filter">
              Apply
            </button>
          </div>
        </div>
        <div *ngIf="!savedFilters || savedFilters.length < 1">
          <div>
            <label class="form-check-label">
              Click
              <a (click)="saveFilterSwal.fire()" id="link-save-filters">
                <i class="fa fa-plus-square-o fa-lg text-primary"></i>
              </a> to save the current filter selections ...
            </label>
          </div>
        </div>
      </div>

      <swal
        #saveFilterSwal
        title="Filter Name"
        text="Please enter a name for your filter"
        icon="info"
        input="text"
        [inputValidator]="validateInput"
        [showCancelButton]="true"
        [focusCancel]="true"
        confirmButtonText="Save Filter"
        [swalOptions]="{showClass: {popup: 'animate__animated animate__fadeInDown'},
                                hideClass: {popup: 'animate__animated animate__fadeOutUp'}
                               }"
        (confirm)="saveFilter($event)">
      </swal>
    </div>
  `,
  styles: [`
      :host >>> .tooltip-inner {
          background-color: var(--primary);
          color: #fff;
      }

      :host >>> .tooltip.top .tooltip-arrow::before,
      :host >>> .tooltip.top .tooltip-arrow {
          border-top-color: var(--primary);
      }

      :host >>> .tooltip.left .tooltip-arrow::before,
      :host >>> .tooltip.left .tooltip-arrow {
          border-left-color: var(--primary);
      }

      :host >>> .tooltip.right .tooltip-arrow::before,
      :host >>> .tooltip.right .tooltip-arrow {
          border-right-color: var(--primary);
      }

      :host >>> .tooltip.bottom .tooltip-arrow::before,
      :host >>> .tooltip.bottom .tooltip-arrow {
          border-bottom-color: var(--primary);
      }
  `],
})
export class SavedFiltersComponent implements OnInit {


  /**
   * Identifier key used for caching
   */
  @Input() contentType: string;

  /**
   * The current filter object to be cached.
   */
  private _filters: Filter;

  /**
   * @see _filters
   * @param value
   */
  @Input()
  set filters(value: Filter | string) {
    this._filters = Helpers.jsonParseString(value);
  }

  /**
   * Event that is emitted when stored filters applied
   */
  @Output() apply = new EventEmitter();

  /**
   * Cached filter array retrieved from local storage
   * @private
   */
  private _savedFilters: Array<FilterCache> = [];

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this._load();
  }

  /**
   * Load saved filters from cache
   */
  private _load() {
    const cachedFilters = JSON.parse(localStorage.getItem(this.cacheKey));
    if (cachedFilters) {
      this._savedFilters = cachedFilters;
    }
  }

  /**
   * Save current filters to local storage
   * @param e - filter name
   */
  public saveFilter(e: string) {
    const cachedFilter = Helpers.deepCopy(this._filters);
    const filterName = e ? e : JSON.stringify(cachedFilter);
    const cache = { name: filterName, filter: cachedFilter };
    this._savedFilters.push(cache);
    localStorage.setItem(this.cacheKey, JSON.stringify(this._savedFilters));
  }

  /**
   * Emit selected filters on apply button click
   * @param savedFilter
   */
  public applyFilter(savedFilter: FilterCache) {
    this.apply.emit(savedFilter.filter);
  }

  /**
   * Remove stored filters from local storage
   * @param savedFilter
   */
  public removeFilter(savedFilter: FilterCache) {
    this._savedFilters = this._savedFilters.filter(item => item.filter !== savedFilter.filter);
    localStorage.setItem(this.cacheKey, JSON.stringify(this._savedFilters));
  }

  /**
   * Flush all the saved filters from local storage
   */
  public removeAllSavedFilters() {
    this._savedFilters = [];
    localStorage.removeItem(this.cacheKey);
  }

  /**
   * Stringify filter object for tooltip
   * @param object
   */
  public toString(object: Filter) {
    return JSON.stringify(object);
  }

  /**
   * @see _savedFilters
   */
  get savedFilters(): FilterCache[] {
    return this._savedFilters;
  }

  /**
   * Cache key getter
   */
  get cacheKey(): string {
    return `${this.contentType}-filters`;
  }

  /**
   * Sweet Alert input validator
   */
  public validateInput(value: string): string | null {
    if (!value) {
      return NO_NAME_ERROR;
    }

    return null;
  }

}
