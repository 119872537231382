import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import {useHistory} from "react-router-dom";

// Basic component with logout button
export const SignOut = () => {
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();

  const signOut = async () => {
    const auth = await oktaAuth.isAuthenticated();
    if (auth) {
      oktaAuth.tokenManager.clear();
      await oktaAuth.signOut({ revokeAccessToken: true });
    }

    history.push('/')
  };

  signOut().then();

  return <></>;
};
