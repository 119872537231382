import { Injectable } from '@angular/core';
import { DateRange } from '../data/core/filters/date';
import { optionAll, optionCustom, optionToday, optionWeek, optionYesterday } from '../data/core/filters/dates';
import { DateFilterObject } from '../data/core/filters/filters';
import { HttpParams } from '@angular/common/http';
import moment from 'moment';

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export class Helpers {

  /**
   * Check if provided value is an array of strings.
   * Returns true if value is empty, undefined, or null
   * Returns true if array contains strings only
   * Returns false otherwise
   * @param value
   */
  public static isProperStringArray(value: Array<string> | any): boolean {
    return !value || Array.isArray(value) && value.every(i => (typeof i === 'string'));
  }

  /**
   * Copy provided text to clipboard
   * @param text
   */
  public static copyToClipboard(text: string): void {
    const tmp = document.createElement('textarea');
    tmp.value = text;
    // Hide the tmp element by pushing it off the screen
    tmp.style.position = 'absolute';
    tmp.style.left = '-999px';
    document.body.appendChild(tmp);
    tmp.select();
    document.execCommand('copy');
    document.body.removeChild(tmp);
  }

  /**
   * Prepare GET http params. Exclude any properties with empty or undefined values
   * @param data
   * @param arraySeparator
   */
  static prepareParam(data: object, arraySeparator = '|'): HttpParams {
    let httpParams = new HttpParams();
    const exclude: Array<any> = [undefined, NaN, null, ''];
    for (const key in data) {
      if (!Object.prototype.hasOwnProperty.call(data, key) || exclude.includes(data[key])) {
        continue;
      }
      const value = data[key];
      let pipedValues = '';
      if (Array.isArray(value)) {
        if (!value.length) {
          continue;
        }

        value.forEach(element => {
          pipedValues += `${arraySeparator}${element}`;
        });
        httpParams = httpParams.append(key, pipedValues.substring(1));
      } else {
        httpParams = httpParams.set(key, value);
      }
    }

    return httpParams;
  }

  /**
   * Converts string into boolean.
   * @param data
   */
  static convertStringToBoolean(data: string | boolean): boolean {
    if (typeof data === 'string') {
      return (!(data === '' || data === 'false'));
    }
    return data;
  }

  /**
   * Parses stringified data into object or array.
   * @param data
   */
  static jsonParseString<T>(data: string | T): T {
    if (typeof data === 'string') {
      try {
        return JSON.parse(data);
      } catch (e) {
        console.error(e)
        return null;
      }
    }
    return data;
  }

  /**
   * Copy of the js object
   * @param obj
   */
  static deepCopy<T>(obj: T): T {
    let copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' !== typeof obj) {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, attr)) {
          copy[attr] = this.deepCopy(obj[attr]);
        }
      }
      return copy;
    }
    return obj;
  }

  public static gotoTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  /**
   * Generates a unique UUID.
   * This function is a RFC4122 version 4 compliant solution that
   * offsetting the first 13 hex numbers by a hex portion of the timestamp
   */
  public static generateUUID() {
    const array = new Uint32Array(1);
    let
      d = new Date().getTime(),
      d2 = (performance && performance.now && (performance.now() * 1000)) || 0;

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      let r = window.crypto.getRandomValues(array)[0];
      if (d > 0) {
        // eslint-disable-next-line no-bitwise
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        // eslint-disable-next-line no-bitwise
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      // eslint-disable-next-line no-bitwise
      return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
  }

  /**
   * Remove empty fields from provided object
   * @param obj
   */
  public static removeNulls<T>(obj: any): T {
    if (!obj) {
      return obj;
    }
    const isArray = Array.isArray(obj);
    for (const k of Object.keys(obj)) {
      if (obj[k] === null) {
        if (isArray) {
          obj.splice(Number(k), 1);
        } else {
          delete obj[k];
        }
      } else if (typeof obj[k] === 'object') {
        this.removeNulls(obj[k]);
      }
      if (isArray && obj.length === Number(k)) {
        this.removeNulls(obj);
      }
    }
    return obj;
  }
}

@Injectable()
export class DateHelpers {

  /**
   * Returns the current day date range
   */
  static getTodayDates(): DateRange {
    const now = moment();
    return {
      start: now.startOf('day').toDate(),
      end: now.endOf('day').toDate(),
    };
  }

  /**
   * Returns the day before current date range
   */
  static getYesterdayDates(): DateRange {
    const now = moment().subtract(1, 'day');
    return {
      start: now.startOf('day').toDate(),
      end: now.endOf('day').toDate(),
    };
  }

  /**
   * returns start and end date if stored in localstorage or returns current date time.
   * @function getCustomDates
   * @param dateObj - object with start and end date
   */
  static getCustomDates(dateObj): DateRange {
    const start = (dateObj && dateObj.start) ? moment(dateObj.start) : moment().startOf('day');
    const end = (dateObj && dateObj.end) ? moment(dateObj.end) : moment().endOf('day');
    return {
      start: start.toDate(),
      end: end.toDate(),
    };
  }

  /**
   * Returns date range for the week option
   */
  static getWeekDates(): DateRange {
    const now = moment();
    return {
      start: moment().subtract(6, 'days').startOf('day').toDate(),
      end: now.endOf('day').toDate(),
    };
  }

  /**
   * Return date range base on the provided option
   * @param dateFilter
   */
  static getDateRangeByOption(dateFilter: DateFilterObject): DateRange {
    switch (dateFilter.option) {
      case optionAll:
        return null;
      case optionToday:
        return DateHelpers.getTodayDates();
      case optionYesterday:
        return DateHelpers.getYesterdayDates();
      case optionWeek:
        return DateHelpers.getWeekDates();
      case optionCustom:
        return DateHelpers.getCustomDates(dateFilter);
      default:
        return null;
    }
  }

  /**
   * Format Date object to display on filters list
   * @param date
   */
  static formatDateFilterObject(date: DateFilterObject): string {
    if (date.option === optionAll) {
      return date.option;
    }
    return `${date.option} | `
      + moment.utc(date.start).local().format(DATE_FORMAT)
      + ' - '
      + moment.utc(date.end).local().format(DATE_FORMAT);
  }
}
