import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import '@gvp-microapps/web-components';
import { useOktaAuth } from '@okta/okta-react';
import { getUserAuth } from './redux/actions'
import { setCurrentUser } from './redux/slices';
import { environment } from '../environments/environment';
import { useAppDispatch } from './redux/store';

export default function TopNav() {
  const { authState, oktaAuth } = useOktaAuth();
  const [userName, setUserName] = useState('');
  const dispatch = useAppDispatch();
  const menuItems = (
    <ul className="top-nav-menu">
      <li>
        <NavLink exact className="nav-menu-item" activeClassName="active" to="/">Dashboard</NavLink>
      </li>
      <li>
        <NavLink className="nav-menu-item" activeClassName="active" to="/services">Services</NavLink>
      </li>
      <li>
        <NavLink className="nav-menu-item" activeClassName="active" to="/roles">Roles</NavLink>
      </li>
      <li>
        <NavLink className="nav-menu-item" activeClassName="active" to="/users">Users</NavLink>
      </li>
      <li>
        <NavLink className="nav-menu-item" activeClassName="active" to="/groups">Groups</NavLink>
      </li>
      <li>
        <NavLink className="nav-menu-item" activeClassName="active" to="/config">Config</NavLink>
      </li>
      <li style={{ display: 'none' }}>
        <NavLink className="nav-menu-item" activeClassName="active" to="/users">Users</NavLink>
      </li>
    </ul>
  )

  const userMenuItems = (
    !authState?.isAuthenticated ? '' :
      <li style={{ padding: 5 }}>
        <NavLink to="/sign-out">Sign Out</NavLink>
      </li>
  )

  useEffect(() => {
    if (!authState?.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserName('');
    } else {
      oktaAuth.getUser().then(info => {
        dispatch(setCurrentUser({
          name: info?.name,
          email: info?.email,
        }))
        setUserName(info.name);
        dispatch(getUserAuth(info.preferred_username))
      });
    }
  }, [authState, oktaAuth]);

  return (

    <prism-header
      env-name={environment.env}
      app-url="/"
      name="AuthZ"
      background-color="#1a7a8f"
    >
      <div slot="left">
        {menuItems}
      </div>

      <div slot="right">
        <div className="d-flex">
          <prism-other-apps class="my-auto">
          </prism-other-apps>

          <prism-user-menu style={{ zIndex: "1" }}>
            <ul slot="app-profile-menu-items">
              {userMenuItems}
            </ul>
            {
              userName ? <span slot="avatar">{userName.charAt(0)}</span> : ''
            }
          </prism-user-menu>
        </div>
      </div>
    </prism-header>
  )
}
