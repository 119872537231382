import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { SortDirection, SortEvent } from '../../data/types/sort';

@Component({
  selector: 'prism-sortable-header',
  template: `
    <div class="align-items-center d-flex justify-content-between pointer" (click)="onSortClick()">
      {{ columnName }}
      <i class="fa text-dark ml-1" [ngClass]="icon"></i>
    </div>
  `,
})
export class SortableHeaderComponent {
  /**
   * Name of column
   */
  @Input() columnName = '';

  /**
   * Name of param for column that is being sorted
   */
  @Input() sortKey = '';

  /**
   * Sort direction ('asc' | desc' | 'default')
   * @private
   */
   private _direction: SortDirection = 'default';

  /**
   * Setter for direction
   * @param value
   */
  @Input()
  set direction(value: SortDirection) {
    this._direction = value;
  }

  /**
   * Event emitter for when column header is clicked
   */
  @Output() sort = new EventEmitter<SortEvent>();

  /**
   * Handler for when header is clicked
   */
  public onSortClick(): void {
    const sortOrder: { [key: string]: SortDirection } = { 'asc': 'desc', 'desc': 'default', 'default': 'asc' };
    this._direction = sortOrder[this._direction];
    this.sort.emit({ column: this.sortKey, direction: this._direction });
  }

  /**
   * Get icon class based on sort direction
   */
  get icon(): string {
    switch (this._direction) {
      case 'asc':
        return 'fa-caret-up';
      case 'desc':
        return 'fa-caret-down';
      default:
        return 'fa-sort';
    }
  }
}
