import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IGroups } from '../../interfaces'

const initialGroupsState: IGroups = {
  totalRecords: 0,
    data: [],
}

export const groupsSlice = createSlice({
  name: 'groups',
  initialState : initialGroupsState,
  reducers: {
    getGroupsSuccess: (state, action: PayloadAction<IGroups>) => {
      state.totalRecords = action.payload?.totalRecords === -1 ? state?.totalRecords : action.payload?.totalRecords
      state.data = action.payload.data
    },
  },
})

export const { getGroupsSuccess } = groupsSlice.actions

export default groupsSlice.reducer
