import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DateHelpers, Helpers } from '../../../utils/helpers';
import { dateOptions, optionAll, optionCustom, optionToday } from '../../../data/core/filters/dates';
import { DateFilterObject } from '../../../data/core/filters/filters';

@Component({
  selector: 'prism-filters-date',
  template: `
    <div class="row my-2 dates-filter">
      <div class="col-12">
        <i class="fa fa-calendar"></i> Dates:
        <hr >
      </div>
      <div class="col-md-4">
        <div class="form-check" *ngFor="let option of dateOptions">
          <input class="form-check-input" type="radio"
                 [checked]="checkSelected(option)"
                 (change)="changeOption(option)">
          <label class="form-check-label text-capitalize">
            {{ formatOption(option) }}
          </label>
        </div>
      </div>
      <div class="col-md-8">
        <form [formGroup]="datepickerForm">
          <div class="form-group">
            <input
              class="form-control"
              bsDatepicker
              formControlName="start"
              [maxDate]="filter.end"
              [bsConfig]="dpConfig"
              (onShown)="updateCustomFilter()"
              placeholder="{{dpConfig.dateInputFormat}}"
              id="input-start-in-dates-filter">
          </div>
          <div class="form-group">
            <input
              class="form-control"
              bsDatepicker
              formControlName="end"
              [minDate]="filter.start"
              [maxDate]="maxDate"
              [bsConfig]="dpConfig"
              (onShown)="updateCustomFilter()"
              placeholder="{{dpConfig.dateInputFormat}}"
              id="input-end-in-dates-filter">
          </div>
          <input type="hidden" formControlName="option">
        </form>
      </div>
    </div>
  `,
})

export class FiltersDateComponent implements OnDestroy {

  /**
   * Date Filters form
   */
  public datepickerForm: UntypedFormGroup = new UntypedFormGroup({
    option: new UntypedFormControl(),
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  /**
   * The max date for the end date filter
   */
  public maxDate: Date;

  /**
   * Config for the start and end date pickers
   */
  public dpConfig = {
    adaptivePosition: true,
    containerClass: 'theme-blue',
    dateInputFormat: 'MM/DD/YYYY, h:mm a',
  };

  /**
   * A filter object to manipulate the date filter data
   */
  public filter: DateFilterObject = {
    option: optionToday,
  };

  /**
   * Subscriber for the date filter form
   * @private
   */
  private readonly formSubscription: Subscription;

  /**
   * Emit on filter update
   */
  @Output() public update = new EventEmitter<DateFilterObject>();

  /**
   * Input for a date filter data. If empty set data for today
   * @param selection
   */
  @Input()
  public set selection(selection: DateFilterObject | string) {
    if (!selection) {
      this.updateFilter({ option: null }, false);
      return;
    }

    this.updateFilter(Helpers.jsonParseString(selection), false);
  }

  /**
   * Event category for the analytics event triggered in this component.
   */
  @Input() public analyticsCategory: string | null;

  /**
   * List of date options to be ignored.
   */
  @Input() public dateOptionIgnore: Array<string> = [];

  /**
   * List of the available date options to select.
   */
  public get dateOptions(): Array<string> {
    return dateOptions.filter(each => !this.dateOptionIgnore.includes(each));
  }

  constructor() {
    this.maxDate = new Date();
    this.formSubscription = this.datepickerForm.valueChanges
    .subscribe(values => {
      this.filter = { ...values };
      this.emitFilter();
    });
  }

  /**
   * Unsubscribe from the form subscription
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  /**
   * Replace underscores with spaces for UI
   * @param option
   */
  public formatOption(option: string): string {
    return option.replace(/_/g, ' ');
  }

  /**
   * Check of the current radio button option selected
   * @param option
   */
  public checkSelected(option: string): boolean {
    return option === this.datepickerForm.controls.option.value;
  }

  /**
   * Update filter data of option change
   * @param option
   */
  public changeOption(option: string): void {
    this.filter.option = option;
    this.updateFilter(this.filter);
  }

  /**
   * Update filter data based on filter option or use the current set of start and end dates
   * if option is 'custom'
   * @param dateFilter
   * @param emit set to true to notify a parent component on filter data update
   */
  public updateFilter(dateFilter: DateFilterObject, emit: boolean = true): void {
    const dates = DateHelpers.getDateRangeByOption(dateFilter);

    this.filter = {
      option: dateFilter.option,
      start: dates?.start,
      end: dates?.end,
    }

    this.datepickerForm.patchValue(this.filter, { emitEvent: emit });
  }

  /**
   * Change filter option to 'custom' on open calendar
   */
  public updateCustomFilter(): void {
    this.filter.option = optionCustom;
    this.datepickerForm.patchValue(
      { option: optionCustom },
      { emitEvent: false },
    );
  }

  /**
   * Format return data and notify a parent component on filter change
   * @private
   */
  private emitFilter(): void {
    if (!this.filter.start && this.filter.option !== optionAll) {
      return;
    }
    const returnFilter: DateFilterObject = {
      option: this.filter.option,
      start: this.filter.start ? this.filter.start['toISOString']() : null,
      end: this.filter.end ? this.filter.end['toISOString']() : null,
    };
    this.update.emit(returnFilter);
  }
}
