import { toTitleCase } from '.'
import { IStatement } from '../../interfaces'

// eslint-disable-next-line max-len,no-control-regex
export const emailValidationRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

// item is for passing other data for validation which is not a part of role or service object
// objectToVerify is the whole object of role/service to validate based on thier properties
export const getError = (name: string, value: any, item: any, objectToVerify: any): string => {
  switch (name) {
    case 'name':
      return value
        ?
        /^[a-zA-Z ]+$/.test(value as string)
          ?
          item?.includes(value?.toLowerCase())
            ?
            'This name already exists.'
            :
            ''
          :
          'Name should only contain letters'
        :
        'Name is required'
    case 'prefix':
    case 'description':
      return value
        ?
        /^[a-zA-Z ]+$/.test(value as string)
          ?
          ''
          :
          `${toTitleCase(name)} should only contain letters`
        :
        `${toTitleCase(name)} is required`
    case 'owner':
      return value
        ?
        emailValidationRegex.test(value as string)
          ?
          ''
          :
          `${toTitleCase(name)} should be a valid email`
        :
        `${toTitleCase(name)} is required`
    case 'servicePrefix':
      return value ? '' : `${toTitleCase(name)} is required`
    case 'actions':
      return value.length > 0
        ?
        (objectToVerify.actions as Array<string>)?.includes(value)
          ?
          `${toTitleCase(name)} is duplicate`
          :
          ''
        :
        `${toTitleCase(name)} is required`
    case 'statements':
      if (Array.isArray(item) ? item?.length === 0 : objectToVerify?.statements?.length === 0) {
        if (Array.isArray(value) ? value.length > 0 : value) {
          return ''
        } else {
          return 'At least one statement is required'
        }
      } else if (((Array.isArray(item) ? item : objectToVerify.statements) as Array<IStatement>)?.some(
        statement => (statement?.actions?.some(action => value?.actions?.includes(action)))
          && statement?.resources?.some(resource => value?.resources?.includes(resource)))) {
        return 'Statement contains duplicate combinations'
      } else {
        return ''
      }
    default:
      return ''
  }
}

export const allFieldsValid = (objectToVerify) => {
  let errorsObject = {}

  Object.keys(objectToVerify).forEach(key => errorsObject[key] = '')

  Object.entries(objectToVerify).forEach(([key, value]) => {
    errorsObject = {
      ...errorsObject,
      [key]: getError(key, value, null, objectToVerify),
    }
  })
  return errorsObject;
}
