import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export const loaderSlice = createSlice({
  name: 'isLoading',
  initialState : false,
  reducers: {
    toggleLoading: (_state, action: PayloadAction<boolean>) => action.payload,
  },
})

export const { toggleLoading } = loaderSlice.actions

export default loaderSlice.reducer
