import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FiltersDateComponent } from './filters-date.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
    declarations: [FiltersDateComponent],
    imports: [
        CommonModule,
        SweetAlert2Module,
        TooltipModule,
        ReactiveFormsModule,
        BsDatepickerModule,
    ],
    exports: [FiltersDateComponent],
})
export class FiltersDateModule {
}
