import { useState, useEffect } from "react";
import { EntityListTitleBar, DialogBox, Table } from "../shared";
import { ServiceListColumns } from "./service-list-columns";
import { DeleteItem } from "../shared/request/delete-item";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import { getServicesAction } from "../../redux/actions";

const mapState = (state) => ({
  user: state.userPermissions,
  isLoading: state.isLoading,
  services: state.services,
})

export const ServiceList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showDeletionWarning, setShowDeletionWarning] = useState(false)
  const [itemToBeDeleted, setItemToBeDeleted] = useState(null)
  const [deletedItem, setDeletedItem] = useState(null)
  const { user, isLoading, services } = useSelector(mapState)

  const deleteHandler = (row) => {
    setItemToBeDeleted(row.id)
    setShowDeletionWarning(true)
  }
  const editHandler = (row) => {
    history.push('/services/' + row.id)
  }

  const columns = ServiceListColumns({ deleteHandler, editHandler, user })

  const doDeletion = () => {
    DeleteItem('services', itemToBeDeleted, setDeletedItem);
    setShowDeletionWarning(false);
  }

  const cancelDeletion = () => {
    setItemToBeDeleted(null);
    setShowDeletionWarning(false);
  }

  const abortController = new AbortController();

  useEffect(() => {
    dispatch(getServicesAction())
    return () => {
      abortController.abort();
    }
  }, [deletedItem])

  const filteredServices = services?.data?.filter(service => user?.can?.read('service')?.some(id => id === service?.id || id === "*"))

  return (
    <>
      <EntityListTitleBar
        title={"Services"}
        createButtonParams={user?.can?.create('service') && { url: "/services/create", entityName: "service" }}
      />
      <div>
      {
        isLoading ?
          <LinearProgress /> :
          <div data-testid="service-list">
            <div style={{ height: "60vh", width: '100%' }}>
              <Table headings={columns} minCellWidth={120} tableContent={filteredServices} />
            </div>
          </div>
      }
    </div>

      <DialogBox
        open={showDeletionWarning}
        handleClose={cancelDeletion}
        handleConfirm={doDeletion}
        title="Delete Service"
        description="Are you sure you want to delete the service?"
        showAsConfirmDialog={true}
      />

      <hr />
      <p><i>Note: only displaying services on which you have any permission to make a change.</i></p>
    </>
  )

}
