import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ComparisonReport } from './types';
import { Helpers } from '../../utils/helpers';

@Component({
  selector: 'prism-json-comparison',
  template: `
    <div class="json-comparison">
      <div class="initContainer" [hidden]="hideInitialStep">
        <div class="split-left">
            <textarea spellcheck="false"
                      id="textarealeft"
                      placeholder="Enter JSON to compare, enter an URL to JSON"
                      tabindex="1"
                      [innerHTML]="leftTree | json">
            </textarea>
          <pre id="errorLeft" class="error"></pre>
          <span class="fileInput">or
            <input type="file"
                   id="fileLeft"
                   tabindex="4"
                   onchange="jdd.handleFiles(this.files, 'left')">
          </span>
        </div>

        <div class="center">
          <button #compare
                  class="btn btn-secondary"
                  id="compare"
                  tabindex="3">
            Compare
          </button>
        </div>

        <div class="split-right">
            <textarea spellcheck="false"
                      class="right"
                      id="textarearight"
                      placeholder="Enter JSON to compare, enter an URL to JSON"
                      tabindex="2"
                      [innerHTML]="rightTree | json">
            </textarea>
          <pre id="errorRight" class="error"></pre>
          <span class="fileInput">or
            <input type="file"
                   id="fileRight"
                   tabindex="5"
                   onchange="jdd.handleFiles(this.files, 'right')">
          </span>
        </div>
      </div>

      <div class="diffcontainer" [class.no-initial-step]="hideInitialStep">
        <div class="row json-comparison mb-2">
          <div class="col-md-2 codeLine d-grid">
            <span class="missing m-1 p-1">Missing properties</span>
            <span class="eq m-1 p-1">Unequal values</span>
            <span class="type m-1 p-1">Incorrect types</span>
          </div>
        </div>
        <div [hidden]="hideReport" id="report"></div>
        <div class="row code-blocks">
          <pre id="out" class="col-5 left code-block"></pre>
          <pre id="out2" class="col-5 right code-block"></pre>
        </div>
        <ul id="toolbar" class="toolbar"></ul>
        <div style="display: none;" id="reportData"></div>
      </div>
    </div>
  `,
  styles: [],
})
export class JsonComparisonComponent implements OnDestroy, AfterViewInit {

  /**
   * An object to compare in the left window
   */
  private _leftTree: object;

  /**
   * An object to compare in the right window
   */
  private _rightTree: object;

  /**
   * A flag to hide/show the initial step view
   */
  private _hideInitialStep = true;

  /**
   * A flag to hide/show the comparison report
   */
  private _hideReport = true;

  /**
   * Setter for
   * @see _leftTree
   * @param value
   */
  @Input()
  set leftTree(value: object | string) {
    this._leftTree = Helpers.jsonParseString(value);
  }

  /**
   * @see _leftTree
   */
  get leftTree(): object {
    return this._leftTree;
  }

  /**
   * Setter for
   * @see _rightTree
   * @param value
   */
  @Input()
  set rightTree(value: object | string) {
    this._rightTree = Helpers.jsonParseString(value);
  }

  /**
   * @see _rightTree
   */
  get rightTree(): object {
    return this._rightTree;
  }

  /**
   * Setter for
   * @see _hideInitialStep
   * @param value
   */
  @Input()
  set hideInitialStep(value: boolean) {
    this._hideInitialStep = Helpers.convertStringToBoolean(value);
  }

  /**
   * @see _hideInitialStep
   */
  get hideInitialStep(): boolean {
    return this._hideInitialStep;
  }

  /**
   * Setter for
   * @see _hideReport
   * @param value
   */
  @Input()
  set hideReport(value: boolean) {
    this._hideReport = Helpers.convertStringToBoolean(value);
  }

  /**
   * @see _hideReport
   */
  get hideReport(): boolean {
    return this._hideReport;
  }

  /**
   * Emits a json difference comparison report
   */
  @Output()
  public reportGenerated: EventEmitter<ComparisonReport> = new EventEmitter();

  /**
   * Reference to a compare button
   * @private
   */
  @ViewChild('compare')
  private _compareButton: ElementRef;

  /**
   * Json difference comparison report
   * @private
   */
  private _reportData: ComparisonReport;

  constructor(
    private renderer: Renderer2,
  ) {
  }

  /**
   * @override
   */
  public ngAfterViewInit(): void {
    this._addDiffFiles();
  }

  /**
   * Get and emit report data.
   * @private
   */
  private _emitReportData(): void {
    const data = (document.getElementById('reportData') as HTMLInputElement).innerHTML;

    this._reportData = data ? JSON.parse(data) : {
      incorrectTypes: 0,
      missingProperties: 0,
      totalDiffCount: 0,
      unequalValues: 0,
    };
    this.reportGenerated.emit(this._reportData);
  }

  /**
   * Add css and js assets to the current view.
   * Adding js must be done in the certain sequence
   * @private
   */
  private _addDiffFiles(): void {
    this._addCssToElement('assets/jdd/styles/jdd.css');

    new Observable(res => {
      this._addJsToElement('assets/jdd/js/jQuery.min.js').onload = () => {
        res.next();
      }
    })
      .subscribe(() => {
        this._addJsToElement('assets/jdd/js/jsl.format.js').onload = () => {
          this._addJsToElement('assets/jdd/js/jsl.parser.js');
          this._addJsToElement('assets/jdd/js/jdd.js');

          this._listenToCompareClick();
        };
      })
  }

  /**
   * Add a click event listener to the compare button. Call emit report data function on click.
   * Perform comparison if _hideInitialStep set to true.
   * Set timeout before getting a report to let the tool finish comparison first
   * @see _compareButton
   * @see _hideInitialStep
   * @private
   */
  private _listenToCompareClick(): void {
    this.renderer.listen(this._compareButton.nativeElement, 'click', () => {
      setTimeout(() => {
        this._emitReportData();
      }, 10);
    });

    if (this._hideInitialStep) {
      this._performComparison();
    }
  }

  /**
   * Perform JSON difference comparison
   * Set timeout before getting a report to let the tool load first
   * @private
   */
  private _performComparison(): void {
    setTimeout(() => {
      this._compareButton.nativeElement.click()
    }, 200);
  }

  /**
   * Add a css asset to the current view
   * @param src
   * @private
   */
  private _addCssToElement(src: string): void {
    const headID = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.media = 'screen';
    link.href = src;
    headID.appendChild(link);
  }

  /**
   * Add a js asset to the current view
   * @param src
   * @private
   */
  private _addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  /**
   * Delete js and css assets from the current view
   * @private
   */
  private _deleteDiffFiles(): void {
    const scripts = document.getElementsByTagName('script');
    let i = scripts.length;
    while (i--) {
      if (scripts[i].src.includes('assets/jdd/js/jQuery.min.js') ||
        scripts[i].src.includes('assets/jdd/js/jsl.format.js') ||
        scripts[i].src.includes('assets/jdd/js/jsl.parser.js') ||
        scripts[i].src.includes('assets/jdd/js/jdd.js')) {
        scripts[i].parentNode.removeChild(scripts[i]);
      }
    }

    const styles = window.document.getElementsByTagName('link');
    let j = styles.length;
    while (j--) {
      if (styles[j].href.includes('assets/jdd/styles/jdd.css')) {
        styles[j].parentNode.removeChild(styles[j]);
      }
    }
  }

  /**
   * @override
   */
  public ngOnDestroy(): void {
    this._deleteDiffFiles();
  }
}
