import { call, put } from 'redux-saga/effects'
import { postAsync } from '../commonAsync';
import {toggleAlert, toggleAlertActionCreator, toggleLoading} from '../../slices'

export function* createGroupSaga(action) {
  try {
    yield put(toggleLoading(true));
    const response = yield call(postAsync, "groups", action.payload.newGroup);
    if (yield response?.ok) {
      yield put(toggleAlert(toggleAlertActionCreator('success', `Group created successfully`)))
      yield action.payload.push("/groups");
    }
    else {
      const errorCode = yield response.status;
      const { Error } = yield response.json();
      const errorMessages = yield Error?.map(error => error?.message).join(",")
      yield put(toggleAlert(toggleAlertActionCreator('error', `${errorCode}: ${errorMessages}`)))
    }
  }
  catch (error) {
    yield put(toggleAlert(toggleAlertActionCreator('error', error?.toString())))
  }
}
