import { Component, Input } from '@angular/core';
import { HeaderLogo } from './header-logo.interface';
import { Helpers } from '../../utils/helpers';

@Component({
  selector: 'prism-header',
  template: `
    <div class="app-header prism-header navbar"
         [style.background-color]="backgroundColor">
      <a class="navbar-brand ml-2 mr-1 d-none d-sm-block" [href]="appUrl">
        <div class="navbar-brand-container">
          <img *ngIf="logo"
               class="navbar-brand-full"
               src="{{ logo.src }}"
               width="{{ logo.width }}"
               height="{{ logo.height }}"
               alt="{{ logo.alt }}">
          <div *ngIf="name"
               class="app-name">
            {{ name }}
          </div>
        </div>
      </a>
      <div class="mr-2" *ngIf="envName.length">
        <div class="badge badge-light">
          {{ envName }}
        </div>
      </div>

      <div class="mr-auto d-flex">
        <ng-content select="[slot='left']"></ng-content>
      </div>
      <div class="d-flex">
        <ng-content select="[slot='center']"></ng-content>
      </div>
      <div class="ml-auto d-flex">
        <ng-content select="[slot='right']"></ng-content>
      </div>
    </div>
  `,
  styles: [`
    .prism-header {
      padding: 0 6px;
      border-bottom: none;
      height: 54px;
    }

    .app-name {
      font-size: xx-large;
      font-weight: bold;
      color: white;
    }
  `],
})
export class HeaderComponent {

  /**
   * An application environment name
   */
  @Input()
  public envName: string = '';

  /**
   * An application url link to route on logo click
   */
  @Input()
  public appUrl: string = '/';

  /**
   * A header logo object
   */
  private _logo: HeaderLogo;

  /**
   * @see _logo
   * @param value
   */
  @Input()
  set logo(value: HeaderLogo | string) {
    this._logo = Helpers.jsonParseString(value);
  }

  /**
   * @see _logo
   */
  get logo(): HeaderLogo {
    return this._logo;
  }

  /**
   * An App name or abbreviation
   */
  @Input()
  public name: string;

  /**
   * A HEX color code for the header background color
   */
  @Input()
  public backgroundColor: string = '#1D428A';
}
