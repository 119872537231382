import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IRoleLocalState, IRolesLocalState } from '../../interfaces'

const initialRolesState: IRolesLocalState = {
  totalRecords: 0,
    data: [],
}

const mapToRoles = (rawRoles): Array<IRoleLocalState> => rawRoles?.map(({ name, description, statements, service, id, ownerServiceId, roleAssignmentsCount }) => ({ name, description, statements, service, id, ownerServiceId, roleAssignmentsCount }))

export const rolesSlice = createSlice({
  name: 'roles',
  initialState : initialRolesState,
  reducers: {
    getRolesSuccess: (state, action: PayloadAction<IRolesLocalState>) => {
      state.totalRecords = action.payload?.totalRecords === -1 ? state?.totalRecords : action.payload?.totalRecords
      state.data = mapToRoles(action.payload.data)
    },
  },
})

export const { getRolesSuccess } = rolesSlice.actions

export default rolesSlice.reducer
