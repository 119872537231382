import { call, put } from "redux-saga/effects";
import {toggleAlert, toggleAlertActionCreator, toggleLoading} from '../../slices'
import { putAsync } from "../commonAsync";

export function* updateRoleSaga(action) {
  try {
    yield put(toggleLoading(true))
    const response = yield call(putAsync, 'roles', action.payload.role, action.payload.id)
    if (yield response.ok) {
      yield put(toggleAlert(toggleAlertActionCreator('success', 'Role updated successfully')))
      yield action.payload.push('/roles')
    }
    else {
      const errorCode = yield response.status;
      const { Error } = yield response.json();
      const errorMessages = yield Error?.map(error => error?.message).join(",")
      yield put(toggleAlert(toggleAlertActionCreator('error', `${errorCode}: ${errorMessages}`)))
    }
    yield put(toggleLoading(false))
  }
  catch (error) {
    yield put(toggleAlert(toggleAlertActionCreator('error', error?.toString())))
  }
}
