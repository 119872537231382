 import { AuthorizationSchema } from '../authorization/schema/authorization.schema';

export class PermissionHelpers {
  /**
   * Check if user has required permissions
   *
   * @param sessionUserPermissions - Session user permissions gotten from authz
   * @param permissions - List of the current user permissions
   * @param permissionDrns - List of the current user drn permissions
   */
  public static hasPermission(
    sessionUserPermissions: AuthorizationSchema,
    permissions: Array<string>,
    permissionDrns: Array<string>,
  ): boolean {
    if (!sessionUserPermissions || !permissions) {
      return false;
    }
    let hasPermission = false;

    let resourceDrns = null;
    for (const item of permissions) {
      resourceDrns = sessionUserPermissions.allowedResources[item.toLowerCase()];
      hasPermission = (resourceDrns) ? permissionDrns.some(el => resourceDrns.indexOf(el) >= 0) : false;
      if (hasPermission) {
        break;
      }
    }

    return hasPermission;
  }
}
