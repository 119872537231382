import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  LinearProgress,
  Select,
  TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ViewStatements } from "./view-statements";
import { CreateStatement } from "./create-statement";
import { IRole, IRoleLocalState, IService, IStatement } from '../../interfaces';
import { allFieldsValid, DialogBox, EntityListTitleBar, getError, toCamelCase } from '../shared';
import { Alert } from '@material-ui/lab';
import { useCreateRoleStyles } from "./create-role.style";
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  createRoleAction,
  getRolesAction,
  getServicesAction,
  updateRoleAction
} from '../../redux/actions'
import { useAppDispatch } from '../../redux/store';
import { toggleAlertActionCreator, toggleAlert } from '../../redux/slices';

type CreateRoleProps = {
  role?: IRole,
  history: any,
}

const mapState = state => ({
  user: state?.userPermissions,
  roles: state?.roles,
  services: new Map<string, IService>(state.services?.data?.map(service => [service.prefix, service])),
  isLoading: state?.isLoading
})

export const CreateRole: React.FC<CreateRoleProps> = (props: CreateRoleProps): JSX.Element => {

  const initialRole: IRoleLocalState = {
    name: "",
    description: "",
    statements: [],
    service: null
  }

  type ErrorsType = {
    servicePrefix: string,
    name: string,
    description: string,
    statements: string,
  }

  const initialErrors: ErrorsType = {
    servicePrefix: "",
    name: "",
    description: "",
    statements: "",
  }

  const { user, roles, isLoading, services } = useSelector(mapState);
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  //LOCAL STATES
  const [role, setRole] = useState<IRoleLocalState>(props.history.location?.state?.role || initialRole);
  const [errors, setErrors] = useState<ErrorsType>(initialErrors)
  const [newServiceOnPrefixChange, setNewServiceOnPrefixChange] = useState(null)

  const [expandStatements, setExpandStatements] = useState<boolean>(role?.statements?.length > 0)
  const [openStatementDeleteWarning, setOpenStatementDeleteWarning] = useState<boolean>(false)

  const [showUpdateRoleDialog, setShowUpdateRoleDialog] = useState<boolean>(false);
  const roleNames = roles?.data?.map(roleData => roleData.name.toLowerCase())
  const isRoleToCopy = !id && props.history.location?.state?.role;

  //EFFECTS
  useEffect(() => {
    setExpandStatements(role?.statements?.length > 0)
    setErrors({
      ...errors,
      statements: ""
    })
  }, [role?.statements?.length])

  useEffect(() => {
    if (roles.length === 0) {
      dispatch(getRolesAction(25, 1, true))
    }
    const canCreateRole = user?.can?.create('role')
    if (id) {
      if (props.history.location?.state?.id) {
        dispatch(getServicesAction())
      } else {
        dispatch(toggleAlert(toggleAlertActionCreator('error', "Update is allowed only by navigating via 'Roles' page")))
        history.push('/roles')
      }
    } else {
      if (canCreateRole) {
        isRoleToCopy && setRole({
          ...role,
          name: "",
          description: `Copied from ${role?.name} role. Original Description: "${role?.description}"`
        })
        dispatch(getServicesAction())
      } else {
        dispatch(toggleAlert(toggleAlertActionCreator('error', "Not authorized to create a role")))
        history.push('/roles')
      }
    }
  }, [])

  const history = useHistory();

  //CALLBACKS
  const onUpdateRoleDialogCancel = (): void => setShowUpdateRoleDialog(false);

  const onUpdateRoleDialogConfirm = (): void => {
    const roleToPost: IRole = {
      serviceId: role.service?.id,
      name: role.name,
      description: role.description,
      statements: role.statements,
    }
    setShowUpdateRoleDialog(false);
    dispatch(updateRoleAction(roleToPost, history.push, role.id))
  }

  const textChanged = (event: React.ChangeEvent<{ value: string, name: string }>): void => {
    event.target.name === "name"
      ?
      checkForErrors(event.target.name, event.target.value, roleNames, null)
      :
      checkForErrors(event.target.name, event.target.value, null, role)
    if (event.target.name === 'servicePrefix') {
      if (role.statements.length > 0) {
        setOpenStatementDeleteWarning(true)
        setNewServiceOnPrefixChange(services.get(event.target.value))
      } else
        setRole({
          ...role,
          service: services.get(event.target.value)
        })
    } else {
      setRole({
        ...role,
        [event.target.name]: event.target.value
      })
    }
  }

  const onExistingPrefixChangeConfirm = () => {
    setRole({
      ...role,
      statements: [],
      service: newServiceOnPrefixChange
    })
    setNewServiceOnPrefixChange(null)
    setOpenStatementDeleteWarning(false)
  }

  const checkForErrors = (name, value, item, role): void => {
    setErrors({
      ...errors,
      [name]: getError(name, value, item, role)
    })
  }

  const allRoleFieldsAreValid = (): boolean => {
    const roleToVerify = {
      name: role.name,
      description: role.description,
      statements: role.statements,
      servicePrefix: role.service?.prefix
    }
    const errorsObject = allFieldsValid(roleToVerify) as ErrorsType;
    setErrors(errorsObject)
    return !Object.values(errorsObject).reduce((a, b) => a + b)
  }

  const onCreateRole: (event) => void = (event): void => {
    event.preventDefault()

    const roleToPost: IRole = {
      serviceId: role.service?.id,
      name: role.name,
      description: role.description,
      statements: role.statements,
    }
    allRoleFieldsAreValid() && (id ? setShowUpdateRoleDialog(true) : dispatch(createRoleAction(roleToPost, history.push)))
  }

  const onBlur = (event): void => {
    checkForErrors(event.target.name, event.target.value, null, role)
  }

  const onCreateStatement = (selectedActions: Array<string>, selectedResources: Array<string>): void => {
    const newStatement = {
      actions: selectedActions,
      effect: "Allow",
      resources: selectedResources
    } as IStatement;

    setRole({
      ...role,
      statements: [
        ...role.statements,
        newStatement
      ]
    })
  }

  const onDeleteStatement = (indexToBeDeleted: number): void => {
    setRole({
      ...role,
      statements: [
        ...role.statements.filter((_, index) => index !== indexToBeDeleted)
      ]
    })
  }

  //STYLE INSTANCE
  const classes = useCreateRoleStyles();

  const textFields: Array<string> = ["Name", "Description"]

  //FILTER SERVICE PREFIX LIST AS PER USER AUTHORISATION
  const canCreateRole = user?.can?.create('role')
  let filteredServices = null;
  if (canCreateRole || user?.can?.update('role')) {
    const allServices = Array.from(services?.values());
    filteredServices = (canCreateRole?.includes('*') || canCreateRole?.includes('role'))
      ?
      Array.from(services?.keys())
      :
      (canCreateRole?.flatMap(serviceId => allServices?.filter(service => service.id === serviceId)))?.map(service => service?.prefix)
  }

  return (
    <>
      <EntityListTitleBar title={`${id ? "Update" : "Create"} Role`}/>

      {
        isLoading
          ?
          <LinearProgress/>
          :
          <Container component="main" maxWidth="sm" data-testid="create-role">
            <CssBaseline/>
            <form className={classes.form}>
              {
                textFields.map((field, index) => {
                  const camelCaseField = toCamelCase(field)
                  return <TextField
                    key={index}
                    variant="outlined"
                    required
                    error={!!errors[camelCaseField]}
                    value={role[camelCaseField]}
                    onChange={textChanged}
                    helperText={errors[camelCaseField]}
                    fullWidth
                    id={camelCaseField}
                    label={field}
                    name={camelCaseField}
                    type="text"
                  />
                })
              }
              <FormControl variant="outlined" fullWidth onBlur={onBlur} error={!!errors.servicePrefix}>
                <InputLabel onBlur={onBlur} htmlFor="demo-simple-select-error-label">Service Prefix *</InputLabel>
                <Select
                  labelId="demo-simple-select-error-label"
                  id="demo-simple-select-error"
                  value={role.service?.prefix || ""}
                  error={!!errors['servicePrefix']}
                  onChange={textChanged}
                  fullWidth
                  onBlur={onBlur}
                  name="servicePrefix"
                  label="servicePrefix"
                  defaultValue={role.service?.prefix || ""}
                >
                  {
                    filteredServices?.map((servicePrefix) =>
                      <option className={classes.option} key={servicePrefix}
                              value={servicePrefix}>{servicePrefix}</option>
                    )
                  }
                </Select>
                {
                  errors.servicePrefix && <FormHelperText>{errors.servicePrefix}</FormHelperText>
                }
              </FormControl>
              <Card style={{ border: errors.statements && "1px solid red" }}>
                {
                  role.service?.prefix
                    ?
                    <FormControl variant="outlined" fullWidth onBlur={onBlur}>
                      <CreateStatement
                        actionOptions={services.get(role.service?.prefix)?.actions || []}
                        onCreateStatement={onCreateStatement}
                        servicePrefix={role.service?.prefix}
                        statements={role.statements}
                      />
                      {/* View Statements Accordion */}
                      <Accordion expanded={expandStatements} onChange={(event) => {
                        setExpandStatements(!expandStatements)
                      }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon/>}
                        >
                          <FormLabel component="label">Statements</FormLabel>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordion}>
                          <ViewStatements statements={role.statements} onDeleteStatement={onDeleteStatement}
                                          shouldShowDeleteButton/>
                        </AccordionDetails>
                      </Accordion>
                      {
                        errors.statements &&
                        <FormHelperText style={{ color: "red" }}>{errors.statements}</FormHelperText>
                      }
                    </FormControl>
                    :
                    <Alert severity="info">Pick a service prefix to add statements.</Alert>
                }
              </Card>
              <DialogBox
                open={openStatementDeleteWarning}
                showAsConfirmDialog
                description="Changing the service prefix will detete the existing statements. Do you wish to continue?"
                handleClose={() => setOpenStatementDeleteWarning(false)}
                handleConfirm={onExistingPrefixChangeConfirm}
              />
              <Grid container direction="row" spacing={2}>
                <Grid item sm={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={() => history.push('/roles')}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onCreateRole}
                  >
                    {`${id ? "Update" : "Create"} Role`}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
      }
      <DialogBox
        open={showUpdateRoleDialog}
        handleClose={onUpdateRoleDialogCancel}
        handleConfirm={onUpdateRoleDialogConfirm}
        title="Update Role"
        description={`Are you sure to update ${role.name} role?`}
        showAsConfirmDialog
      />
    </>
  )
}
