import {useEffect, useState} from 'react';
import { Grid, TextField } from '@material-ui/core';
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';

const TokenClaimSchema = {
  '$schema': 'http://json-schema.org/draft-04/schema#',
  'type': 'object',
  'properties': {
    'cid': {
      'type': 'string',
    },
    'aud': {
      'type': 'string',
    },
    'iss': {
      'type': 'string',
    },
  },
  'required': [
    'cid',
    'aud',
    'iss',
  ],
}

export const ServiceToken = (props) => {
  const [errors, setErrors] = useState({
    tokenType: '',
    tokenProvider: '',
    tokenClaims: '',
  });

  useEffect(() => {
    tokenPropChanged('tokenType', props.service.token.tokenType);
  }, [props.service.tokenValidation]);

  const validate = (name = '', value = null) => {
    if (!name) {
      return errors;
    }

    const tokenClaimsErrors = [];
    let newErrorValue = '';
    if (name === 'tokenType' || name === 'tokenProvider') {
      newErrorValue = props.service.tokenValidation && !value ? `Missing "${name}" value.` : ''
    } else if (name === 'tokenClaims') {
      if (typeof value !== 'object') {
        tokenClaimsErrors.push('"tokenClaims" is expected to be an object.')
      } else {
        ['cid', 'aud', 'iss'].forEach(propName => {
          if (!value.hasOwnProperty(propName)) {
            tokenClaimsErrors.push('Missing ' + propName + ' in tokenClaims.');
          }
          // TODO when the rules are clear, validate actual values in addition to checking existence of props.
        })
      }
      newErrorValue = tokenClaimsErrors.join('');
    }

    return {
      ...errors,
      [name]: newErrorValue,
    };
  };

  const tokenPropChanged = (propName, propValue) => {
    const updatedErrors =  validate(propName, propValue);

    props.onTokenError(Object.values(updatedErrors).join(''));

    props.onTokenChange({
      ...(props.service.token),
      [propName]: propValue,
    });

    setErrors(updatedErrors);
  }

  const valueChanged = (event) => {
    tokenPropChanged(event.target.name, event.target.value);
  };

  const claimsChanged = (newClaims) => {
    tokenPropChanged('tokenClaims', newClaims)
  };

  return (
    <Grid container spacing={2}>
      {
        props.service?.id
          ?
          <>
            <i>Token not updatable at this time. Implementation under way.</i>
            <pre>
              { JSON.stringify(props.service.token, null, 2) }
            </pre>
          </>
          :
          <>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                value={props.service.token.tokenType}
                onChange={valueChanged}
                id="tokenType"
                label="Type"
                name="tokenType"
                type="text"
                size="small"
                error={!!errors.tokenType}
                helperText={errors.tokenType}
                disabled={props.service.id}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                value={props.service.token.tokenProvider}
                onChange={valueChanged}
                id="tokenProvider"
                label="Provider"
                name="tokenProvider"
                type="text"
                size="small"
                error={!!errors.tokenProvider}
                helperText={errors.tokenProvider}
                disabled={props.service.id}
              />
            </Grid>

            <Grid item xs={12}>
              {props.service.id ?
                <>
                  <i>Token claims not updatable at this time.</i>
                  <pre>
              {JSON.stringify(props.service.token.tokenClaims, null, 2)}
            </pre>
                </>
                :
                <>
                  <JsonEditor
                    mode={'tree'}
                    ace={ace}
                    htmlElementProps={{style: {height: 180}}}
                    value={props.service.token.tokenClaims}
                    onChange={claimsChanged}
                    id="tokenClaims"
                    name="tokenClaims"
                    readonly={props.service.id}
                    schema={TokenClaimSchema}
                  />
                  <i>
                    At this time, all three fields are required: cid, aud, iss. Empty values are allowed.
                  </i>
                </>
              }

            </Grid>
          </>
      }
    </Grid>
  )
}
