import { EntityListTitleBar } from "../shared";
import { Card, Divider, FormLabel, Typography } from "@material-ui/core";
import { configData } from "../../../config-data";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

export const Config = () => (
  <>
    <EntityListTitleBar title={"AuthZ Data Manager Config Data"} />

    <Container component="main" maxWidth="xs" data-testid="resource-type-list">
      <CssBaseline />
      <Card style={{ padding: "1rem", marginBottom: "1rem" }}>
        <FormLabel component="legend">Pre-defined Service Prefixes</FormLabel>
        {
          configData.actionPrefixes.map((p, idx) => (
            <>
              <Typography variant="body1">
                {p}
              </Typography>
              <Divider /></>
          ))
        }
      </Card>

      <Card style={{ padding: "1rem", marginBottom: "1rem" }}>
        <FormLabel component="legend">Pre-defined Base Actions</FormLabel>
        {
          configData.baseActions.map((a, idx) => (
            <>
              <Typography variant="body1">
                {a}
              </Typography>
              <Divider /></>
          ))
        }
      </Card>

      <Card style={{ padding: "1rem", marginBottom: "1rem" }}>
        <FormLabel component="legend">Pre-defined Base Resource Types</FormLabel>
        {
          configData.baseResourceTypes.map((r, idx) => (
            <>
              <Typography variant="body1">
                {r}
              </Typography>
              <Divider /></>
          ))
        }
      </Card>
    </Container>
  </>
)
