import { IRoleAssignment } from "../../../interfaces";

export const CREATE_ROLE_ASSIGNMENT = 'CREATE_ROLE_ASSIGNMENT'

type CreateRoleAssignmentActionType = {
    type: string,
    payload: {
        roleAssignment: IRoleAssignment
    }
}

export const createRoleAssignmentAction: (roleAssignment: IRoleAssignment) => CreateRoleAssignmentActionType = (roleAssignment): CreateRoleAssignmentActionType => ({
    type: CREATE_ROLE_ASSIGNMENT,
    payload: {
        roleAssignment
    }
})
