import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IAlert } from '../../../interfaces'

const initialAlertState: IAlert = {
  message: '',
    type: '',
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState : initialAlertState,
  reducers: {
    toggleAlert: (state, action: PayloadAction<IAlert>) => {
      state.message = action.payload.message
      state.type = action.payload.type
    },
  },
})

type ToggleAlertActionType = {
  type: string,
  message: string
}

export const toggleAlertActionCreator : (type: string, message: string) => ToggleAlertActionType = (type: string, message: string) : ToggleAlertActionType => ({
  type, message
})

export const { toggleAlert } = alertSlice.actions

export default alertSlice.reducer
