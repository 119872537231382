import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { FiltersContainerComponent } from './components/filters/filters-container/filters-container.component';
import { OptionsFilterComponent } from './components/filters/options-filter/options-filter.component';
import { FiltersDateComponent } from './components/filters/filters-date/filters-date.component';
import { SearchFilterComponent } from './components/filters/search-filter/search-filter.component';
import { SavedFiltersComponent } from './components/filters/saved-filters/saved-filters.component';
import { FiltersListComponent } from './components/filters/filters-list/filters-list.component';
import { Type } from '@angular/core';
import { ConfirmationModalModule } from './components/confirmation-modal/confirmation-modal.module';
import { FiltersContainerModule } from './components/filters/filters-container/filters-container.module';
import { FiltersDateModule } from './components/filters/filters-date/filters-date.module';
import { SearchFilterModule } from './components/filters/search-filter/search-filter.module';
import { SavedFiltersModule } from './components/filters/saved-filters/saved-filters.module';
import { OptionsFilterModule } from './components/filters/options-filter/options-filter.module';
import { FiltersListModule } from './components/filters/filters-list/filters-list.module';
import { HorizontalMenuComponent } from './components/horizontal-menu/horizontal-menu.component';
import { HorizontalMenuModule } from './components/horizontal-menu/horizontal-menu.module';
import { HeaderComponent } from './components/header/header.component';
import { HeaderModule } from './components/header/header.module';
import { PrismOtherAppsModule } from './components/prism-other-apps/prism-other-apps.module';
import { PrismOtherAppsComponent } from './components/prism-other-apps/prism-other-apps.component';
import { TableLimitSelectorComponent, TableLimitSelectorModule } from './components/table-limit-selector';
import { LoadingSpinnerComponent, LoadingSpinnerModule } from './components/loading-spinner';
import { ResourceNotFoundNoticeComponent, ResourceNotFoundNoticeModule } from './components/resource-not-found-notice';
import { BadgePillListComponent, BadgePillListModule } from './components/badge-pill-list';
import { BadgePillComponent, BadgePillModule } from './components/badge-pill';
import { ExpandableListComponent, ExpandableListModule } from './components/expandable-list';
import { PermissionCheckModule } from './directives/permission-check/permission-check.module';
import { BreadcrumbListComponent, BreadcrumbListModule } from './components/breadcrumb-list';
import { JiraIssueCollectorComponent, JiraIssueCollectorModule } from './components/jira-issue-collector';
import { JsonComparisonComponent, JsonComparisonModule } from './components/json-comparison';
import { SortableHeaderComponent, SortableHeaderModule } from './components/sortable-header';

interface BootstrapComponents {
  selector: string;
  component: Type<any>;
}

export const coreModules = [
  ConfirmationModalModule,
  FiltersContainerModule,
  FiltersDateModule,
  SearchFilterModule,
  SavedFiltersModule,
  OptionsFilterModule,
  FiltersListModule,
  HorizontalMenuModule,
  HeaderModule,
  PrismOtherAppsModule,
  TableLimitSelectorModule,
  PermissionCheckModule,
  ExpandableListModule,
  ResourceNotFoundNoticeModule,
  BadgePillModule,
  BadgePillListModule,
  LoadingSpinnerModule,
  BreadcrumbListModule,
  JiraIssueCollectorModule,
  JsonComparisonModule,
  SortableHeaderModule,
]

export const bootstrapComponents: Array<BootstrapComponents> = [
  {
    selector: 'prism-confirmation-modal',
    component: ConfirmationModalComponent,
  },
  {
    selector: 'prism-filters-container',
    component: FiltersContainerComponent,
  },
  {
    selector: 'prism-options-filter',
    component: OptionsFilterComponent,
  },
  {
    selector: 'prism-filters-date',
    component: FiltersDateComponent,
  },
  {
    selector: 'prism-search-filter',
    component: SearchFilterComponent,
  },
  {
    selector: 'prism-saved-filters',
    component: SavedFiltersComponent,
  },
  {
    selector: 'prism-filters-list',
    component: FiltersListComponent,
  },
  {
    selector: 'prism-horizontal-menu',
    component: HorizontalMenuComponent,
  },
  {
    selector: 'prism-header',
    component: HeaderComponent,
  },
  {
    selector: 'prism-other-apps',
    component: PrismOtherAppsComponent,
  },
  {
    selector: 'prism-table-limit-selector',
    component: TableLimitSelectorComponent,
  },
  {
    selector: 'prism-expandable-list',
    component: ExpandableListComponent,
  },
  {
    selector: 'prism-resource-not-found',
    component: ResourceNotFoundNoticeComponent,
  },
  {
    selector: 'prism-badge-pill',
    component: BadgePillComponent,
  },
  {
    selector: 'prism-badge-pill-list',
    component: BadgePillListComponent,
  },
  {
    selector: 'prism-loading-spinner',
    component: LoadingSpinnerComponent,
  },
  {
    selector: 'prism-breadcrumb-list',
    component: BreadcrumbListComponent,
  },
  {
    selector: 'prism-jira-issue-collector',
    component: JiraIssueCollectorComponent,
  },
  {
    selector: 'prism-json-comparison',
    component: JsonComparisonComponent,
  },
  {
    selector: 'prism-sortable-header',
    component: SortableHeaderComponent,
  },
]
