export const DISPLAY_LIST_IGNORE = ['page', 'limit', 'count'];
export const DISPLAY_LIST_DATES = 'dates';

export interface FilterObject<T = any> {
  filter: string,
  value: T,
}

export interface FilterOption {
  name: string,
  value?: string | null,
  code?: string,
}

export interface DateFilterObject {
  option: string,
  start?: string | Date,
  end?: string | Date,
}

export interface FilterCache {
  name: string,
  filter: any,
}

export interface Filter {
  networkIds?: any,
  status?: any,
  page?: number,
  limit?: number,
  text?: string,
  channelNames?: Array<string>,
  hidden?: boolean | string,
  dates?: DateFilterObject,
  [key: string]: any,
}

export interface FilterOptions {
  name: string,
  code: string,
  type: string,
  parameter?: string,
  options: Array<FilterOption>,
}

export interface FilterListObject {
  key: string,
  value: any,
}

export type OptionsFilterType = string | number | boolean | Array<string | number | boolean>;
