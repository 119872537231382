import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpandableListComponent } from './expandable-list.component';
import { PopoverModule } from 'ngx-bootstrap/popover';


@NgModule({
  declarations: [ExpandableListComponent],
  imports: [
    CommonModule,
    PopoverModule,
  ],
  exports: [ExpandableListComponent],
})
export class ExpandableListModule {
}
