import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { AdvancedList, DialogBox, EntityListTitleBar } from '../shared';
import { GridColDef } from '@material-ui/data-grid';
import { IRoleAssignments } from '../../interfaces';
import { IconButton, Tooltip } from '@material-ui/core';
import { RoleAssignmentsTableHeadings } from '../../enums';
import { StatementsDialog } from '../role';
import { useSelector } from 'react-redux';
import {
  getRoleAssignmentsAction,
  deleteRoleAssignmentAction,
} from '../../redux/actions';
import { useAppDispatch } from '../../redux/store';

const getDataFromState = (state) => ({
  roleAssignments: state.roleAssignments,
  isLoading: state.isLoading,
  user: state.userPermissions,
});

export const UserList: React.FC = (): JSX.Element => {
  const { roleAssignments, isLoading, user } = useSelector(getDataFromState);
  const dispatch = useAppDispatch();

  const [roleAssignmentToBeDeleted, setRoleAssignmentToBeDeleted] = useState<string>(null);

  const columns: GridColDef[] = [
    {
      field: 'userId',
      headerName: RoleAssignmentsTableHeadings.UserId,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'roleName',
      headerName: RoleAssignmentsTableHeadings.RoleName,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'tenantId',
      headerName: RoleAssignmentsTableHeadings.TenantId,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'status',
      headerName: RoleAssignmentsTableHeadings.Status,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'servicePrefix',
      headerName: RoleAssignmentsTableHeadings.ServicePrefix,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'id',
      headerName: ' ',
      renderCell: (params) => {
        const onDeleteClick = () => {
          setRoleAssignmentToBeDeleted(params.row.id);
        };
        const canDelete = (ownerServiceId) => {
          // assignment on service owner role displayed but CANNOT be deleted
          if (params.row.roleName?.includes("Owner")) {
            return false;
          }
          // assignment on service admin role displayed but can only be deleted by super admin or that service's owner
          if (params.row.roleName?.includes("Admin")) {
            return (user?.isSuperAdmin() || user?.isServiceOwner(params.row.ownerServiceId));
          }
          // assignment any other role can be deleted by super admin, service owner, or service admin
          // group admins CANNOT delete any assignment
          const partialAuthRawData = user?.can?.delete(
            'roleAssignment',
            null,
            null,
            true,
          );
          let permission = false;
          partialAuthRawData &&
            Object.entries(partialAuthRawData).forEach(
              ([key, value]: [string, Array<string>]) => {
                permission =
                  permission ||
                  key === '*' ||
                  key === ownerServiceId ||
                  value?.includes(ownerServiceId);
              },
            );
          return permission;
        };
        return (
          <>
            <StatementsDialog roleId={params.row.roleId} />
            {canDelete(params?.row?.ownerServiceId) && (
              <Tooltip title="Delete">
                <IconButton onClick={onDeleteClick}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
      disableColumnMenu: true,
      width: 120,
      align: 'right',
    },
  ];

  const handleDeleteConfirm: () => void = (): void => {
    setRoleAssignmentToBeDeleted(null);
    dispatch(deleteRoleAssignmentAction(roleAssignmentToBeDeleted));
  };

  return (
    <>
      <EntityListTitleBar title={'Users'} />
      <AdvancedList
        columns={columns}
        getEntityAction={getRoleAssignmentsAction}
        isLoading={isLoading}
        entity={roleAssignments}
        searchFilterOptions={Object.values(RoleAssignmentsTableHeadings)}
      />
      {/* ROLE ASSIGNMENT DELETE DIALOG */}
      <DialogBox
        open={!!roleAssignmentToBeDeleted}
        handleClose={() => setRoleAssignmentToBeDeleted(null)}
        handleConfirm={handleDeleteConfirm}
        title="Delete Role Assignment"
        description="Are you sure you want to delete the role assignment?"
        showAsConfirmDialog
      />
    </>
  );
};

UserList.defaultProps = {
  roleAssignments: {
    totalRecords: 0,
    data: [],
  } as IRoleAssignments,
};
