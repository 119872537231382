export const GET_SERVICES = 'GET_SERVICES';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';

type GetServicesActionType = {
    type: string,
}

export const getServicesAction: () => GetServicesActionType = (): GetServicesActionType => ({
    type: GET_SERVICES
})
