import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IUserPermissions } from '../../../interfaces'

export const userPermissionsSlice = createSlice({
  name: 'userPermissions',
  initialState : null,
  reducers: {
    setUserPermissions: (_state, action: PayloadAction<IUserPermissions>) => action.payload,
  },
})

export const { setUserPermissions } = userPermissionsSlice.actions

export default userPermissionsSlice.reducer
