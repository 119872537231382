import { ICreateGroup } from '../../../interfaces'

export const UPDATE_GROUP = 'UPDATE_GROUP'

type UpdateGroupActionType = {
  type: string,
  payload: {
    group: ICreateGroup,
    push?: any,
    id: string
  }
}

export const updateGroupAction: (group: ICreateGroup, id: string, push?: any) => UpdateGroupActionType = (group, id, push): UpdateGroupActionType => ({
  type: UPDATE_GROUP,
  payload: {
    group,
    push,
    id
  }
})
