export const GET_GROUPS = 'GET_GROUPS';

type GetGroupsActionType = {
  type: string,
  payload: {
    pageSize: number,
    currentPage: number,
    withTotalRecords: boolean,
    searchText: string,
    selectedSearchCriteria: string
  }
}

export const getGroupsAction: (pageSize: number, currentPage: number, withTotalRecords: boolean, searchText?: string, selectedSearchCriteria?: string) => GetGroupsActionType = (pageSize: number, currentPage: number, withTotalRecords: boolean, searchText: string = "", selectedSearchCriteria: string = ""): GetGroupsActionType => ({
  type: GET_GROUPS,
  payload: {
    pageSize, currentPage, withTotalRecords, searchText, selectedSearchCriteria
  }
})
