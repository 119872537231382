import { Divider, Grid, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((_) => ({
  container: {
    border: "1px solid #ccc",
    marginBottom: "1rem"
  },
  heading: {
    marginTop: "0.5rem",
    marginLeft: "0.5rem"
  },
  divider: {
    width: "100%"
  },
  subContainer: {
    margin: "1rem 0"
  }
}));

export const DashboardContainer: React.FC<any> = (props: any): JSX.Element => {
  const classes = useStyles();
  return <Grid container className={classes.container}>
    <h3 className={classes.heading}>{props.heading}</h3>
    <Divider className={classes.divider} />
    <Grid container wrap="nowrap" justifyContent='space-around' className={classes.subContainer}>
      {props.children}
    </Grid>
  </Grid>
}
