import { environment } from "../../../environments/environment";
import { RequestHeaders } from "../../pages/shared/request/request-headers";

export const getAsync = async (entity) => {
  return await fetch(`${environment.apiBaseUrl}${entity}`, {
    headers: await RequestHeaders.get(),
  }).then(response => response)
}

export const deleteAsync = async (entity) => {
  return await fetch(`${environment.apiBaseUrl}${entity}`, {
    method: 'DELETE',
    headers: await RequestHeaders.get(),
  }).then(response => response)
}

export const postAsync = async (entity, postBody) => {
  return await fetch(`${environment.apiBaseUrl}${entity}`, {
    method: 'POST',
    headers: await RequestHeaders.get(),
    body: JSON.stringify(postBody)
  }).then(response => response)
}

export const putAsync = async (entity, postBody, id) => {
  return await fetch(`${environment.apiBaseUrl}${entity}/${id}`, {
    method: 'PUT',
    headers: await RequestHeaders.get(),
    body: JSON.stringify(postBody)
  }).then(response => response)
}

