import { call, put } from "redux-saga/effects";
import { IRole } from "../../../interfaces";
import {toggleAlert, getRolesSuccess, toggleAlertActionCreator, toggleLoading} from '../../slices'
import { getAsync } from "../commonAsync";
import { toKebabCase } from "../../../pages/shared";

const mapToRoles = (rawRoles): Array<IRole> => rawRoles?.map(({ id, ownerServiceId, service, name, description, roleAssignmentsCount, statements }) => ({
  id,
  ownerServiceId,
  service,
  name,
  description,
  statements,
  roleAssignmentsCount
}))

export function* getRolesSaga(action) {
  try {
    yield put(toggleLoading(true))
    const response = yield call(getAsync, `roles?${action.payload?.withTotalRecords ? ("&withTotalRecords=" + action.payload?.withTotalRecords) : ""}${action.payload?.pageSize ? ("&limit=" + action.payload?.pageSize) : ""}${action.payload?.currentPage ? ("&page=" + action.payload?.currentPage) : ""}${(action.payload?.selectedSearchCriteria && action.payload?.searchText) ? ("&" + toKebabCase(action.payload?.selectedSearchCriteria) + "=" + action.payload?.searchText) : ""}`)
    if (yield response?.ok) {
      const result = yield response.json();
      //Temporary logic to add services details in roles response
      const servicesRes = yield call(getAsync, `services?limit=100`);
      const services = yield servicesRes.json();
      const roles = yield {
        ...result,
        data: mapToRoles(result?.data?.map(role => {
          role.service = services?.data?.find(service => {
            return service.id === role.serviceId
          })
          return role
        }))
      }
      //
      yield put(getRolesSuccess(roles))
    }
    else {
      const errorCode = yield response.status;
      const { Error } = yield response.json();
      const errorMessages = yield Error?.map(error => error?.message).join(",")
      yield put(toggleAlert(toggleAlertActionCreator('error', `${errorCode}: ${errorMessages}`)))
    }
    yield put(toggleLoading(false))
  }
  catch (error) {
    yield put(toggleAlert(toggleAlertActionCreator('error', error?.toString())))
  }
}
