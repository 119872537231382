import { ICreateGroup } from '../../../interfaces'

export const CREATE_GROUP = 'CREATE_GROUP'

type CreateGroupActionType = {
  type: string,
  payload: {
    newGroup: ICreateGroup,
    push?: any,
  }
}

export const createGroupAction: (newGroup: ICreateGroup, push?: any) => CreateGroupActionType = (newGroup, push): CreateGroupActionType => ({
  type: CREATE_GROUP,
  payload: {
    newGroup,
    push
  }
})
