import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgePillListComponent } from './badge-pill-list.component';
import { BadgePillModule } from '../badge-pill';

@NgModule({
  imports: [CommonModule, BadgePillModule],
  declarations: [BadgePillListComponent],
  exports: [BadgePillListComponent],
})

export class BadgePillListModule {
}
