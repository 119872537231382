
export abstract class HttpService {

  /**
   * The API endpoint prefix
   * @protected
   */
  protected apiContext = '/v1/metadata';

  /**
   * The API resource name.
   * @protected
   */
  protected abstract resource: string;

  /**
   * Sends a HTTP POST request to the given endpoint
   * @param endpoint
   * @param body
   */
  post(endpoint: string, body?: Record<string, string | string[] | object>) {
    // Add prepending slash to endpoint if it's not empty
    endpoint = (endpoint === '') ? '' : `/${endpoint}`;
    return fetch(`${this.apiContext}/${this.resource}${endpoint}`, {
      method: 'post',
      body: JSON.stringify(body),
    });
  }

  status(response) {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(new Error(response.statusText))
    }
  }

  json(response) {
    return response.json()
  }
}

