import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';


@Injectable()
export class Valid {

  static regExps = {
    name: /^[a-zA-Z ,.'-]{2,64}$/,
    alphaNum: /^\\w{1,128}$/,
    alphaNumDash: /^[a-zA-Z\d-]{1,128}$/,
    alphaNumDashUnderscore: /^[a-zA-Z\d-_]{1,128}$/,
    number: /^-?\d+$/,
    decimal: /^-?\d+(\.\d+)?$/,
    commaNum: /^[\\d,]{1,128}$/,
    commaAlphaNum: /^[a-zA-Z\d_, ]{1,128}$/,
    npi: /^\\d{10}$/,
    ssn: /^\\d{9}$/,
    alphaSpaceDash: /^[a-zA-Z -]*$/,
    nameAlphaNum: /^[a-zA-Z\d ,.'-]*$/,
    address: /^[a-zA-Z\d #,.'-]+$/,
    code: /^\\d{5}$/,
    passwordAlphaNum: /^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\d]{8,}$/,
    procedure: /^[a-zA-Z ,.():";'™®/-]{2,128}$/,
    accountNumber: /^\\d{9,12}$/,
    routingNumber: /^\\d{4,17}$/,
    colorHex: /^(?:[\da-fA-F]{3}){1,2}$/,
    percentage: /^100$|^\\d{0,2}(\\.\\d{1,2})?$/,
  };

  /**
   * Number validation
   * @param control
   */
  static number(control: AbstractControl): { [key: string]: any } | null {
    const message = 'Please enter a valid number!';
    return Valid.validateRegEx(Valid.regExps.number, control.value, message);
  }

  /**
   * Number with decimal validation
   * @param control
   */
  static decimal(control: AbstractControl): { [key: string]: any } | null {
    const message = 'Please enter a valid number!';
    return Valid.validateRegEx(Valid.regExps.decimal, control.value, message);
  }

  /**
   * Percentage validation
   * @param control
   */
  static percentage(control: AbstractControl): { [key: string]: any } | null {
    const message = 'Please enter a valid percentage!';
    return Valid.validateRegEx(Valid.regExps.percentage, control.value, message);
  }

  /**
   * Filed Name validation
   * @param control
   */
  static fieldName(control: AbstractControl): { [key: string]: any } | null {
    const message = 'Please enter a valid name!';
    return Valid.validateRegEx(Valid.regExps.alphaNumDashUnderscore, control.value, message);
  }

  /**
   * Address validation
   * @param control
   */
  static address(control: AbstractControl): { [key: string]: any } | null {
    const message = 'Please enter a valid address!';
    return Valid.validateRegEx(Valid.regExps.address, control.value, message);
  }

  static city(control: AbstractControl): { [key: string]: any } | null {
    const message = 'Please enter a valid city name!';
    return Valid.validateRegEx(Valid.regExps.alphaSpaceDash, control.value, message);
  }

  static postalCode(control: AbstractControl): { [key: string]: any } | null {
    const message = 'Please enter a valid zip code!';
    return Valid.validateRegEx(Valid.regExps.code, control.value, message);
  }

  static password(control: AbstractControl): { [key: string]: any } | null {
    const message = 'Password must contain at least 1 letter and 1 number!';
    return Valid.validateRegEx(Valid.regExps.passwordAlphaNum, control.value, message);
  }

  static colorHex(control: AbstractControl): { [key: string]: any } | null {
    const message = 'Please enter a hex color!';
    return Valid.validateRegEx(Valid.regExps.colorHex, control.value, message);
  }

  static ssn(control: AbstractControl): { [key: string]: any } | null {
    const message = 'Please enter a valid number!';
    return Valid.validateRegEx(Valid.regExps.ssn, control.value, message);
  }

  static customName(fieldName: string): ValidatorFn {
    const message = `Please enter a valid  ${fieldName}!`;

    return (control: AbstractControl): { [key: string]: any } | null => Valid._name(control.value, message)
  }


  static required(fieldName: string): ValidatorFn {
    const message = `${fieldName} is required!`;

    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value || Number.isInteger(control.value)) {
        return null;
      }

      return {
        required: {
          valid: false,
          message: message,
        },
      };
    }
  }

  private static _name(value: string, message: string): any {

    if (!message) {
      message = 'Please enter a valid name!';
    }

    return Valid.validateRegEx(Valid.regExps.name, value, message);
  }

  private static validateRegEx(regExp: RegExp, value: string, message: string): { [key: string]: any } | null {
    if (!value) {
      return null;
    }

    return regExp.test(value) ? null : {
      pattern: {
        valid: false,
        message: message,
      },
    };
  }
}
