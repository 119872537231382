import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ElementIdentifier, ElementType } from '../../utils';
import { Breadcrumb } from '.';
import { BreadcrumbService } from './services/breadcrumb.service';

@Component({
  selector: 'prism-breadcrumb-list',
  template: `
    <ol class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs; last as isLast">
        <a [href]="breadcrumb.url"
           *ngIf="breadcrumb.url !== null && !isLast; else noUrl"
           id="{{ breadcrumbIdentifier(breadcrumb.label) }}"
        >{{ breadcrumb.label }}</a>

        <ng-template #noUrl>
          <span>{{ breadcrumb.label }}</span>
        </ng-template>
      </li>
    </ol>
  `,
})
export class BreadcrumbListComponent implements OnInit {

  /**
   * Breadcrumb data
   */
  public breadcrumbs: Array<Breadcrumb> = [];

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private breadcrumbService: BreadcrumbService) {
  }

  public ngOnInit(): void {
    this.breadcrumbService.refresh(this.activatedRoute);
    this.watchRouter();
    this.watchBreadcrumbs();
  }

  /**
   * Watches for routing changes so the breadcrumb-list can be refreshed.
   */
  public watchRouter(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.breadcrumbService.refresh(this.activatedRoute);
    });
  }

  /**
   * Watches for breadcrumb changes in the service so that breadcrumb-list can be refreshed.
   */
  public watchBreadcrumbs(): void {
    this.breadcrumbService.breadcrumbs.subscribe((breadcrumbs: Array<Breadcrumb>) => {
      this.breadcrumbs = breadcrumbs;
    });
  }

  /**
   * Add breadcrumb element id
   * @param name
   */
  public breadcrumbIdentifier(name): string {
    return ElementIdentifier.getElementId(name, ElementType.BREADCRUMB)
  }
}

