import { NgModule } from '@angular/core';
import { ResourceNotFoundNoticeComponent } from './resource-not-found-notice.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ResourceNotFoundNoticeComponent],
  imports: [
    CommonModule,
  ],
  exports: [ResourceNotFoundNoticeComponent],
})

export class ResourceNotFoundNoticeModule {
}
