import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FilterObject } from '../../../data/core/filters/filters';

@Component({
  selector: 'prism-search-filter',
  template: `
    <form (submit)="submit($event)" class="search-filter">
      <div class="input-group search">
        <input type="text"
               class="form-control pr-4"
               placeholder="{{ placeholder }}"
               [formControl]="searchTerm"
               id="input-textual-filter"
        >
        <button type="button"
                class="btn bg-transparent clear-search-btn"
                (click)="clearSearch()">
          <i class="fa fa-times"></i>
        </button>
        <div class="input-group-append">
          <button class="btn btn-primary" type="submit">
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </form>
  `,
  styles: [`
      .search > input:focus {
          outline: 0;
          box-shadow: none;
          border-color: #000000;
      }

      .clear-search-btn {
          margin-left: -40px;
          z-index: 100;
          box-shadow: none;
      }

      .clear-search-btn > i {
          pointer-events: none;
      }
  `],
})
export class SearchFilterComponent {

  /**
   * The current filter object to be used in the API query.
   */
  public filter: FilterObject = {
    filter: '',
    value: null,
  };

  /**
   * A search input placeholder
   */
  @Input() public placeholder = 'Search...';

  /**
   * The key that will be sent to the API via params
   * @param type
   */
  @Input()
  set type(type: string) {
    this.filter.filter = type;
  }

  /**
   * The preloaded search string. Used to set cached value to the search filter component
   * @param searchValue
   */
  @Input()
  set searchValue(searchValue: string) {
    this.searchTerm.setValue(searchValue);
  }

  /**
   * Emit filter object with a search value
   */
  @Output() searchEvent = new EventEmitter<FilterObject<null>>();

  /**
   * Form with the searchTerm property
   */
  public searchTerm = new UntypedFormControl('');

  /**
   * Handler for when search form is submitted
   */
  public submit(e: Event) {
    e.preventDefault();
    if (this.searchTerm.value?.trim()) {
      this.emitEvent();
    }
  }

  /**
   * Handler for button that clears search input
   */
  public clearSearch() {
    this.searchTerm.setValue('');
    this.emitEvent();
  }

  /**
   * Apply search value to the filter object and emit the changes
   * Added custom event for react apps.
   */
  private emitEvent() {
    this.filter.value = this.searchTerm.value;
    this.searchEvent.emit(this.filter);

    document.dispatchEvent(new CustomEvent('search-event', { detail: this.filter }));
  }
}
